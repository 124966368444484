import React, { useEffect, useState, useRef } from "react";
import FilterByForDashboard from "../FilterByForDashboard";
import { useGlobalContext } from "../../../context/GlobalContext";
import {
  getDayOfWeekChart,
  getUsePerDayChartData,
  getWeekPartChart,
  getTimeOfDayChart,
} from "../../../services/dashboard/frequency";
import {
  getUsePerDayAndWeekChartDataForCoaster,
  getDayOfWeekAndWeekPartChartForCoaster,
  getTimeOfDayChartForCoaster,
} from "../../../services/dashboard/frequency";
import UsesPerDay from "./frequencyComponents/UsesPerDay";
import UsesPerWeek from "./frequencyComponents/UsesPerWeek";
import TimeOfDay from "./frequencyComponents/TimeOfDay";
import DayOfWeek from "./frequencyComponents/DayOfWeek";
import WeekPart from "./frequencyComponents/WeekPart";
import { useSelector } from "react-redux";
import { PROJECT_ID } from "../../../utils/constants";
import { validateCompareObjectValues } from "../../../utils/filterBy/filterBy";

const Frequency = (props) => {
  const [activeOption, setActiveOption] = useState("FILTERBY");
  const [question_id, setQuestionId] = useState("");
  const rowRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { device, deviceCode, category, product_id } = useGlobalContext();
  const [usePerDayGraph, setUsePerDayGraph] = useState("");
  const [usePerWeekGraph, setUsePerWeekGraph] = useState("");
  const [dayOfWeekGraph, setDayOfWeekGraph] = useState("");
  const [weekPartGraph, setWeekPartGraph] = useState("");
  const [timeOfDayGraph, setTimeOfDayGraph] = useState("");
  const [productType, setProductType] = useState("");
  const { token } = useSelector((state) => state.auth);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedOccasions, setSelectedOccasions] = useState([]);
  const [selectedHouseHoldAge, setSelectedHouseHoldAge] = useState([]);
  const [selectedHouseHoldIncome, setSelectedHouseHoldIncome] = useState([]);
  const [selectedHouseHoldSize, setSelectedHouseHoldSize] = useState([]);
  const [selectedHouseHoldChildren, setSelectedHouseHoldChildren] = useState(
    [],
  );
  const [selectedHouseHoldRaceEthnicity, setSelectedHouseHoldRaceEthnicity] =
    useState([]);
  const [selectedForms, setSelectedForms] = useState([]);
  const [selectedDayPart, setSelectedDayPart] = useState([]);
  const [selectedWeekPart, setSelectedWeekPart] = useState([]);
  const [selectedTimeOfDay, setSelectedTimeOfDay] = useState([]);
  const [activeButtonTimeOfDay, setActiveButtonTimeOfDay] =
    useState("% OF TOTAL");
  const [timeOfDayLoader, setTimeOfDayLoader] = useState(false);
  const [activeButtonDayOfWeek, setActiveButtonDayOfWeek] =
    useState("% OF TOTAL");
  const [dayOfWeekLoader, setDayOfWeekLoader] = useState(false);
  const [activeButtonWeekPart, setActiveButtonWeekPart] =
    useState("% OF TOTAL");
  const [weekPartLoader, setWeekPartLoader] = useState(false);

  const getSelectedCategory = () => {
    if (selectedHouseHoldAge.length > 0) {
      return "age";
    }
    if (selectedHouseHoldIncome.length > 0) {
      return "income";
    }
    if (selectedHouseHoldSize.length > 0) {
      return "size";
    }
    if (selectedHouseHoldChildren.length > 0) {
      return "children";
    }
    if (selectedHouseHoldRaceEthnicity.length > 0) {
      return "raceEthnicity";
    }
    return "";
  };

  const getSelectedCategoriesString = () => {
    const categories = [];

    if (selectedHouseHoldAge.length > 0) {
      categories.push("age");
    }
    if (selectedHouseHoldIncome.length > 0) {
      categories.push("income");
    }
    if (selectedHouseHoldSize.length > 0) {
      categories.push("size");
    }
    if (selectedHouseHoldChildren.length > 0) {
      categories.push("children");
    }
    if (selectedHouseHoldRaceEthnicity.length > 0) {
      categories.push("raceEthnicity");
    }

    return categories.join("|");
  };

  const requestBodyForSensor = {
    compare_with: props?.selectedComparitionBy,
    compare_by: props?.compareBy,
    chart_type: "how_often",
    occasion_question_id: parseInt(question_id),
    filter_occasion: selectedOccasions.join("|"),
    filter_household: Object.values(selectedFilters)
      .flat()
      .map((item) => item.uniques.trim())
      .filter((value) => value !== "All") // Remove "All" if present
      .join("|"),
    filter_household_type: getSelectedCategoriesString(),
    filter_consumer_segment: "",
    filter_product_category: parseInt(product_id),
    filter_product_type: productType,
    filter_brand: selectedBrands.join("|"),
    filter_form: selectedForms.join("|"),
    filter_day_part: selectedDayPart.join("|"),
    filter_week_part: selectedWeekPart.join("|"),
    graph_type: "total_average",
    project_id: parseInt(PROJECT_ID),
    section_type:
      props?.chooseComparison && validateCompareObjectValues(props?.compareBy)
        ? "compare"
        : "filter",
  };

  const fetchChartsDataForSensor = () => {
    setLoading(true);
    Promise.all([
      getUsePerDayChartData(
        { ...requestBodyForSensor, how_often_use_per: "day" },
        token,
      ),
      getUsePerDayChartData(
        { ...requestBodyForSensor, how_often_use_per: "week" },
        token,
      ),
    ])
      .then(([usePerDayResponse, usePerWeekResponse]) => {
        setUsePerDayGraph(usePerDayResponse.data.graphs);
        setUsePerWeekGraph(usePerWeekResponse.data.graphs);
      })
      .catch((error) => {
        console.error("Error fetching chart data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchWeekPartChartDataSensor = async () => {
    try {
      setWeekPartLoader(true);
      const response = await getWeekPartChart(
        {
          ...requestBodyForSensor,
          graph_type: "weekly_average",
          how_often_use_per: "",
          sub_graph_type:
            activeButtonWeekPart === "% OF TOTAL"
              ? "percentage_of_total"
              : "number_of_usage",
        },
        token,
      );
      setWeekPartGraph(response.data.graphs);
    } catch (error) {
      console.error("Error fetching chart data:", error);
    } finally {
      setWeekPartLoader(false);
    }
  };

  const fetchDayOfWeekChartDataSensor = async () => {
    try {
      setDayOfWeekLoader(true);
      const response = await getDayOfWeekChart(
        {
          ...requestBodyForSensor,
          graph_type: "daily_average",
          how_often_use_per: "",
          sub_graph_type:
            activeButtonDayOfWeek === "% OF TOTAL"
              ? "percentage_of_total"
              : "number_of_usage",
        },
        token,
      );
      setDayOfWeekGraph(response.data.graphs);
    } catch (error) {
      console.error("Error fetching chart data:", error);
    } finally {
      setDayOfWeekLoader(false);
    }
  };

  const fetchTimeOfDayChartDataSensor = async () => {
    try {
      setTimeOfDayLoader(true);
      const response = await getTimeOfDayChart(
        {
          ...requestBodyForSensor,
          graph_type: "day_average",
          how_often_use_per: "",
          sub_graph_type:
            activeButtonTimeOfDay === "% OF TOTAL"
              ? "percentage_of_total"
              : "number_of_usage",
        },
        token,
      );
      setTimeOfDayGraph(response.data.graphs);
    } catch (error) {
      console.error("Error fetching chart data:", error);
    } finally {
      setTimeOfDayLoader(false);
    }
  };

  const requestBodyForCoaster = {
    compare_with: props?.selectedComparitionBy,
    compare_by: props?.compareBy,
    chart_type: "",
    filter_occasion: selectedOccasions.join("|"),
    filter_household: [
      ...selectedHouseHoldAge,
      ...selectedHouseHoldIncome,
      ...selectedHouseHoldSize,
      ...selectedHouseHoldChildren,
      ...selectedHouseHoldRaceEthnicity,
    ]
      .map((item) => item.uniques)
      .join("|"),
    filter_household_type: getSelectedCategoriesString(),
    filter_consumer_segment: "",
    filter_product_category: parseInt(product_id),
    filter_product_type: productType,
    filter_brand: selectedBrands.join("|"),
    filter_form: selectedForms.join("|"),
    graph_type: "no_of_use",
    how_long_use_per: "",
    project_id: parseInt(PROJECT_ID),
    cat_id: parseInt(product_id),
    cat_name: category,
    section_type:
      props?.chooseComparison && validateCompareObjectValues(props?.compareBy)
        ? "compare"
        : "filter",
    how_often_use_per: "",
  };

  const fetchChartsDataForCoaster = () => {
    setLoading(true);

    Promise.all([
      getUsePerDayAndWeekChartDataForCoaster(
        { ...requestBodyForCoaster, day_type: "day" },
        token,
      ),
      getUsePerDayAndWeekChartDataForCoaster(
        { ...requestBodyForCoaster, day_type: "week" },
        token,
      ),
    ])
      .then(([usePerDayResponse, usePerWeekResponse]) => {
        setUsePerDayGraph(usePerDayResponse.data.graphs);
        setUsePerWeekGraph(usePerWeekResponse.data.graphs);
      })
      .catch((error) => {
        console.error("Error fetching chart data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchDayOfWeekChartDataCoaster = async () => {
    try {
      setDayOfWeekLoader(true);
      const response = await getDayOfWeekAndWeekPartChartForCoaster(
        {
          ...requestBodyForCoaster,
          day_type: "day",
          how_often_use_per: "",
          sub_graph_type:
            activeButtonDayOfWeek === "% OF TOTAL"
              ? "percentage_of_total"
              : "number_of_usage",
        },
        token,
      );
      setDayOfWeekGraph(response.data.graphs);
    } catch (error) {
      console.error("Error fetching chart data:", error);
    } finally {
      setDayOfWeekLoader(false);
    }
  };

  const fetchWeekPartChartDataCoaster = async () => {
    try {
      setWeekPartLoader(true);
      const response = await getDayOfWeekAndWeekPartChartForCoaster(
        {
          ...requestBodyForCoaster,
          day_type: "week",
          how_often_use_per: "",
          sub_graph_type:
            activeButtonWeekPart === "% OF TOTAL"
              ? "percentage_of_total"
              : "number_of_usage",
        },
        token,
      );
      setWeekPartGraph(response.data.graphs);
    } catch (error) {
      console.error("Error fetching chart data:", error);
    } finally {
      setWeekPartLoader(false);
    }
  };

  const fetchTimeOfDayChartDataCoaster = async () => {
    try {
      setTimeOfDayLoader(true);
      const response = await getTimeOfDayChartForCoaster(
        {
          ...requestBodyForCoaster,
          how_often_use_per: "",
          sub_graph_type:
            activeButtonTimeOfDay === "% OF TOTAL"
              ? "percentage_of_total"
              : "number_of_usage",
        },
        token,
      );
      setTimeOfDayGraph(response.data.graphs);
    } catch (error) {
      console.error("Error fetching chart data:", error);
    } finally {
      setTimeOfDayLoader(false);
    }
  };

  useEffect(() => {
    if (productType !== "") {
      fetchChartsDataForSensor();
    }
  }, [productType, props?.apply]);

  useEffect(() => {
    if (parseInt(deviceCode) === 1) {
      fetchChartsDataForCoaster();
    }
  }, [deviceCode, props?.apply]);

  useEffect(() => {
    if (productType !== "") {
      fetchTimeOfDayChartDataSensor();
    }
  }, [productType, props?.apply, activeButtonTimeOfDay]);

  useEffect(() => {
    if (productType !== "") {
      fetchDayOfWeekChartDataSensor();
    }
  }, [productType, props?.apply, activeButtonDayOfWeek]);

  useEffect(() => {
    if (productType !== "") {
      fetchWeekPartChartDataSensor();
    }
  }, [productType, props?.apply, activeButtonWeekPart]);

  useEffect(() => {
    if (deviceCode === 1) {
      fetchDayOfWeekChartDataCoaster();
    }
  }, [deviceCode, props?.apply, activeButtonDayOfWeek]);

  useEffect(() => {
    if (deviceCode === 1) {
      fetchWeekPartChartDataCoaster();
    }
  }, [deviceCode, props?.apply, activeButtonWeekPart]);

  useEffect(() => {
    if (deviceCode === 1) {
      fetchTimeOfDayChartDataCoaster();
    }
  }, [deviceCode, props?.apply, activeButtonTimeOfDay]);

  const handleReset = () => {
    setSelectedDayPart([]);
    setSelectedWeekPart([]);
    setSelectedBrands([]);
    setSelectedOccasions([]);
    setSelectedFilters([]);
    setSelectedForms([]);
    props?.setApply(!props?.apply);
  }

  return (
    <div className="row" style={{ position: "relative" }}>
      <FilterByForDashboard
        loading={loading}
        setLoading={setLoading}
        device={device}
        deviceCode={deviceCode}
        category={category}
        product_id={product_id}
        productType={productType}
        setProductType={setProductType}
        activeOption={activeOption}
        setActiveOption={setActiveOption}
        frequency={true}
        question_id={question_id}
        setQuestionId={setQuestionId}
        selectedForms={selectedForms}
        setSelectedForms={setSelectedForms}
        selectedBrands={selectedBrands}
        setSelectedBrands={setSelectedBrands}
        selectedGenders={selectedGenders}
        setSelectedGenders={setSelectedGenders}
        selectedOccasions={selectedOccasions}
        setSelectedOccasions={setSelectedOccasions}
        selectedHouseHoldAge={selectedHouseHoldAge}
        setSelectedHouseHoldAge={setSelectedHouseHoldAge}
        selectedHouseHoldIncome={selectedHouseHoldIncome}
        setSelectedHouseHoldIncome={setSelectedHouseHoldIncome}
        selectedHouseHoldSize={selectedHouseHoldSize}
        setSelectedHouseHoldSize={setSelectedHouseHoldSize}
        selectedHouseHoldChildren={selectedHouseHoldChildren}
        setSelectedHouseHoldChildren={setSelectedHouseHoldChildren}
        selectedHouseHoldRaceEthnicity={selectedHouseHoldRaceEthnicity}
        setSelectedHouseHoldRaceEthnicity={setSelectedHouseHoldRaceEthnicity}
        selectedDayPart={selectedDayPart}
        setSelectedDayPart={setSelectedDayPart}
        selectedWeekPart={selectedWeekPart}
        setSelectedWeekPart={setSelectedWeekPart}
        selectedTimeOfDay={selectedTimeOfDay}
        setSelectedTimeOfDay={setSelectedTimeOfDay}
        apply={props?.apply}
        setApply={props?.setApply}
        isCompareByClicked={props?.isCompareByClicked}
        setIsCompareByClicked={props?.setIsCompareByClicked}
        setSelectedFilters={setSelectedFilters}
        selectedFilters={selectedFilters}
        isDayWeekPartShow={true}
        handleReset={handleReset}
      />
      <div className="col-9" style={{ padding: "0px 3%" }}>
        <UsesPerDay usePerDayGraph={usePerDayGraph} loading={loading} />
        <UsesPerWeek usePerWeekGraph={usePerWeekGraph} loading={loading} />
        <TimeOfDay
          activeButtonTimeOfDay={activeButtonTimeOfDay}
          setActiveButtonTimeOfDay={setActiveButtonTimeOfDay}
          timeOfDayGraph={timeOfDayGraph}
          timeOfDayLoader={timeOfDayLoader}
        />
        <DayOfWeek
          activeButtonDayOfWeek={activeButtonDayOfWeek}
          setActiveButtonDayOfWeek={setActiveButtonDayOfWeek}
          dayOfWeekGraph={dayOfWeekGraph}
          dayOfWeekLoader={dayOfWeekLoader}
        />
        <WeekPart
          activeButtonWeekPart={activeButtonWeekPart}
          setActiveButtonWeekPart={setActiveButtonWeekPart}
          weekPartGraph={weekPartGraph}
          weekPartLoader={weekPartLoader}
        />
      </div>
    </div>
  );
};

export default Frequency;
