import React from "react";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import CaptchaImage from "../../globalUtilities/captcha";
import { Button } from "primereact/button";

const StepTwo = ({
  password,
  handlePasswordChange,
  passwordError,
  enteredCaptcha,
  setEnteredCaptcha,
  error,
  captcha,
  handleLogin,
  setStep,
  handleForgotPassword,
}) => {
  return (
    <>
      {/* Desktop View Start */}
      <div className="login-form-pad-desktop-step-2 d-none d-md-block">
        <div className="d-flex align-items-center">
          <span className="pi pi-lock me-2 text-secondary"></span>
          <Password
            autoFocus
            value={password}
            onChange={(e) => handlePasswordChange(e.target.value)}
            toggleMask
            feedback={false}
            className="username w-100"
            placeholder="Enter Password"
          />
        </div>
        {passwordError && (
          <div className="error-message text-danger align-items-left fs-error">
            {passwordError}
          </div>
        )}
        <div className="mt-4 d-flex align-items-center">
          <span className="pi pi-lock me-2 text-secondary"></span>
          <InputText
            value={enteredCaptcha}
            onChange={(e) => setEnteredCaptcha(e.target.value)}
            placeholder="Enter security code"
            className="username w-100"
          />
        </div>
        {error && (
          <div className="error-message text-danger align-items-left fs-error">
            {error}
          </div>
        )}
        <div className="captcha-display">
          <CaptchaImage captcha={captcha} />
        </div>
        <div className="text-center pt-1 mt-4 pb-1">
          <Button
            label="LOGIN"
            onClick={handleLogin}
            className="mb-4 w-100 fw-600 next-button"
          />
        </div>
        <div>
          <span
            className="forgot-password"
            onClick={() => handleForgotPassword()}
          >
            Forgot Password?
          </span>
        </div>
        <div className="mt-5 fw-600">
          <p className="read-terms">
            By clicking "Login" you agree with the{" "}
            <span className="decoration">Terms of Service</span> and{" "}
            <span className="decoration">Privacy Policy</span>
          </p>
        </div>
      </div>
      {/* Desktop View End */}
      {/* Mobile View Start */}
      <div className="login-form-pad-mobile-step-2 d-block d-md-none">
        <div className="d-flex align-items-center">
          <span className="pi pi-lock me-2 text-secondary"></span>
          <Password
            value={password}
            onChange={(e) => handlePasswordChange(e.target.value)}
            toggleMask
            feedback={false}
            className="username w-100"
            placeholder="Enter Password"
          />
        </div>
        {passwordError && (
          <div className="error-message text-danger align-items-left fs-error">
            {passwordError}
          </div>
        )}
        <div className="mt-4 d-flex align-items-center">
          <span className="pi pi-lock me-2 text-secondary"></span>
          <InputText
            autoFocus
            value={enteredCaptcha}
            onChange={(e) => setEnteredCaptcha(e.target.value)}
            placeholder="Enter security code"
            className="username w-100"
          />
        </div>
        {error && (
          <div className="error-message text-danger align-items-left fs-error">
            {error}
          </div>
        )}
        <div className="captcha-display">
          <CaptchaImage captcha={captcha} />
        </div>
        <div className="text-center pt-1 mt-4 pb-1">
          <Button
            label="LOGIN"
            onClick={handleLogin}
            className="mb-4 w-100 next-button"
          />
        </div>
        <div>
          <span
            className="forgot-password"
            onClick={() => handleForgotPassword()}
          >
            Forgot Password?
          </span>
        </div>
        <div className="mt-5 fw-600">
          <p className="read-terms">
            By clicking "Login" you agree with the{" "}
            <span className="decoration">Terms of Service</span> and{" "}
            <span className="decoration">Privacy Policy</span>
          </p>
        </div>
      </div>
      {/* Mobile View End */}
    </>
  );
};

export default StepTwo;
