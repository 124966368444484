import React from "react";
import { Chart } from "primereact/chart";

const DayOfWeek = () => {
  const data = {
    labels: ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"],
    datasets: [
      {
        label: "CATEGORY",
        data: [17.46, 53.62, 67.7, 76.29, 88.35, 98.4, 25.56],
        backgroundColor: "orange",
        borderColor: "orange",
        borderWidth: 1,
      },
      {
        label: "TIDE",
        data: [15.46, 24.62, 30.7, 46.29, 55.35, 62.4, 70.56],
        backgroundColor: "blue",
        borderColor: "blue",
        borderWidth: 1,
      },
      {
        label: "GAIN",
        data: [10.46, 25.62, 31.7, 49.29, 55.35, 64.4, 78.56],
        backgroundColor: "#EE534F",
        borderColor: "#EE534F",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      datalabels: {
        color: "black",
        anchor: "end",
        align: "top",
        font: {
          weight: "bold",
          size: 12,
        },
        formatter: (value) => (value !== 0 ? value : ""),
      },
    },
    scales: {
      x: {
        title: {
          display: true,
        },
      },
      y: {
        beginAtZero: true,
        max: 100,
      },
    },
  };

  return (
    <>
      <div className="fs-14 text-start mt-4 mb-4">
        <strong>DAY OF WEEK - AVERAGE DAILY USES</strong>
      </div>
      <div className="col-12">
        <Chart type="bar" data={data} options={options} />
      </div>
    </>
  );
};

export default DayOfWeek;
