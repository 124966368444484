import React from "react";

const ChartDivider = () => {
  return (
    <>
      <hr style={{ marginTop: "1.5rem" }} />
      <hr style={{ marginTop: "3rem" }} />
    </>
  );
};
export default ChartDivider;
