import React from "react";
import FilterByForDashboard from "../FilterByForDashboard";
import ChartDivider from "../ChartDivider";
import LoyalUsersToOneBrand from "./loyaltyComponents/LoyalUsersToOneBrand";
import BrandStickiness from "./loyaltyComponents/BrandStickiness";
import TopReasonForSwitchBrand from "./loyaltyComponents/TopReasonForSwitchBrand";

const Loyalty = (props) => {
  return (
    <div className="row">
      {/* Filter By Component */}
      <FilterByForDashboard />
      <div className="col-9" style={{ padding: "0px 5%" }}>
        <LoyalUsersToOneBrand />
        <ChartDivider />
        <BrandStickiness />
        <ChartDivider />
        <TopReasonForSwitchBrand />
        <hr style={{ margin: "2rem 0" }} />
      </div>
    </div>
  );
};
export default Loyalty;
