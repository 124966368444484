import React, { useMemo } from "react";
import { Chart } from "primereact/chart";
import ChartDivider from "../../ChartDivider";
import ChartSkeleton from "../../../Skeletons/ChartSkeleton";
import { getOccasionsChartOptions } from "../../../../utils/chartOptions/dashboard/occasions";
import { Dropdown } from "primereact/dropdown";

const OccasionsChart = React.memo(
  function OccasionsChart(props) {
    // Memoize the chart options to prevent re-creation on every render
    const options = useMemo(
      () => getOccasionsChartOptions(props.occasionsChartData),
      [props.occasionsChartData],
    );

    return (
      <div className="mt-4" style={{ maxWidth: "100%" }}>
        <div className="mb-4">
          <h5 className="text-uppercase fw-bold mb-1 text-start">Occasions</h5>
          <p
            className="text-uppercase mb-0 text-start fw-bold fs-12 pt-3"
            style={{ color: "#6c757d" }}
          >
            % of uses
          </p>
          <div className="d-flex justify-content-end align-items-center">
            <label className="me-2 fw-600 fs-14 text-dark-gray">
              SORT GRAPH:
            </label>
            <Dropdown
              value={props?.sortOption}
              options={[
                { label: "HIGHEST TO LOWEST", value: "highest" },
                { label: "LOWEST TO HIGHEST", value: "lowest" },
                { label: "DEFAULT", value: "default" },
              ]}
              onChange={(e) => props?.handleSortChange(e.value)}
              placeholder="Select Sorting"
              style={{ backgroundColor: "#F5F5F5", width: "250px" }}
            />
          </div>
          <div className="col-12">
            {!props?.loading ? (
              <Chart
                type="bar"
                data={props?.occasionsChartData}
                options={options}
              />
            ) : (
              <ChartSkeleton />
            )}
          </div>
          <ChartDivider />
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    // Custom comparison function to prevent unnecessary re-renders
    return (
      prevProps.sortOption === nextProps.sortOption &&
      prevProps.loading === nextProps.loading &&
      JSON.stringify(prevProps.occasionsChartData) ===
        JSON.stringify(nextProps.occasionsChartData)
    );
  },
);

export default OccasionsChart;
