import React from "react";
import { Skeleton } from "primereact/skeleton";
const FilterSkeleton = () => {
  return (
    <div className="w-full md:w-12 p-3">
      <Skeleton className="mb-2"></Skeleton>
      <Skeleton className="mb-2"></Skeleton>
      <Skeleton className="mb-2"></Skeleton>
      <Skeleton className="mb-2"></Skeleton>
      <Skeleton className="mb-2"></Skeleton>
      <Skeleton className="mb-2"></Skeleton>
    </div>
  );
};
export default FilterSkeleton;
