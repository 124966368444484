import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";
import { TabView, TabPanel } from "primereact/tabview";
import "../../assets/css/dashboard.css"; // Ensure your CSS file is correctly imported
import UserSummary from "../../components/dashboardTabs/userSummary/UserSummary";
import Occasions from "../../components/dashboardTabs/occasions/Occasions";
import { useGlobalContext } from "../../context/GlobalContext";
import Experience from "../../components/dashboardTabs/experience/Experience";
import BrandScorecard from "../../components/dashboardTabs/brandScorecard/BrandScorecard";
import Frequency from "../../components/dashboardTabs/frequency/Frequency";
import Amount from "../../components/dashboardTabs/amount/Amount";
import Needs from "../../components/dashboardTabs/needs/Needs";
import CompareBy from "../../components/compareBy/CompareBy";
import Loyalty from "../../components/dashboardTabs/loyalty/Loyalty";
const Dashboard = () => {
  const [apply, setApply] = useState(false);
  const [loading, setLoading] = useState(false);
  const { deviceCode, product_id } = useGlobalContext();
  const [activeIndex, setActiveIndex] = useState(0);
  const [compareBy, setCompareBy] = useState(null);
  const [selectedComparitionBy, setSelectedComparisonBy] = useState("");
  const [isCompareByClicked, setIsCompareByClicked] = useState(false);
  const [chooseComparison, setChooseComparison] = useState(null);
  useEffect(() => {
    setActiveIndex(0);
  }, [product_id]);
  return (
    <div className="container-fluid page-wrapper">
      <div className="row">
        <div className="col-12 col-md-12">
          <Card className="shadow">
            <CompareBy
              compareBy={compareBy}
              setCompareBy={setCompareBy}
              setSelectedComparisonBy={setSelectedComparisonBy}
              apply={apply}
              setApply={setApply}
              loading={loading}
              setLoading={setLoading}
              isCompareByClicked={isCompareByClicked}
              setIsCompareByClicked={setIsCompareByClicked}
              chooseComparison={chooseComparison}
              setChooseComparison={setChooseComparison}
            />
          </Card>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-12 col-md-12" style={{ marginTop: "-10px" }}>
          <Card
            className="shadow"
            style={{ marginBottom: "20px", paddingBottom: "10px" }}
          >
            <TabView
              activeIndex={activeIndex}
              onTabChange={(e) => setActiveIndex(e.index)}
            >
              <TabPanel header="BRAND SCORECARD" className="tabStyle">
                <BrandScorecard setIsCompareByClicked={setIsCompareByClicked} />
              </TabPanel>
              <TabPanel header="FREQUENCY">
                <Frequency
                  compareBy={compareBy}
                  selectedComparitionBy={selectedComparitionBy}
                  apply={apply}
                  setApply={setApply}
                  isCompareByClicked={isCompareByClicked}
                  setIsCompareByClicked={setIsCompareByClicked}
                  chooseComparison={chooseComparison}
                />
              </TabPanel>
              {deviceCode === 1 && (
                <TabPanel header="AMOUNT">
                  <Amount
                    compareBy={compareBy}
                    selectedComparitionBy={selectedComparitionBy}
                    apply={apply}
                    setApply={setApply}
                    isCompareByClicked={isCompareByClicked}
                    setIsCompareByClicked={setIsCompareByClicked}
                    chooseComparison={chooseComparison}
                  />
                </TabPanel>
              )}
              {deviceCode === 2 && (
                <TabPanel header="OCCASIONS">
                  <Occasions
                    compareBy={compareBy}
                    selectedComparitionBy={selectedComparitionBy}
                    apply={apply}
                    setApply={setApply}
                    isCompareByClicked={isCompareByClicked}
                    setIsCompareByClicked={setIsCompareByClicked}
                    chooseComparison={chooseComparison}
                  />
                </TabPanel>
              )}
              {deviceCode === 2 && (
                <TabPanel header="NEEDS">
                  <Needs
                    compareBy={compareBy}
                    selectedComparitionBy={selectedComparitionBy}
                    apply={apply}
                    setApply={setApply}
                    isCompareByClicked={isCompareByClicked}
                    setIsCompareByClicked={setIsCompareByClicked}
                    chooseComparison={chooseComparison}
                  />
                </TabPanel>
              )}
              {deviceCode === 2 && (
                <TabPanel header="EXPERIENCE">
                  <Experience
                    compareBy={compareBy}
                    selectedComparitionBy={selectedComparitionBy}
                    apply={apply}
                    setApply={setApply}
                    isCompareByClicked={isCompareByClicked}
                    setIsCompareByClicked={setIsCompareByClicked}
                    chooseComparison={chooseComparison}
                  />
                </TabPanel>
              )}
              {/* {deviceCode === 2 && (
                <TabPanel header="LOYALTY">
                  <Loyalty />
                </TabPanel>
              )} */}
              {deviceCode === 2 && (
                <TabPanel header="USER SUMMARY">
                  <UserSummary
                    compareBy={compareBy}
                    selectedComparitionBy={selectedComparitionBy}
                    apply={apply}
                    setApply={setApply}
                    isCompareByClicked={isCompareByClicked}
                    setIsCompareByClicked={setIsCompareByClicked}
                    chooseComparison={chooseComparison}
                  />
                </TabPanel>
              )}
            </TabView>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
