import React, { useEffect, useRef } from "react";

export const generateCaptcha = () => {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let captcha = "";

  for (let i = 0; i < 5; i++) {
    captcha += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return captcha;
};

const CaptchaImage = ({ captcha }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const { width, height } = canvas;

    // Clear canvas
    ctx.clearRect(0, 0, width, height);

    // Draw text with slight distortion
    ctx.font = "30px Arial";
    ctx.fillStyle = "black";
    for (let i = 0; i < captcha.length; i++) {
      const xOffset = Math.random() * 5; // Random offset for slight distortion
      const yOffset = Math.random() * 5;
      ctx.fillText(captcha.charAt(i), 10 + i * 30 + xOffset, 40 + yOffset);
    }

    // Add noise
    for (let i = 0; i < 50; i++) {
      ctx.beginPath();
      // prettier-ignore
      ctx.arc(
        Math.random() * width,
        Math.random() * height,
        Math.random() * 2,
        0,
        2 * Math.PI,
      );
      ctx.fill();
    }
  }, [captcha]);

  return <canvas ref={canvasRef} width={200} height={50} />;
};

export default CaptchaImage;
