import React from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

const StepOne = ({ email, setEmail, error, handleNext }) => {
  return (
    <>
      {/* Desktop View Start */}
      <div className="login-form-pad-desktop d-none d-md-block">
        <div className="d-flex align-items-center">
          <span className="pi pi-at me-2 text-secondary"></span>
          <InputText
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter Email Address"
            className="username w-100"
          />
        </div>
        {error && (
          <div className="error-message text-danger align-items-left fs-error">
            {error}
          </div>
        )}
        <div className="text-center pt-1 mb-5 mt-4 pb-1">
          <Button
            label="NEXT"
            onClick={handleNext}
            className="mb-4 w-100 fw-600 next-button"
          />
        </div>
      </div>
      {/* Desktop View End */}
      {/* Mobile View Start */}
      <div className="login-form-pad-mobile d-block d-md-none">
        <div className="mb-4 d-flex align-items-center">
          <span className="pi pi-at me-2 text-secondary"></span>
          <InputText
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter Email Address"
            className="username w-100"
          />
        </div>
        {error && (
          <div className="error-message text-danger align-items-left fs-error">
            {error}
          </div>
        )}
        <div className="text-center pt-1 mb-5 pb-1">
          <Button
            label="NEXT"
            onClick={handleNext}
            className="mb-4 w-100 fw-600 next-button"
          />
        </div>
      </div>
      {/* Mobile View End */}
    </>
  );
};
export default StepOne;
