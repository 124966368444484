import React, { createContext, useContext, useState, useEffect } from "react";

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  // Retrieve initial values from sessionStorage or set defaults
  const [device, setDevice] = useState(
    sessionStorage.getItem("device") || null,
  );
  const [deviceCode, setDeviceCode] = useState(
    sessionStorage.getItem("deviceCode") || null,
  );
  const [category, setCategory] = useState(
    sessionStorage.getItem("category") || null,
  );
  const [product_id, setProductId] = useState(
    sessionStorage.getItem("product_id") || null,
  );
  // Save to sessionStorage whenever the state changes
  useEffect(() => {
    if (device !== null) {
      sessionStorage.setItem("device", device);
    }
  }, [device]);

  useEffect(() => {
    if (deviceCode !== null) {
      sessionStorage.setItem("deviceCode", deviceCode);
    }
  }, [deviceCode]);

  useEffect(() => {
    if (category !== null) {
      sessionStorage.setItem("category", category);
    }
  }, [category]);

  useEffect(() => {
    if (product_id !== null) {
      sessionStorage.setItem("product_id", product_id);
    }
  }, [product_id]);

  return (
    <GlobalContext.Provider
      value={{
        device,
        setDevice,
        deviceCode,
        setDeviceCode,
        category,
        setCategory,
        product_id,
        setProductId,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
