import axios from "axios";
import { getApiBaseUrl } from "../../config";

const apiPrefixReports = getApiBaseUrl("reports");
const apiPrefixCoaster = getApiBaseUrl("coaster");

// sensor API Calls
export const getUsePerDayChartData = async (req_body, authtoken) => {
  return await axios.post(
    `${apiPrefixReports}/api/v1/svc-smart-tag/charts-total-average`,
    req_body,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};

export const getDayOfWeekChart = async (req_body, authtoken) => {
  return await axios.post(
    `${apiPrefixReports}/api/v1/svc-smart-tag/charts-daily-average`,
    req_body,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};

export const getWeekPartChart = async (req_body, authtoken) => {
  return await axios.post(
    `${apiPrefixReports}/api/v1/svc-smart-tag/charts-weekly-average`,
    req_body,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};

export const getTimeOfDayChart = async (req_body, authtoken) => {
  return await axios.post(
    `${apiPrefixReports}/api/v1/svc-smart-tag/charts-day-average`,
    req_body,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};

//Coaster API Calls
export const getUsePerDayAndWeekChartDataForCoaster = async (
  req_body,
  authtoken,
) => {
  return await axios.post(
    `${apiPrefixCoaster}/api/v1/frequency-use-compare`,
    req_body,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};

export const getDayOfWeekAndWeekPartChartForCoaster = async (
  req_body,
  authtoken,
) => {
  return await axios.post(
    `${apiPrefixCoaster}/api/v1/frequency-days-or-week-compare`,
    req_body,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};

export const getTimeOfDayChartForCoaster = async (req_body, authtoken) => {
  return await axios.post(
    `${apiPrefixCoaster}/api/v1/frequency-time-compare`,
    req_body,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};
