import React from "react";
import { Chart } from "primereact/chart";

const TopLikesUsers = () => {
  const data = {
    labels: ["PLEASENT SCENT", "VALUE FOR MONEY", "ODOR REMOVAL", "PACKAGING"],
    datasets: [
      {
        label: "CATEGORY",
        backgroundColor: "#FF9800", // Corrected color definition
        data: [60, 26, 18, 18],
      },
      {
        label: "TIDE",
        backgroundColor: "#2196F3", // Corrected color definition
        data: [76, 13, 15, 15],
      },
      {
        label: "GAIN",
        backgroundColor: "#F44336", // Corrected color definition
        data: [50, 36, 12, 12],
      },
    ],
  };

  const options = {
    indexAxis: "y", // Horizontal bar chart
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      datalabels: {
        color: "black",
        anchor: "end",
        align: "right",
        font: {
          weight: "bold",
          size: 12,
        },
        formatter: (value) => (value !== 0 ? value : ""),
      },
    },
  };

  return (
    <>
      <div className="fs-14 text-start mt-4 mb-4">
        <strong>TOP LIKES - % USERS</strong>
      </div>
      <div className="col-12">
        <Chart type="bar" data={data} options={options} />
      </div>
    </>
  );
};

export default TopLikesUsers;
