import React from "react";
import { Chart } from "primereact/chart";

const HouseholdIncome = () => {
  const data = {
    labels: ["< 49K", "50-99K", "100-149K", "150-199K", "200K+"],
    datasets: [
      {
        data: [25, 29, 15, 10, 5],
        backgroundColor: "#2196F3",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: "black",
        anchor: "end",
        align: "top",
        font: {
          weight: "bold",
          size: 12,
        },
        formatter: (value) => (value !== 0 ? value : ""),
      },
    },
  };

  return (
    <>
      <div className="fs-14 text-start mt-4 mb-4">
        <strong>HOUSEHOLD INCOME - % USERS</strong>
      </div>
      <div className="col-12">
        <Chart type="bar" data={data} options={options} />
      </div>
    </>
  );
};

export default HouseholdIncome;
