import React from "react";
import { Card } from "primereact/card";
import { Panel } from "primereact/panel";
import { Divider } from "primereact/divider";
import HouseHoldFrequencyTable from "./GrowYourBrandComponent/HouseHoldFrequencyTable";
import ChartDivider from "../../ChartDivider";

const GrowYourBrand = () => {
  const TitleRow = () => {
    return (
      <>
        <div className="row">
          {/* Empty space column */}
          <div
            className="col-12 col-md-4"
            style={{
              backgroundColor: "transparent",
              height: "1px", // Maintain space in mobile
            }}
          ></div>

          {/* Content column */}
          <div className="col-12 col-md-8">
            <h6
              className="fs-16 fw-600 p-3 text_dark"
              style={{ margin: "0px 0px -10px 0px" }}
            >
              AVERAGE HOUSEHOLD USAGE
            </h6>
          </div>
        </div>
        <div className="container px-3">
          <Divider type="solid" />
        </div>
      </>
    );
  };

  const PanelTitle = () => {
    return (
      <span className="fw-600 text_dark" style={{ color: "#000" }}>
        HOUSEHOLD FREQUENCY
      </span>
    );
  };

  return (
    <div className="mt-4" style={{ maxWidth: "100%" }}>
      <div className="mb-4">
        <h5 className="fw-bold mb-1 text-start text_dark">GROW YOUR BRAND</h5>
        <p
          className="text-uppercase mb-0 text-start fw-bold fs-12"
          style={{ color: "#9E9E9E" }}
        >
          Understand usage habits in your category to unlock opportunities to
          grow through accelerated usage amount and/or frequency
        </p>
      </div>
      <Card title={<TitleRow />} className="card-style" unstyled={true}>
        <div className="row">
          {/* Data rows for headers */}
          <div className="col-12">
            <div className="row text-center justify-content-between mt-2 align-items-center">
              <div className="col-4"></div>
              <div className="col-2">
                <span className="household-chip header-chip text_dark">
                  1.2x
                </span>
                <p className="text-uppercase fw-bold fs-12 text-dark-gray header-label">
                  PER DAY
                </p>
              </div>
              <div className="col-3">
                <span className="household-chip header-chip text_dark">
                  4.0x
                </span>
                <p className="text-uppercase fw-bold fs-12 text-dark-gray header-label">
                  PER WEEK
                </p>
              </div>
              <div className="col-3">
                <span className="household-chip header-chip text_dark">
                  2.3
                </span>
                <p className="text-uppercase fw-bold fs-12 text-dark-gray header-label">
                  GRAMS PER USE
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Divider */}
        <div className="container px-3">
          <Divider type="solid" />
        </div>

        {/* Table */}
        <Panel
          className="text-start px-3"
          header={<PanelTitle />}
          toggleable
          expandIcon="pi pi-angle-up"
          collapseIcon="pi pi-angle-down"
          collapsed={false}
        >
          <div className="d-flex flex-column gap-3 panel-height">
            <HouseHoldFrequencyTable />
          </div>
        </Panel>
      </Card>
      <ChartDivider />
    </div>
  );
};

export default GrowYourBrand;
