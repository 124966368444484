import axios from "axios";
import { getApiBaseUrl } from "../../config";

const apiPrefixCoaster = getApiBaseUrl("coaster");

export const getOuncesAndGramsPerUseChartData = async (req_body, authtoken) => {
  return await axios.post(
    `${apiPrefixCoaster}/api/v1/amount-per-use-compare`,
    req_body,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};

export const getTimeOfDayChartData = async (req_body, authtoken) => {
  return await axios.post(
    `${apiPrefixCoaster}/api/v1/amount-time-compare`,
    req_body,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};

export const getDayOfWeekAndWeekPartChartData = async (req_body, authtoken) => {
  return await axios.post(
    `${apiPrefixCoaster}/api/v1/amount-days-or-week-compare`,
    req_body,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};
