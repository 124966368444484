import React from "react";
import pg_logo from "../../assets/images/pg-logo.png";
import { Button } from "primereact/button";

const StepThree = () => {
  return (
    <>
      {/* Desktop View Start */}
      <div className="login-form-pad-p-and-g d-none d-md-block">
        <div>
          <p>Login for:</p>
        </div>
        <div>
          <img
            src={pg_logo}
            style={{ width: "100px", height: "100px" }}
            alt="logo"
            className="image-fluid"
          />
        </div>
        <div className="text-center pt-1 mt-4 pb-1">
          <Button label="SSO LOGIN" className="mb-4 w-100 next-button" />
        </div>
        <div className="mt-4 mb-5 fw-600">
          <p className="read-terms">
            By clicking "Login" you agree with the{" "}
            <span className="decoration">Terms of Service</span> and{" "}
            <span className="decoration">Privacy Policy</span>
          </p>
        </div>
      </div>
      {/* Desktop View end */}
      {/* Mobile View Start */}
      <div className="login-form-pad-p-and-g d-block d-md-none">
        <div>
          <p>Login for:</p>
        </div>
        <div>
          <img
            src={pg_logo}
            style={{ width: "100px", height: "100px" }}
            alt="logo"
            className="image-fluid"
          />
        </div>
        <div className="text-center pt-1 mt-4 pb-1">
          <Button label="SSO LOGIN" className="mb-4 w-100 fw-600 next-button" />
        </div>
        <div className="mt-4 mb-5 fw-600">
          <p className="read-terms">
            By clicking "Login" you agree with the{" "}
            <span className="decoration">Terms of Service</span> and{" "}
            <span className="decoration">Privacy Policy</span>
          </p>
        </div>
      </div>
      {/* Mobile View end */}
    </>
  );
};
export default StepThree;
