export const handleSelectAll = (currentSelections, options) => {
  if (currentSelections.length === options.length) {
    return [];
  } else {
    return [...options];
  }
};

export const handleSelectOption = (currentSelections, optionKey) => {
  if (currentSelections?.includes(optionKey)) {
    return currentSelections?.filter((key) => key !== optionKey);
  } else {
    return [...currentSelections, optionKey];
  }
};
export const handleSelectOptionOccasions = (
  currentSelections = [],
  optionKey,
) => {
  if (!optionKey) {
    return currentSelections;
  }
  const optionKeyStr = String(optionKey);
  let updatedSelections;
  if (currentSelections.includes(optionKeyStr)) {
    updatedSelections = currentSelections.filter((key) => key !== optionKeyStr);
  } else {
    updatedSelections = [...currentSelections, optionKeyStr];
  }
  return updatedSelections;
};

export const isAllSelected = (currentSelections, options) => {
  return currentSelections?.length === options?.length;
};

export const isAllSelectedHold = (currentSelections, options) => {
  return currentSelections.length === options.length + 1;
};

export const getFilteredOptions = (list) => {
  return list.length > 0
    ? [
        { phu_id: 0, uniques: "ALL" },
        ...list.filter((item) => item.uniques !== "ALL"),
      ]
    : [];
};

export const getFilteredOptionsWithoutPhuId = (
  list,
  activeOption,
  maxSelection,
) => {
  if (activeOption === "COMPAREBY") {
    return list.length > 0 && list.length < maxSelection
      ? ["ALL", ...list.filter((item) => item !== "ALL")]
      : list.filter((item) => item !== "ALL");
  } else {
    return list.length > 0 ? ["ALL", ...list] : [];
  }
};

export const validateCompareObjectValues = (obj) => {
  if (!obj || typeof obj !== "object") {
    return false;
  }
  return Object.values(obj).some((item) => item?.value?.trim() !== "");
};
