export const getUserSummaryChartOptions = (occasionsChartData) => {
  const allDataValues =
    occasionsChartData?.datasets?.flatMap((dataset) => dataset.data) || [];
  const maxValue = Math.max(...allDataValues, 0) + 1;

  return {
    indexAxis: "y", // Horizontal bar chart
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        display: false,
        position: "top",
        align: "start",
        labels: {
          padding: 15,
          font: { size: 12, weight: "bold" },
          color: "#6C757D",
        },
      },
      datalabels: {
        color: "#000",
        anchor: "end",
        align: "right",
        font: { weight: "bold", size: 12 },
        formatter: (value) => (value !== 0 ? value : ""),
      },
    },
    scales: {
      x: {
        stacked: false, // Stack the bars horizontally
        suggestedMax: maxValue,
        ticks: {
          stepSize: 10, // Defines intervals
          font: { size: 12, weight: "bold" },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          font: { size: 12, weight: "bold" },
        },
      },
    },
  };
};
