import React, { useState } from "react";
import { Card } from "primereact/card";
import { TabView, TabPanel } from "primereact/tabview";
import "../../assets/css/dashboard.css"; // Ensure your CSS file is correctly imported
import Usage from "../dashboardTabs/usage/Usage";
import Occasions from "../dashboardTabs/occasions/Occasions";
import Hml from "../dashboardTabs/hml/Hml";
import { useGlobalContext } from "../../context/GlobalContext";
import Needs from "../dashboardTabs/needs/Needs";
import Loyalty from "../dashboardTabs/loyalty/Loyalty";
import Experience from "../dashboardTabs/experience/Experience";
import UserProfile from "../dashboardTabs/userProfile/UserProfile";

const Dashboard = () => {
  const { device, deviceCode, category } = useGlobalContext();
  return (
    <div className="container-fluid page-wrapper">
      <div className="row">
        <div className="col-12 col-md-12">
          <Card>
            <TabView>
              <TabPanel header="USAGE" className="tabStyle">
                <Usage
                  device={device}
                  deviceCode={deviceCode}
                  category={category}
                />
              </TabPanel>
              <TabPanel header="OCCASIONS">
                <Occasions />
              </TabPanel>
              <TabPanel header="H/M/L">
                <Hml />
              </TabPanel>
              <TabPanel header="NEEDS">
                <Needs />
              </TabPanel>
              <TabPanel header="LOYALTY">
                <Loyalty />
              </TabPanel>
              <TabPanel header="EXPERIENCE">
                <Experience />
              </TabPanel>
              <TabPanel header="USER PROFILE">
                <UserProfile />
              </TabPanel>
            </TabView>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
