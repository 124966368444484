import axios from "axios";
import { getApiBaseUrl } from "../../config";

const apiPrefixReports = getApiBaseUrl("reports");

export const getHouseHoldMasterList = async (req_body, authtoken) => {
  return await axios.post(
    `${apiPrefixReports}/api/v1/svc-smart-tag/household-master-list`,
    req_body,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};

export const getUserSummaryChartData = async (req_body, authtoken) => {
  return await axios.post(
    `${apiPrefixReports}/api/v1/svc-smart-tag/charts-household-average`,
    req_body,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};
