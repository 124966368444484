import React, { useState } from "react";
import { Chart } from "primereact/chart";
import ChartDivider from "../../ChartDivider";
import { Button } from "primereact/button";
import ChartSkeleton from "../../../Skeletons/ChartSkeleton";
import { getTimeOfDayChartOptions } from "../../../../utils/chartOptions/dashboard/frequency";

const TimeOfDay = React.memo(function TimeOfDay(props) {
  const options = getTimeOfDayChartOptions(props?.activeButtonTimeOfDay);
  const ToggleButtons = () => {
    const handleClick = (label) => {
      props?.setActiveButtonTimeOfDay(label);
    };

    return (
      <div
        className="d-flex align-items-center gap-2"
        style={{ float: "right" }}
      >
        {/* <Button
          label="% OF TOTAL"
          className={`px-3 py-2 fw-bold fs-6 ${
            props?.activeButtonTimeOfDay === "% OF TOTAL"
              ? "btn-primary text-white"
              : "btn-light text-dark"
          }`}
          onClick={() => handleClick("% OF TOTAL")}
          style={{
            borderRadius: "10px",
            border: "none",
            height: "25px",
            backgroundColor:
              props?.activeButtonTimeOfDay === "% OF TOTAL"
                ? "#002D42"
                : "#E0E0E0",
          }}
        />
        <Button
          label="# OF USES"
          className={`px-3 py-2 fw-bold fs-6 ${
            props?.activeButtonTimeOfDay === "# OF USES"
              ? "btn-primary text-white"
              : "btn-light text-dark"
          }`}
          onClick={() => handleClick("# OF USES")}
          style={{
            borderRadius: "10px",
            border: "none",
            height: "25px",
            backgroundColor:
              props?.activeButtonTimeOfDay === "# OF USES"
                ? "#002D42"
                : "#E0E0E0",
          }}
        /> */}
      </div>
    );
  };

  return (
    <>
      <div className="mt-4" style={{ maxWidth: "100%" }}>
        <div className="mb-4">
          <h5 className="text-uppercase fw-bold mb-1 text-start">
            <span>Time of day</span>
            <ToggleButtons />
          </h5>
          <p
            className="text-uppercase mb-0 text-start fw-bold fs-12 py-3"
            style={{ color: "#6c757d" }}
          >
            {props?.activeButtonTimeOfDay}
          </p>
          <div className="col-12">
            {!props?.timeOfDayLoader ? (
              <Chart
                type="line"
                data={props?.timeOfDayGraph}
                options={options}
              />
            ) : (
              <ChartSkeleton />
            )}
          </div>
          <ChartDivider />
        </div>
      </div>
    </>
  );
});

export default TimeOfDay;
