import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ children, isLoginRoute = false }) => {
  // Access the auth state from Redux store
  const auth = useSelector((state) => state.auth);

  // Check if the user is authenticated
  const isAuthenticated = auth?.isAuthenticated;

  // Prevent authenticated users from accessing the login page
  if (isAuthenticated && isLoginRoute) {
    return <Navigate to="/dashboard" replace />;
  }

  // If user is not authenticated and trying to access protected routes
  if (!isAuthenticated && !isLoginRoute) {
    return <Navigate to="/login" replace />;
  }

  // If the user is authenticated and it's not the login route, allow access
  return children;
};

export default ProtectedRoute;
