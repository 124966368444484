import React from "react";
import { Chart } from "primereact/chart";

const Gender = () => {
  const data = {
    labels: ["WOMEN", "MEN"],
    datasets: [
      {
        data: [36, 64],
        backgroundColor: ["#2196F3", "#FF9800"], // Static colors
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
        },
      },
      datalabels: {
        formatter: (value, context) => {
          const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
          const percentage = ((value / total) * 100).toFixed(0); // Convert to percentage
          return `${percentage}%`; // Show percentage
        },
        color: "#fff", // Text color for labels
        font: {
          weight: "bold",
        },
      },
    },
  };

  return (
    <>
      <div className="fs-14 text-start mt-4 mb-4">
        <strong>GENDER - % USERS</strong>
      </div>
      {/* Use Bootstrap classes to center the chart */}
      <div className="d-flex justify-content-center align-items-center">
        <Chart type="pie" data={data} options={options} />
      </div>
    </>
  );
};

export default Gender;
