import React from "react";
import { Chart } from "primereact/chart";

const WeekPart = () => {
  const data = {
    labels: ["Weekday (M-F)", "Weekend (S-S)"],
    datasets: [
      {
        label: "CATEGORY",
        data: [51, 31],
        backgroundColor: "orange",
      },
      {
        label: "TIDE",
        data: [73, 23],
        backgroundColor: "dodgerblue",
      },
      {
        label: "GAIN",
        data: [25, 55],
        backgroundColor: "#EE534F",
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      datalabels: {
        color: "black",
        anchor: "end",
        align: "top",
        font: {
          weight: "bold",
          size: 12,
        },
        formatter: (value) => (value !== 0 ? value : ""),
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: 150, // Adjust the maximum value as needed
      },
    },
  };

  return (
    <>
      <div className="fs-14 text-start mt-4 mb-4">
        <strong>WEEK PART - AVERAGE DAILY USES</strong>
      </div>
      <div className="col-12">
        <Chart type="bar" data={data} options={options} />
      </div>
    </>
  );
};

export default WeekPart;
