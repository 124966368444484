import React from "react";
import { Chart } from "primereact/chart";
import ChartDivider from "../../ChartDivider";
import ChartSkeleton from "../../../Skeletons/ChartSkeleton";

const OverallSatisfaction = React.memo(function OverallSatisfaction(props) {
  const maxDataValue = Math.max(
    ...(Array.isArray(props?.overAllSatisfactionChartData?.datasets) // Ensure datasets is an array
      ? props.overAllSatisfactionChartData.datasets.flatMap(
          (dataset) => (Array.isArray(dataset?.data) ? dataset.data : []), // Ensure dataset.data is an array
        )
      : [0]), // Default to [0] if datasets is undefined or not an array
  );

  const suggestedMax = Math.ceil(maxDataValue / 10) * 10 + 10; // Rounds up to nearest 10 and adds extra 10%

  const options = {
    barThickness:
      props?.overAllSatisfactionChartData?.datasets === 1 ? 300 : undefined,
    indexAxis: "y", // Horizontal bar chart
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "start",
        labels: {
          padding: 15,
          font: { size: 12, weight: "bold" },
          color: "#6C757D",
        },
      },
      datalabels: {
        color: "#000",
        anchor: "end",
        align: "right",
        font: { weight: "bold", size: 12 },
        formatter: (value) => (value !== 0 ? value : ""),
      },
    },
    scales: {
      x: {
        stacked: false, // Stack the bars horizontally
        suggestedMax: suggestedMax,
        ticks: {
          font: { size: 12, weight: "bold" },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          font: { size: 12, weight: "bold" },
        },
      },
    },
  };

  const secondaryText = (value) => {
    switch (value) {
      case "Single":
        return "Top Box";
      case "Two":
        return "Top Two Box";
      case "Mean":
        return "Mean Score";
      default:
        return "Full Data";
    }
  };

  return (
    <div className="mt-4" style={{ maxWidth: "100%" }}>
      <div className="mb-4">
        <h5 className="text-uppercase fw-bold mb-1 text-start">
          Overall Satisfaction
        </h5>
        <p
          className="text-uppercase mb-0 text-start fw-bold fs-12 py-3"
          style={{ color: "#6c757d" }}
        >
          % {secondaryText(props?.activeDataTypeButton)}
        </p>
        <div className="col-12 pb-4">
          {!props?.loading ? (
            <Chart
              type="bar"
              data={props?.overAllSatisfactionChartData}
              options={options}
            />
          ) : (
            <ChartSkeleton />
          )}
        </div>
        <p
          style={{ width: "65%" }}
          className="ms-5 p-2 fs-12 float-inherit text-start rounded-2 fw-bold border text-secondary"
        >
          {props?.chartQuestion}
        </p>
        <ChartDivider />
      </div>
    </div>
  );
});

export default OverallSatisfaction;
