import React from "react";
import { Chart } from "primereact/chart";
import ChartDivider from "../../ChartDivider";
import ChartSkeleton from "../../../Skeletons/ChartSkeleton";
import { getOuncePerUSeChartOptions } from "../../../../utils/chartOptions/dashboard/amount";
const OuncesPerUse = React.memo(function OuncesPerUse(props) {
  const options = getOuncePerUSeChartOptions(props?.chartData);

  return (
    <>
      <div className="mt-4" style={{ maxWidth: "100%" }}>
        <div className="mb-4">
          <h5 className="text-uppercase fw-bold mb-1 text-start">
            Grams PER USE
          </h5>
          <p
            className="text-uppercase mb-0 text-start fw-bold fs-12 py-3"
            style={{ color: "#6c757d" }}
          >
            Average
          </p>
          <div className="col-12">
            {!props?.loading ? (
              <Chart type="bar" data={props?.chartData} options={options} />
            ) : (
              <ChartSkeleton />
            )}
          </div>
          <ChartDivider />
        </div>
      </div>
    </>
  );
});

export default OuncesPerUse;
