import React, { useRef } from "react";
import { Button } from "primereact/button";
import "../../assets/css/dashboard.css";
import { useNavigate } from "react-router-dom";
import { Avatar } from "primereact/avatar";
import { OverlayPanel } from "primereact/overlaypanel";
import { useSelector, useDispatch } from "react-redux";
import { persistStore } from "redux-persist";
import { store } from "../../store/store";
import { LOGOUT } from "../../utils/constants";

const Header = ({ handleShowSidebar, showSidebar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const op = useRef(null);

  const handleButtonClick = (buttonLabel) => {
    if (buttonLabel === "DASHBOARD") {
      navigate("/dashboard");
    } else if (buttonLabel === "REPORTS") {
      navigate("/reports");
    }
  };

  const handleDropdownClick = (option) => {
    if (option.value === "logout") {
      logout();
    } else {
      navigate(`/${option.value}`);
    }
  };

  const logout = () => {
    dispatch({ type: LOGOUT });

    // Ensure persist store is cleared
    persistStore(store)
      .purge()
      .then(() => {
        localStorage.clear(); // Extra safety to clear local storage
        navigate("/login"); // Redirect to login page after logout
      });
  };

  return (
    <>
      {/* Top Header */}
      <div className={`d-flex align-items-center p-3 border-bottom position-fixed top-header justify-content-between ${!showSidebar ? 'hide-sidebar' : ''}`}>
        <div className="text-start d-flex align-items-center justify-content-center">
          <div className="text-start bars-icon d-flex align-items-center justify-content-center" onClick={handleShowSidebar}>
            <i className="pi pi-bars"></i>
          </div>
          <p
            className="mb-0 text-start ps-2 fw-600 text-uppercase"
            style={{ color: "#002133" }}
          >
            Smart Panel
          </p>
        </div>
        <div className="d-flex align-items-center gap-2">
          <span className="fw-bold text-dark">
            Welcome {user && user.firstname ? user.firstname : "User"}
          </span>
          <Avatar
            icon="pi pi-user"
            shape="circle"
            onClick={(e) => op.current.toggle(e)}
            style={{
              backgroundColor: "#9c27b0",
              color: "#ffffff",
              cursor: "pointer",
            }}
          />
          <OverlayPanel ref={op}>
            <div
              className="p-2"
              onClick={logout} // ✅ Correctly invokes logout
              style={{ cursor: "pointer" }}
            >
              Logout
            </div>
          </OverlayPanel>
        </div>
      </div>

      {/* Second Header */}
      <div className={`d-flex flex-column flex-md-row py-2 border-bottom position-fixed second-header w-100 ${!showSidebar ? 'hide-sidebar' : ''}`}>
        <div className="flex-grow-1 ms-4 text-start flex-wrap mb-2 mb-md-0">
          <Button
            outlined
            className={`me-2 me-md-3 ${
              location.pathname === "/dashboard" ? "active-button" : ""
            } br-c`}
            label="DASHBOARD"
            onClick={() => handleButtonClick("DASHBOARD")}
          />
        </div>
      </div>
    </>
  );
};

export default Header;
