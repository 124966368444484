import React from "react";
import FilterByForDashboard from "../FilterByForDashboard";
import ChartDivider from "../ChartDivider";
import TimeOfDay from "./occasionsComponents/TimeOfDay";
import DayOfWeek from "./occasionsComponents/DayOfWeek";
import WeekPart from "./occasionsComponents/WeekPart";
import OccasionDescription from "./occasionsComponents/OccasionDescription";

const Occasions = () => {
  return (
    <div className="row">
      {/* Filter By Component */}
      <FilterByForDashboard />
      <div className="col-9" style={{ padding: "0px 5%" }}>
        <TimeOfDay />
        <ChartDivider />
        <DayOfWeek />
        <ChartDivider />
        <WeekPart />
        <ChartDivider />
        <OccasionDescription />
        <hr style={{ margin: "2rem 0" }} />
      </div>
    </div>
  );
};
export default Occasions;
