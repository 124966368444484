import axios from "axios";
import { getApiBaseUrl } from "../../config";

const apiPrefixReports = getApiBaseUrl("reports");
const apiPrefixCoaster = getApiBaseUrl("coaster");

export const getProductType = async (product_type_req_body, authtoken) => {
  return await axios.post(
    `${apiPrefixReports}/api/v1/svc-smart-tag/product-type`,
    product_type_req_body,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};

export const getHouseHoldData = async (id, authtoken) => {
  return await axios.get(`${apiPrefixCoaster}/api/v1/hh-details/${id}`, {
    headers: {
      token: authtoken,
    },
  });
};

export const getSegmentsList = async (req_body, authtoken) => {
  return await axios.post(
    `${apiPrefixReports}/api/v1/svc-smart-tag/participants-segments`,
    req_body,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};

export const getBrandsFormsAndSize = async (req_body, authtoken) => {
  return await axios.post(
    `${apiPrefixReports}/api/v1/svc-smart-tag/charts-barcode-details`,
    req_body,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};

export const getFilterOptionsForCoaster = async (
  studyID,
  catName,
  type,
  authtoken,
) => {
  return await axios.get(
    `${apiPrefixCoaster}/api/v1/barcode-detail/${studyID}/${catName}/${type}`,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};

export const getOccasions = async (req_body, authtoken) => {
  return await axios.post(
    `${apiPrefixReports}/api/v1/svc-smart-tag/occasions-list`,
    req_body,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};

export const getFiltersOptionsBasedOnFilterName = async (
  study_id,
  filter_name,
  authtoken,
) => {
  return await axios.get(
    `${apiPrefixCoaster}/api/v1/barcode-detail/${study_id}/catName/${filter_name}`,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};
