import React from "react";
import FilterByForDashboard from "../FilterByForDashboard";
import ChartDivider from "../ChartDivider";
import Gender from "./userProfileComponents/Gender";
import Age from "./userProfileComponents/Age";
import HouseholdIncome from "./userProfileComponents/HouseholdIncome";
import HouseholdSize from "./userProfileComponents/HouseholdSize";
import HouseholdMembersLivingWith from "./userProfileComponents/HouseholdMembersLivingWith";
import Ethnicity from "./userProfileComponents/Ethnicity";

const UserProfile = (props) => {
  return (
    <div className="row">
      {/* Filter By Component */}
      <FilterByForDashboard />
      <div className="col-9" style={{ padding: "0px 5%" }}>
        <Gender />
        <ChartDivider />
        <Age />
        <ChartDivider />
        <HouseholdIncome />
        <ChartDivider />
        <HouseholdSize />
        <ChartDivider />
        <HouseholdMembersLivingWith />
        <ChartDivider />
        <Ethnicity />
        <hr style={{ margin: "2rem 0" }} />
      </div>
    </div>
  );
};
export default UserProfile;
