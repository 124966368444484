import React, { useState } from "react";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";

const DataAndQuantityType = (props) => {
  // Button options
  const buttonOptions = [
    { name: "TOP BOX (%)", value: "Single" },
    { name: "TOP TWO BOX (%)", value: "Two" },
    { name: "MEAN SCORE (AVG)", value: "Mean" },
    { name: "FULL DATA", value: "Full" },
  ];

  // Handler to update active button
  const handleButtonClick = (option) => {
    props?.setActiveDataTypeButton(option.value);
  };

  return (
    <>
      <div className="d-flex py-4">
        {buttonOptions.map((option, index) => (
          <Button
            key={index}
            className={`fw-bold btn me-2 ${
              props?.activeDataTypeButton === option.value
                ? "active-button br-c"
                : "light-bg br-c"
            }`}
            onClick={() => handleButtonClick(option)}
            label={option.name}
          />
        ))}
      </div>
      <Divider />
    </>
  );
};

export default DataAndQuantityType;
