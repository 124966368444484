export const getNeedsChartOptions = (chartData) => {
  const allDataValues =
    chartData?.datasets?.flatMap((dataset) => dataset.data) || [];
  const maxValue = Math.max(...allDataValues, 0) + 25;

  return {
    indexAxis: "y",
    maintainAspectRatio: false,
    aspectRatio: 0.2,
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "start",
        labels: {
          padding: 15,
          font: { size: 12, weight: "bold" },
          color: "#6C757D",
        },
      },
      datalabels: {
        color: "#000",
        anchor: "end",
        align: "right",
        font: { weight: "bold", size: 12 },
        formatter: (value) => (value !== 0 ? value : ""),
      },
    },
    scales: {
      x: {
        stacked: false,
        suggestedMax: maxValue,
        ticks: {
          font: { size: 12, weight: "bold" },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          font: { size: 12, weight: "bold" },
          callback: function (value) {
            const label = this.getLabelForValue(value);
            const maxCharsPerLine = 35;
            let lines = [];

            for (let i = 0; i < label.length; i += maxCharsPerLine) {
              lines.push(label.slice(i, i + maxCharsPerLine));
            }

            return lines;
          },
        },
      },
    },
  };
};
