import React from "react";
import { Chart } from "primereact/chart";

const DailyAverage = () => {
  const dailyAverageData = {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: [
      {
        label: "",
        data: [0.7, 0.49, 0.59, 0.74, 0.82, 0.65, 0.55],
        backgroundColor: "#FF9800",
        participant: [31, 31, 31, 32, 32, 32, 32],
      },
    ],
  };
  const dailyAverageOptions = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 10, // Ensures a difference of 10 units between y-axis ticks
        },
      },
    },
  };

  return (
    <div className="col-12">
      <strong className="fs-14 float-start">
        # OF USES PER DAY OF WEEK - DAILY AVERAGE
      </strong>
      <Chart type="bar" data={dailyAverageData} options={dailyAverageOptions} />
      <div className="my-table data-table">
        <div className="table-responsive">
          <table className="table text-start">
            <thead>
              <tr className="table-first-row-border">
                <th></th>
                {dailyAverageData.labels.map((label) => (
                  <th key={label} style={{ color: "#B1B1B1" }}>
                    {label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong style={{ color: "#949494" }}>AVERAGE</strong>
                </td>
                {dailyAverageData.datasets[0].data.map((data, index) => (
                  <td key={index} style={{ color: "#949494" }}>
                    {data}
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <strong style={{ color: "#949494" }}>
                    # of Participants
                  </strong>
                </td>
                {dailyAverageData.labels.map((_, index) => (
                  <td key={index} style={{ color: "#949494" }}>
                    57
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default DailyAverage;
