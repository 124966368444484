import React, { useCallback, useEffect, useState } from "react";
import { Panel } from "primereact/panel";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import _debounce from 'lodash/debounce';
import { PROJECT_ID } from "../../utils/constants";
import { useGlobalContext } from "../../context/GlobalContext";
import {
  getProductType,
  getBrandsFormsAndSize,
} from "../../services/filterOptions/filter";
import handleError from "../../utils/handleError";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import CompareByColumnsSkeleton from "../Skeletons/CompareByColumnsSkeleton";
import { getFilterOptionsForCoaster } from "../../services/filterOptions/filter";
import { ScrollPanel } from "primereact/scrollpanel";

const CompareBy = ({
  loading,
  setLoading,
  setCompareBy,
  setSelectedComparisonBy,
  setApply,
  apply,
  setIsCompareByClicked,
  isCompareByClicked,
  chooseComparison,
  setChooseComparison,
}) => {
  const [brandsList, setBrandsList] = useState([]);
  const [formsList, setFormsList] = useState([]);
  const { deviceCode, category, product_id } = useGlobalContext();
  const { token } = useSelector((state) => state.auth);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const comparisonOptions = [
    { label: "Compare by Brand", value: "brand" },
    { label: "Compare by Group", value: "group" },
    { label: "Compare by Product Form", value: "form" },
  ];

  const [comparisonOption, setComparisonOption] = useState([]);

  const [columns, setColumns] = useState(
    Array(5)
      .fill()
      .map((_, index) => ({
        value: "", // Set value to empty string initially
        selectedComparisons: index === 0 ? ["Category"] : [], // Handle the first column for "Category"
        filterVal: '',
        colComparisonOptions: []
      })),
  );

  const handleColumnChange = (index, newValue) => {
    setColumns((prevColumns) =>
      prevColumns.map((col, i) =>
        i === index ? { ...col, value: newValue } : col,
      ),
    );
  };

  const handleCheckboxChange = (index, optionValue) => {
    setColumns((prevColumns) =>
      prevColumns.map((col, i) => {
        if (i === index) {
          const updatedComparisons =
            index === 0 && optionValue === "Category"
              ? col.selectedComparisons
              : col.selectedComparisons.includes(optionValue)
                ? col.selectedComparisons.filter((val) => val !== optionValue)
                : [...col.selectedComparisons, optionValue];

          return { ...col, selectedComparisons: updatedComparisons };
        }
        return col;
      }),
    );
  };

  const handleChooseComparison = (optionValue) => {
    setChooseComparison(optionValue);
    setSelectedComparisonBy(optionValue);
  };

  const handleApply = () => {
    const resultData = columns.reduce((result, col, index) => {
      const key = `key_${index + 1}`;
      result[key] = {
        name:
          index === 0
            ? "CATEGORY"
            : col.value.trim()
              ? col.value
              : `Column ${index + 1}`,
        value:
          index === 0 ? "Category" : col.selectedComparisons.join("|") || "",
      };
      return result;
    }, {});
    setCompareBy(resultData);
    setApply(!apply);
    setIsCompareByClicked(true);
    setTimeout(() => {
      setIsCollapsed(true);
    }, 500);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const getDefaultColumns = () =>
    Array.from({ length: 5 }, (_, i) => ({
      value: ``,
      selectedComparisons: i === 0 ? ["Category"] : [],
    }));

  const handleReset = () => {
    setChooseComparison(null);

    // Reset columns to default state
    const defaultColumns = getDefaultColumns();
    setColumns(defaultColumns);

    // Reset resultData to match default columns
    const resetData = defaultColumns.reduce((result, col, index) => {
      const key = `key_${index + 1}`;
      result[key] = {
        name: index === 0 ? "CATEGORY" : col.value,
        value:
          index === 0 ? "Category" : col.selectedComparisons.join("|") || "",
      };
      return result;
    }, {});

    setCompareBy(resetData);
    setApply(false);
    setIsCompareByClicked(false);
    setIsCollapsed(false);
  };

  const getComparisonOptions = (chooseComparison, forms, brands) => {
    if (chooseComparison === "brand") {
      return brands.map((brand) => ({ value: brand, label: brand }));
    } else if (chooseComparison === "form") {
      return forms.map((form) => ({ value: form, label: form }));
    }
    return [];
  };

  const fetchBrandsForms = () => {
    setLoading(true);
    setColumns((prevColumns) =>
      prevColumns.map((col, index) => ({
        ...col,
        selectedComparisons: index === 0 ? ["Category"] : [],
      })),
    );

    const requestBody = {
      product_id: parseInt(product_id),
      project_id: parseInt(PROJECT_ID),
    };
    getProductType(requestBody, token)
      .then(async (response) => {
        if (response.status === 200 && chooseComparison !== null) {
          const request_body = {
            product_id: parseInt(product_id),
            product_type: deviceCode === 1 ? "product" : response.data.type,
            project_id: parseInt(PROJECT_ID),
          };
          const [brandsFormsAndSizeResponse] = await Promise.all([
            getBrandsFormsAndSize(request_body, token),
          ]);
          setFormsList(brandsFormsAndSizeResponse.data.barcode_details.forms);
          setBrandsList(brandsFormsAndSizeResponse.data.barcode_details.brands);
          const comparisonOptionData = getComparisonOptions(
            chooseComparison,
            brandsFormsAndSizeResponse.data.barcode_details.forms,
            brandsFormsAndSizeResponse.data.barcode_details.brands,
          );
          setComparisonOption(comparisonOptionData);
          setColumns((prevColumns) =>
            prevColumns.map((col, index) => ({
              ...col,
              colComparisonOptions: comparisonOptionData
            })),
          );
        } else {
          setError("Failed to fetch product types. Unexpected status code.");
        }
      })
      .catch((error) => {
        handleError(error, navigate, dispatch);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchFilterCompareOptionsForCoaster = async () => {
    try {
      setLoading(true);
      setColumns((prevColumns) =>
        prevColumns.map((col, index) => ({
          ...col,
          selectedComparisons: index === 0 ? ["Category"] : [],
        })),
      );

      const [brandsAndFormsResponse] = await Promise.all([
        getFilterOptionsForCoaster(
          PROJECT_ID,
          category,
          chooseComparison,
          token,
        ),
      ]);

      const responseData = brandsAndFormsResponse?.data?.data || [];

      const comparisonOptionData = getComparisonOptions(
        chooseComparison,
        chooseComparison === "form" ? responseData : "",
        chooseComparison === "brand" ? responseData : "",
      );
      setComparisonOption(comparisonOptionData);
      setColumns((prevColumns) =>
        prevColumns.map((col, index) => ({
          ...col,
          colComparisonOptions: comparisonOptionData
        })),
      );
    } catch (error) {
      console.error("Error fetching filter compare options:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (chooseComparison) {
      if (deviceCode === 1) {
        fetchFilterCompareOptionsForCoaster();
      } else {
        fetchBrandsForms();
      }
    }
  }, [chooseComparison, product_id]);

  useEffect(() => {
    handleReset();
  }, [product_id]);

  const processSelectComparisonOption = (col) => {
    if(col?.selectedComparisons && col?.selectedComparisons?.length) {
      const getSelectedItems = comparisonOption.filter(data => col?.selectedComparisons.includes(data.value));
      const getUnSelectedItems = comparisonOption.filter(data => !col?.selectedComparisons.includes(data.value));
      const combinedArr = [...getSelectedItems, ...getUnSelectedItems];
      return combinedArr;
    } else {
      return comparisonOption;
    }
  }

  const handleDebounceFn = (inputValue, index) => {
    setColumns((prevColumns) =>
      prevColumns.map((col, i) =>
        i === index ? { ...col, 
          filterVal: inputValue,
          colComparisonOptions: inputValue !== '' ? comparisonOption?.filter(item => item.value.toLowerCase().includes(inputValue.toLowerCase())) :  processSelectComparisonOption(col)
        } : col,
      ),
    );
  }

  const debounceFn = useCallback(_debounce(handleDebounceFn, 500), [comparisonOption]);

  return (
    <>
      <Panel
        id="compareBySection"
        className="mt-2 text-start"
        header={<p className="d-contents fs-5 mb-0 p-3">COMPARE BY</p>}
        toggleable
        collapsed={isCollapsed}
        onToggle={(e) => setIsCollapsed(e.value)}
        expandIcon={<span className="pi pi-angle-up"></span>}
        collapseIcon={<span className="pi pi-angle-down"></span>}
        // onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <div
          className="d-flex flex-column p-3"
          style={{ borderTop: "1px solid #dee2e6" }}
        >
          <div className="mb-3">
            <h6 className="fw-semibold">STEP 1:</h6>
            <p
              className="text-uppercase mb-0 text-start fw-bold fs-12 pb-3"
              style={{ color: "#9E9E9E" }}
            >
              Choose your comparison (Select any one)
            </p>
            {comparisonOptions.map((option) => (
              <div key={option.value} className="mb-2">
                <Checkbox
                  inputId={option.value}
                  checked={chooseComparison === option.value}
                  onChange={() => handleChooseComparison(option.value)}
                />
                <label htmlFor={option.value} className="ms-2 text-uppercase">
                  {option.label}
                </label>
              </div>
            ))}
          </div>

          <div>
            <h6 className="fw-semibold">STEP 2:</h6>
            <div className="d-flex flex-row justify-content-between">
              {columns.map((column, index) => (
                <div
                  key={index}
                  className="d-flex flex-column align-items-start"
                >
                  <div>
                    <InputText
                      name={`key_${index + 1}`}
                      value={column.value || ""} // Ensure value is empty initially
                      onChange={
                        (e) =>
                          index !== 0 &&
                          handleColumnChange(index, e.target.value) // Only allow changes for non-category columns
                      }
                      className="mb-2"
                      placeholder={
                        index === 0 ? "CATEGORY" : `Column ${index + 1}`
                      } // Placeholder for each column
                      style={{ backgroundColor: "#f5f5f5" }}
                      disabled={index === 0} // Disable the first column (Category)
                    />
                  </div>
                  {index === 0 && comparisonOption.length > 0 ? (
                    chooseComparison !== null && (
                      <div className="d-flex align-items-center pt-2 pb-2">
                        <Checkbox
                          inputId={`category-${index}`}
                          checked={true}
                        />
                        <label
                          htmlFor={`category-${index}`}
                          className="ms-2"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          title="Category"
                        >
                          Category
                        </label>
                      </div>
                    )
                  ) : loading ? (
                    <CompareByColumnsSkeleton />
                  ) : (
                    <>
                      { chooseComparison !== null && comparisonOption.length ?
                        <div className="search-filter">
                          <InputText
                            name={`search_key_${index + 1}`}
                            value={column.filterVal || ''}
                            onChange={
                              (e) => {
                                setColumns((prevColumns) =>
                                  prevColumns.map((col, i) =>
                                    i === index ? { ...col, 
                                      filterVal: e.target.value
                                    } : col,
                                  ),
                                );
                                debounceFn(e.target.value, index);
                              }
                            }
                            className="mb-2"
                            placeholder={'Search'} // Placeholder for each search
                            style={{ 
                              backgroundColor: "#f5f5f5"
                            }}
                          />
                          { !!column?.filterVal === false ?
                            <span className="search-icon pi pi-search text-secondary"></span> :
                            <Button className="search-icon" icon="pi pi-times" rounded text severity="secondary" aria-label="Cancel" 
                            onClick={
                              () => setColumns((prevColumns) =>
                                prevColumns.map((col, i) =>
                                  i === index ? { ...col, 
                                    filterVal: '', 
                                    colComparisonOptions: processSelectComparisonOption(col)
                                  } : col,
                                ),
                              )
                            } />
                          }
                        </div> : ''
                      }
                      { chooseComparison !== null && column?.colComparisonOptions?.length ?
                        <ScrollPanel style={{height: '200px'}} className="scroll-panel-custom">
                          { chooseComparison !== null && column?.colComparisonOptions?.map((option) => (
                            <div
                              key={option.value}
                              className="d-flex align-items-center pt-2 pb-2"
                            >
                              <Checkbox
                                inputId={`${option.value}-${index}`}
                                checked={column.selectedComparisons.includes(
                                  option.value,
                                )}
                                onChange={() =>
                                  handleCheckboxChange(index, option.value)
                                }
                              />
                              <label
                                htmlFor={`${option.value}-${index}`}
                                className="ms-2"
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                                title={option.label}
                              >
                                {option.label.length > 19
                                  ? option.label.slice(0, 16) + "..."
                                  : option.label}
                              </label>
                            </div>
                          ))}
                        </ScrollPanel> : ''
                      }
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="flex align-items-center gap-2 managed-row">
            <Button
              label="RESET"
              className="p-button-text me-2 resetButton br-c fw-600"
              style={{ width: "100px", color: "gray" }}
              onClick={handleReset}
              disabled={chooseComparison === null ? true : false}
            ></Button>
            <Button
              label="APPLY"
              className="p-button-text me-2  active-button br-c fw-600"
              style={{ width: "100px" }}
              onClick={handleApply}
              disabled={chooseComparison === null ? true : false}
            ></Button>
          </div>
        </div>
      </Panel>
    </>
  );
};

export default CompareBy;
