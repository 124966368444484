import React, { useState, useEffect } from "react";
import FilterByForDashboard from "../FilterByForDashboard";
import NeedsChart from "./needsComponents/NeedsCharts";
import NeedsTable from "./needsComponents/NeedsTable";
import IndexIndecator from "../../IndexIndicator/IndexIndecator";
import { useGlobalContext } from "../../../context/GlobalContext";
import { useSelector } from "react-redux";
import { PROJECT_ID } from "../../../utils/constants";
import { getNeedsChartData } from "../../../services/dashboard/needs";
const Needs = (props) => {
  const { device, deviceCode, category, product_id } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [productType, setProductType] = useState("");
  const [activeOption, setActiveOption] = useState("FILTERBY");
  const [question_id, setQuestionId] = useState("");
  const { token } = useSelector((state) => state.auth);
  const [needsChartData, setNeedsChartData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedOccasions, setSelectedOccasions] = useState([]);
  const [selectedHouseHoldAge, setSelectedHouseHoldAge] = useState([]);
  const [selectedHouseHoldIncome, setSelectedHouseHoldIncome] = useState([]);
  const [selectedHouseHoldSize, setSelectedHouseHoldSize] = useState([]);
  const [selectedHouseHoldChildren, setSelectedHouseHoldChildren] = useState(
    [],
  );
  const [selectedHouseHoldRaceEthnicity, setSelectedHouseHoldRaceEthnicity] =
    useState([]);
  const [selectedForms, setSelectedForms] = useState([]);
  const [selectedDayPart, setSelectedDayPart] = useState([]);
  const [selectedWeekPart, setSelectedWeekPart] = useState([]);
  const [selectedTimeOfDay, setSelectedTimeOfDay] = useState([]);
  const [apply, setApply] = useState(false);

  const getSelectedCategoriesString = () => {
    const categories = [];

    if (selectedHouseHoldAge.length > 0) {
      categories.push("age");
    }
    if (selectedHouseHoldIncome.length > 0) {
      categories.push("income");
    }
    if (selectedHouseHoldSize.length > 0) {
      categories.push("size");
    }
    if (selectedHouseHoldChildren.length > 0) {
      categories.push("children");
    }
    if (selectedHouseHoldRaceEthnicity.length > 0) {
      categories.push("raceEthnicity");
    }

    return categories.join("|");
  };
  const fetchChartsData = () => {
    setLoading(true);
    const requestBody = {
      compare_with: props?.selectedComparitionBy,
      compare_by: props?.compareBy,
      filter_brand: activeOption === "FILTERBY" ? selectedBrands.join("|") : "",
      filter_form: activeOption === "FILTERBY" ? selectedForms.join("|") : "",
      filter_household:
        activeOption === "FILTERBY"
          ? [
              ...selectedHouseHoldAge,
              ...selectedHouseHoldIncome,
              ...selectedHouseHoldSize,
              ...selectedHouseHoldChildren,
              ...selectedHouseHoldRaceEthnicity,
            ]
              .map((item) => item.uniques)
              .join("|")
          : "",
      filter_household_type:
        activeOption === "FILTERBY" ? getSelectedCategoriesString() : "",
      filter_occasion:
        activeOption === "FILTERBY" ? selectedOccasions.join("|") : "",
      product_id: parseInt(product_id),
      product_type: productType,
      project_id: parseInt(PROJECT_ID),
      occasion_question_id: parseInt(question_id),
      section_type: props?.isCompareByClicked ? "compare" : "filter",
    };
    Promise.all([getNeedsChartData(requestBody, token)])
      .then(([needsChartDataResponse]) => {
        setNeedsChartData(needsChartDataResponse.data.graphs);
      })
      .catch((error) => {
        console.error("Error fetching chart data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (productType !== "" && question_id !== "") {
      fetchChartsData();
    }
  }, [productType, question_id, apply, props?.apply]);

  const handleReset = () => {
    setSelectedDayPart([]);
    setSelectedWeekPart([]);
    setSelectedBrands([]);
    setSelectedOccasions([]);
    setSelectedFilters([]);
    setSelectedForms([]);
    setApply(!apply);
  }

  return (
    <div className="row">
      <FilterByForDashboard
        loading={loading}
        setLoading={setLoading}
        device={device}
        deviceCode={deviceCode}
        category={category}
        product_id={product_id}
        productType={productType}
        setProductType={setProductType}
        activeOption={activeOption}
        setActiveOption={setActiveOption}
        question_id={question_id}
        setQuestionId={setQuestionId}
        selectedForms={selectedForms}
        setSelectedForms={setSelectedForms}
        selectedBrands={selectedBrands}
        setSelectedBrands={setSelectedBrands}
        selectedGenders={selectedGenders}
        setSelectedGenders={setSelectedGenders}
        selectedOccasions={selectedOccasions}
        setSelectedOccasions={setSelectedOccasions}
        selectedHouseHoldAge={selectedHouseHoldAge}
        setSelectedHouseHoldAge={setSelectedHouseHoldAge}
        selectedHouseHoldIncome={selectedHouseHoldIncome}
        setSelectedHouseHoldIncome={setSelectedHouseHoldIncome}
        selectedHouseHoldSize={selectedHouseHoldSize}
        setSelectedHouseHoldSize={setSelectedHouseHoldSize}
        selectedHouseHoldChildren={selectedHouseHoldChildren}
        setSelectedHouseHoldChildren={setSelectedHouseHoldChildren}
        selectedHouseHoldRaceEthnicity={selectedHouseHoldRaceEthnicity}
        setSelectedHouseHoldRaceEthnicity={setSelectedHouseHoldRaceEthnicity}
        selectedDayPart={selectedDayPart}
        setSelectedDayPart={setSelectedDayPart}
        selectedWeekPart={selectedWeekPart}
        setSelectedWeekPart={setSelectedWeekPart}
        selectedTimeOfDay={selectedTimeOfDay}
        setSelectedTimeOfDay={setSelectedTimeOfDay}
        apply={apply}
        setApply={setApply}
        isCompareByClicked={props?.isCompareByClicked}
        setIsCompareByClicked={props?.setIsCompareByClicked}
        setSelectedFilters={setSelectedFilters}
        selectedFilters={selectedFilters}
        handleReset={handleReset}
      />
      <div className="col-9" style={{ padding: "0px 5%" }}>
        <NeedsChart needsChartData={needsChartData} loading={loading} />
        <NeedsTable tableData={needsChartData} />
        <IndexIndecator />
      </div>
    </div>
  );
};
export default Needs;
