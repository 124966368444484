import React from "react";
import { Chart } from "primereact/chart";
import ChartDivider from "../../ChartDivider";
import ChartSkeleton from "../../../Skeletons/ChartSkeleton";
import { getNeedsChartOptions } from "../../../../utils/chartOptions/dashboard/needs";

const NeedsChart = React.memo(function NeedsChart(props) {
  const options = getNeedsChartOptions(props?.needsChartData);

  return (
    <div className="mt-4" style={{ maxWidth: "100%" }}>
      <div className="mb-4">
        <h5 className="text-uppercase fw-bold mb-1 text-start">Needs</h5>
        <p
          className="text-uppercase mb-0 text-start fw-bold fs-12 py-3"
          style={{ color: "#6c757d" }}
        >
          % of uses
        </p>
        <div className="col-12">
          {!props.loading ? (
            <Chart type="bar" data={props?.needsChartData} options={options} />
          ) : (
            <ChartSkeleton />
          )}
        </div>
        <ChartDivider />
      </div>
    </div>
  );
});

export default NeedsChart;
