import React from "react";
import FilterByForDashboard from "../FilterByForDashboard";
import ChartDivider from "../ChartDivider";
import Users from "./hmlComponents/Users";
import WeekPart from "../occasions/occasionsComponents/WeekPart";
import OccasionDescription from "./hmlComponents/OccasionDescription";
import HowOftenAverageWeeklyFrequency from "./hmlComponents/HowOftenAverageWeeklyFrequency";
import DayOfWeek from "./hmlComponents/DayOfWeek";

const Hml = () => {
  return (
    <div className="row">
      {/* Filter By Component */}
      <FilterByForDashboard />
      <div className="col-9" style={{ padding: "0px 5%" }}>
        <Users />
        <ChartDivider />
        <HowOftenAverageWeeklyFrequency />
        <ChartDivider />
        <OccasionDescription />
        <ChartDivider />
        <DayOfWeek />
        <hr style={{ margin: "2rem 0" }} />
      </div>
    </div>
  );
};
export default Hml;
