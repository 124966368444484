import { Divider } from "primereact/divider";
import React from "react";
import Icon from "@mdi/react";
import {
  mdiAccount,
  mdiAccountMultiple,
  mdiHumanMaleFemale,
  mdiCurrencyUsd,
  mdiHumanChild,
} from "@mdi/js";

const RefineYourTarget = () => {
  const data = [
    {
      title: "HEAD OF HOUSEHOLD AGE",
      icon: mdiAccount,
      items: [
        { label: "18-34", value: "20%", color: "red" },
        { label: "35-44", value: "32%", color: "green" },
        { label: "45-54", value: "23%", color: "#666666" },
        { label: "55+", value: "24%", color: "red" },
      ],
    },
    {
      title: "HEAD OF HOUSEHOLD RACE/ETHNICITY",
      icon: mdiAccountMultiple,
      items: [
        { label: "WHITE, NON-HISPANIC", value: "60%", color: "#666666" },
        { label: "BLACK OR AFRICAN AMERICAN", value: "11%", color: "#666666" },
        { label: "ASIAN, OTHER", value: "7%", color: "#666666" },
        { label: "HISPANIC (OF ANY RACE)", value: "18%", color: "green" },
        { label: "MULTI-ETHNIC", value: "4%", color: "#666666" },
      ],
    },
    {
      title: "HOUSEHOLD SIZE",
      icon: mdiHumanMaleFemale,
      items: [
        { label: "1-MEMBER", value: "30%", color: "green" },
        { label: "2-MEMBER", value: "37%", color: "green" },
        { label: "3-MEMBER", value: "15%", color: "#666666" },
        { label: "4+MEMBER", value: "18%", color: "red" },
      ],
    },
    {
      title: "HOUSEHOLD INCOME",
      icon: mdiCurrencyUsd,
      items: [
        { label: "$25,000-$49,999", value: "12%", color: "#666666" },
        { label: "$50,000-$74,999", value: "10%", color: "red" },
        { label: "$75,000-$99,999", value: "16%", color: "#666666" },
        { label: "$100,000-$149,999", value: "35%", color: "green" },
        { label: "$150,000+", value: "27%", color: "#666666" },
      ],
    },
    {
      title: "PRESENCE OF CHILDREN",
      icon: mdiHumanChild,
      items: [
        { label: "NO CHILDREN <18", value: "64%", color: "green" },
        { label: "CHILDREN PRESENT <18", value: "36%", color: "red" },
      ],
    },
  ];

  return (
    <>
      <div className="mt-4" style={{ maxWidth: "100%" }}>
        <div className="mb-4">
          <h5 className="text-uppercase fw-bold mb-1 text-start text_dark">
            Refine Your Target
          </h5>
          <p className="text-uppercase mb-0 text-start fw-bold fs-12 text-light-gray">
            Gain insight into the types of people & households using your
            products to reach the right people more effectively
          </p>
          <p className="text-uppercase mb-0 text-start fw-bold fs-12 py-3 text-light-gray">
            % uses
          </p>
        </div>
      </div>
      <div
        className=""
        style={{
          maxWidth: "800px",
          backgroundColor: "#fff",
        }}
      >
        {data.map((section, index) => (
          <div key={index} className="mb-4">
            {/* Section Title */}
            <h6
              className="text-uppercase fw-bold mb-3 text-start text_dark"
              style={{ color: "#333", fontSize: "16px" }}
            >
              {section.title}
            </h6>

            {/* Labels and Values */}
            <div className="d-flex align-items-center mb-3">
              <Icon path={section.icon} size={2} color="#BDBDBD" />
              <Divider layout="vertical" />
              {section.items.map((item, idx) => {
                const words = item.label.split(" ");
                const firstWord = words[0];
                const remainingWords = words.slice(1).join(" ");
                return (
                  <React.Fragment key={idx}>
                    <div className="text-start">
                      <span
                        className="d-block fw-normal mb-1"
                        style={{ fontSize: "12px", color: "rgb(73, 80, 87)" }}
                      >
                        <span className="fw-600 text-dark-gray">
                          {firstWord}
                        </span>
                        <br />
                        <span className="fw-600">{remainingWords}</span>
                      </span>
                      <span
                        className="fw-bold"
                        style={{ fontSize: "20px", color: item.color }}
                      >
                        {item.value}
                      </span>
                    </div>
                    {idx < section.items.length - 1 && (
                      <Divider layout="vertical" />
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default RefineYourTarget;
