import React, { useState } from "react";
import { Chart } from "primereact/chart";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import ChartSkeleton from "../../../Skeletons/ChartSkeleton";
import { getWeekPartGraphOptions } from "../../../../utils/chartOptions/dashboard/frequency";

const WeekPart = React.memo(function WeekPart(props) {
  const options = getWeekPartGraphOptions(
    props?.weekPartGraph,
    props?.activeButtonWeekPart,
  );
  const handleClick = (label) => props?.setActiveButtonWeekPart(label);

  return (
    <>
      <div className="mt-4" style={{ maxWidth: "100%" }}>
        <div className="mb-4">
          <h5 className="text-uppercase fw-bold mb-1 text-start">
            <span>WEEK PART</span>
            <div
              className="d-flex align-items-center gap-2"
              style={{ float: "right" }}
            >
              <Button
                label="% OF TOTAL"
                className={`px-3 py-2 fw-bold fs-6 ${
                  props?.activeButtonWeekPart === "% OF TOTAL"
                    ? "btn-primary text-white"
                    : "btn-light text-dark"
                }`}
                onClick={() => handleClick("% OF TOTAL")}
                style={{
                  borderRadius: "10px",
                  border: "none",
                  height: "25px",
                  backgroundColor:
                    props?.activeButtonWeekPart === "% OF TOTAL"
                      ? "#002D42"
                      : "#E0E0E0",
                }}
              />
              <Button
                label="# OF USES"
                className={`px-3 py-2 fw-bold fs-6 ${
                  props?.activeButtonWeekPart === "# OF USES"
                    ? "btn-primary text-white"
                    : "btn-light text-dark"
                }`}
                onClick={() => handleClick("# OF USES")}
                style={{
                  borderRadius: "10px",
                  border: "none",
                  height: "25px",
                  backgroundColor:
                    props?.activeButtonWeekPart === "# OF USES"
                      ? "#002D42"
                      : "#E0E0E0",
                }}
              />
            </div>
          </h5>
          <p
            className="text-uppercase mb-0 text-start fw-bold fs-12 py-3"
            style={{ color: "#6c757d" }}
          >
            {props?.activeButtonWeekPart === "# OF USES" ? 'Average daily uses by day of week' : props?.activeButtonWeekPart}
          </p>
          <div className="col-12 pb-5">
            {!props?.weekPartLoader ? (
              <Chart type="bar" data={props?.weekPartGraph} options={options} />
            ) : (
              <ChartSkeleton />
            )}
          </div>
          <Divider />
        </div>
      </div>
    </>
  );
});

export default WeekPart;
