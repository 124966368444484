import React, { useState } from "react";
import { Card } from "primereact/card";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import "../../assets/css/dashboard.css"; // Ensure your CSS file is correctly imported
import FilterBy from "../../components/reports/FilterBy";
import TotalAverage from "./reportsComponents/TotalAverage";
import DailyAverage from "./reportsComponents/DailyAverage";
import WeeklyAverage from "./reportsComponents/WeeklyAverage";
import TimeOfDayAverage from "./reportsComponents/TimeOfDayAverage";
import { useGlobalContext } from "../../context/GlobalContext";
const Reports = (props) => {
  const { device, deviceCode, category, product_id } = useGlobalContext();
  const [activeButton, setActiveButton] = useState("HOW OFTEN");
  const handleButtonClick = (buttonLabel) => {
    setActiveButton(buttonLabel);
  };

  return (
    <div className="container-fluid page-wrapper">
      <div className="row">
        <div className="col-12 col-md-12">
          <Card>
            <TabView>
              <TabPanel header="SENSOR CHARTS" className="tabStyle">
                <div className="d-flex align-items-center justify-content-center managed-row flex-nowrap">
                  <Button
                    className={`p-button-text me-2 me-md-3 ${activeButton === "HOW OFTEN" ? "active-button" : "inactive-button"} br-c`}
                    outlined
                    onClick={() => handleButtonClick("HOW OFTEN")}
                    label="HOW OFTEN"
                  />
                  {props.deviceCode === 1 ? (
                    <Button
                      className={`p-button-text me-2 me-md-3 ${activeButton === "HOW MUCH" ? "active-button" : "inactive-button"} br-c`}
                      outlined
                      onClick={() => handleButtonClick("HOW MUCH")}
                      label="HOW MUCH"
                    />
                  ) : (
                    <Button
                      className={`p-button-text me-2 me-md-3 ${activeButton === "HOW LONG" ? "active-button" : "inactive-button"} br-c`}
                      outlined
                      onClick={() => handleButtonClick("HOW LONG")}
                      label="HOW LONG"
                    />
                  )}
                  {device},{deviceCode},{category},{product_id}
                </div>
                <div className="row" style={{ borderTop: "1px solid #dee2e6" }}>
                  {/* Filter By Component */}
                  <FilterBy
                    device={device}
                    deviceCode={deviceCode}
                    category={category}
                    product_id={product_id}
                  />
                  <div className="col-9" style={{ padding: "0px 5%" }}>
                    <TotalAverage />
                    <hr style={{ margin: "2rem 0" }} />
                    <DailyAverage />
                    <hr style={{ margin: "2rem 0" }} />
                    <WeeklyAverage />
                    <hr style={{ margin: "2rem 0" }} />
                    <TimeOfDayAverage />
                  </div>
                </div>
              </TabPanel>
            </TabView>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Reports;
