export const getOuncePerUSeChartOptions = (usePerOunceGraph) => {
  const datasets = usePerOunceGraph?.datasets || [];
  const maxValue =
    Math.max(...datasets.flatMap((dataset) => dataset.data), 0) + 2;
  return {
    barThickness: datasets.length === 1 ? 300 : undefined,
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "start",
        labels: {
          padding: 20,
          font: { size: 12, weight: "bold" },
          color: "#6C757D",
        },
      },
      datalabels: {
        color: "#000",
        anchor: "end",
        align: "top",
        font: { weight: "bold", size: 12 },
        formatter: (value) => (value !== 0 ? value : ""),
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          font: { size: 12, weight: "bold" },
        },
      },
      y: {
        beginAtZero: true,
        suggestedMax: maxValue,
        title: {
          display: true,
          text: "GRAMS",
          font: { size: 12, weight: "bold" },
        },
        ticks: {
          callback: (value) => {
            return value === 0
              ? "0"
              : Number.isInteger(value)
                ? `${value}.0`
                : `${value}`;
          },
          font: { size: 12, weight: "bold" },
        },
      },
    },
  };
};

export const timeOfDayChartOptions = () => {
  return {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "start",
        labels: {
          padding: 15,
          font: { size: 12, weight: "bold" },
          color: "#6C757D",
        },
      },
      datalabels: false,
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "GRAMS",
          font: { size: 12, weight: "bold" },
        },
        ticks: {
          stepSize: 0.2,
          callback: (value) => `${value}`,
          font: { size: 12, weight: "bold" },
        },
      },
      x: {
        ticks: {
          font: { size: 12, weight: "bold" },
        },
      },
    },
  };
};

export const getDayOfWeekChartOptions = (dayOfWeekGraph, yLabel) => {
  const datasets = dayOfWeekGraph?.datasets || [];
  const maxValue =
    Math.max(...datasets.flatMap((dataset) => dataset.data), 0) + 1;

  return {
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "start",
        labels: {
          padding: 15,
          font: { size: 12, weight: "bold" },
          color: "#6C757D",
        },
      },
      datalabels: {
        color: "#000",
        anchor: "end",
        align: "top",
        font: { weight: "bold", size: 12 },
        formatter: (value) => (value !== 0 ? value : ""),
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: maxValue,
        title: {
          display: true,
          text: yLabel,
          font: { size: 12, weight: "bold" },
        },
        ticks: {
          stepSize: 25,
          callback: (value) =>
            yLabel === "% OF TOTAL" ? `${value}%` : `${value}`,
          font: { size: 12, weight: "bold" },
        },
      },
      x: {
        ticks: {
          font: { size: 12, weight: "bold" },
        },
      },
    },
  };
};

export const getWeekPartGraphOptions = (weekPartGraph, yLabel) => {
  const datasets = weekPartGraph?.datasets || [];
  const maxValue =
    Math.max(...datasets.flatMap((dataset) => dataset.data), 0) + 1;

  return {
    barThickness: datasets.length === 1 ? 200 : undefined,
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "start",
        labels: {
          padding: 15,
          font: { size: 12, weight: "bold" },
          color: "#6C757D",
        },
      },
      datalabels: {
        color: "#000",
        anchor: "end",
        align: "top",
        font: { weight: "bold", size: 12 },
        formatter: (value) => (value !== 0 ? value : ""),
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: maxValue,
        title: {
          display: true,
          text: yLabel,
          font: { size: 12, weight: "bold" },
        },
        ticks: {
          callback: (value) =>
            yLabel === "% OF TOTAL" ? `${value}%` : `${value}`,
          font: { size: 12, weight: "bold" },
        },
      },
      x: {
        ticks: {
          font: { size: 12, weight: "bold" },
        },
      },
    },
  };
};
