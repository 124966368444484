import React from "react";
import { Chart } from "primereact/chart";

const TimeOfDay = () => {
  const data = {
    labels: [
      "12AM",
      "2AM",
      "4AM",
      "6AM",
      "8AM",
      "10AM",
      "12PM",
      "2PM",
      "4PM",
      "6PM",
      "8PM",
      "10PM",
    ],
    datasets: [
      {
        label: "Category",
        data: [30, 25, 35, 50, 55, 60, 65, 60, 55, 40, 30, 20],
        borderColor: "orange",
        backgroundColor: "orange",
      },
      {
        label: "Tide",
        data: [20, 40, 45, 40, 35, 30, 25, 40, 50, 60, 45, 30],
        borderColor: "blue",
        backgroundColor: "blue",
      },
      {
        label: "Gain",
        data: [60, 55, 50, 45, 40, 35, 30, 25, 20, 15, 10, 5],
        borderColor: "#EE534F",
        backgroundColor: "#EE534F",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      datalabels: false,
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "CATEGORY SIZE = 102",
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "% USES",
        },
        max: 70,
        ticks: {
          // Adding a callback to format ticks with a percentage sign
          callback: function (value) {
            return value + "%"; // Append a percentage sign to each tick label
          },
        },
      },
    },
  };

  return (
    <>
      <div className="fs-14 text-start mt-4 mb-4">
        <strong>TIME OF DAY - % USES</strong>
      </div>
      <div className="col-12">
        <Chart type="line" data={data} options={options} />
      </div>
    </>
  );
};

export default TimeOfDay;
