import React from "react";
import { Chart } from "primereact/chart";

const HouseholdMembersLivingWith = () => {
  const data = {
    labels: [
      "CHILDREN 0-10",
      "CHILDREN 11-17",
      "CHILDREN 18-24",
      "CHILDREN 25+",
      "OTHER FAMILY MEMBERS",
      "FRIENDS/HOUSEMATES",
    ],
    datasets: [
      {
        backgroundColor: "#AB46BC",
        data: [14, 0, 0, 0, 0, 0],
      },
      {
        backgroundColor: "#AB46BC",
        data: [0, 16, 0, 0, 0, 0],
      },
      {
        backgroundColor: "#AB46BC",
        data: [0, 0, 35, 0, 0, 0],
      },
      {
        backgroundColor: "#AB46BC",
        data: [0, 0, 0, 26, 0, 0],
      },
      {
        backgroundColor: "#AB46BC",
        data: [0, 0, 0, 0, 7, 0],
      },
      {
        backgroundColor: "#AB46BC",
        data: [0, 0, 0, 0, 0, 5],
      },
    ],
  };

  // Calculate the maximum value from all datasets
  const allValues = data.datasets.flatMap((dataset) => dataset.data);
  const maxValue = Math.max(...allValues);
  const maxWithBuffer = Math.ceil(maxValue * 1.1); // Adding 10% buffer and rounding up

  const options = {
    indexAxis: "y",
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: "black",
        anchor: "end",
        align: "right",
        font: {
          weight: "bold",
          size: 12,
        },
        formatter: (value) => (value !== 0 ? value : ""),
      },
    },
    scales: {
      x: {
        stacked: true,
        beginAtZero: true,
        max: maxWithBuffer, // Dynamically set max value with buffer
        ticks: {
          stepSize: 10, // Set a step size of 10 between each tick
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      <div className="fs-14 text-start mt-4 mb-4">
        <strong>HOUSEHOLD MEMBERS LIVING WITH - % USERS</strong>
      </div>
      <div className="col-12">
        <Chart type="bar" data={data} options={options} />
      </div>
    </>
  );
};

export default HouseholdMembersLivingWith;
