import React from "react";
import { Divider } from "primereact/divider";

const NeedsTable = ({ tableData }) => {
  if (!tableData?.labels || !tableData?.datasets) return null;
  const categoryDataset = tableData.datasets.find(
    (dataset) => dataset.label.toUpperCase() === "CATEGORY",
  );
  const headers = [
    "",
    ...tableData.datasets.map((dataset) => dataset.label.toUpperCase()),
  ];
  const transformedData = tableData.labels.map((label, index) => {
    const row = { rowHeader: label };

    tableData.datasets.forEach((dataset) => {
      const originalValue = dataset.data[index];
      const categoryValue = categoryDataset?.data[index] || 1;
      const ratio = (originalValue / categoryValue) * 100;

      row[dataset.label] = {
        value: `${originalValue}%`,
        className:
          dataset.label.toUpperCase() === "CATEGORY"
            ? "text-secondary fw-bold"
            : ratio >= 110
              ? "text-success fw-bold"
              : ratio >= 91 && ratio <= 109
                ? "text-secondary fw-bold"
                : "text-danger fw-bold",
      };
    });

    return row;
  });

  return (
    <div className="mt-4" style={{ maxWidth: "100%" }}>
      <div className="mb-4">
        <h5 className="text-uppercase fw-bold mb-1 text-start">Needs</h5>
        <p
          className="text-uppercase mb-0 text-start fw-bold fs-12 py-3"
          style={{ color: "#6c757d" }}
        >
          % of uses, indexed to category
        </p>
        <div className="col-12 pb-4">
          <table className="table table-striped table-bordered text-center">
            <thead className="thead-light">
              <tr>
                {headers.map((header, index) => (
                  <th
                    key={index}
                    style={{
                      color: "#6c757d",
                      // position: index === 0 ? "sticky" : "static",
                      left: index === 0 ? 0 : "auto",
                      background: "#fff",
                      zIndex: index === 0 ? 2 : "auto",
                      width: index === 0 ? "250px" : "auto",
                      minWidth: index === 0 ? "250px" : "auto",
                    }}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {transformedData.map((row, index) => (
                <tr key={index}>
                  <td
                    className="fw-bold text-start"
                    style={{
                      color: "#4A5057",
                      // position: "sticky",
                      left: 0,
                      background: "#fff",
                      zIndex: 2,
                    }}
                  >
                    {row.rowHeader}
                  </td>
                  {tableData.datasets.map((dataset) => (
                    <td
                      key={dataset.label}
                      className={row[dataset.label].className}
                    >
                      {row[dataset.label].value}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Divider />
      </div>
    </div>
  );
};

export default NeedsTable;
