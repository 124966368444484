import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import { getProductType } from "../../services/filterOptions/filter";
import { getHouseHoldData } from "../../services/filterOptions/filter";
import { PROJECT_ID } from "../../utils/constants";
import { useSelector } from "react-redux";
import handleError from "../../utils/handleError";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  isAllSelected,
  isAllSelectedHold,
  handleSelectAll,
  handleSelectOption,
  getFilteredOptions,
  handleSelectOptionOccasions,
} from "../../utils/filterBy/filterBy";
import LabelSkeleton from "../Skeletons/LabelSkeleton";
import FilterSkeleton from "../Skeletons/FilterSkeleton";
import {
  getSegmentsList,
  getBrandsFormsAndSize,
  getOccasions,
  getFiltersOptionsBasedOnFilterName,
  getFilterOptionsForCoaster,
} from "../../services/filterOptions/filter";

const MAX_SELECTION = 5;
const dayPartListData = [
  "12am-2am",
  "2am-4am",
  "4am-6am",
  "6am-8am",
  "8am-10am",
  "10am-12am",
  "12pm-2pm",
  "2pm-4pm",
  "4pm-6pm",
  "6pm-8pm",
  "8pm-10pm",
  "10pm-12pm",
];
const coasterDayPartListData = [
  "12am-4am",
  "4am-8am",
  "8am-12am",
  "12pm-4pm",
  "4pm-8pm",
  "8pm-12pm"
];

const FilterByForDashboard = React.memo(function FilterByForDashboard(props) {
  const { isDayWeekPartShow = false } = props;
  const { token } = useSelector((state) => state.auth);
  const [houseHoldData, setHouseHoldData] = useState([]);
  const [houseHoldAge, setHouseHoldAge] = useState([]);
  const [houseHoldIncome, setHouseHoldIncome] = useState([]);
  const [houseHoldSize, setHouseHoldSize] = useState([]);
  const [houseHoldChildren, setHouseHoldChildren] = useState([]);
  const [houseHoldRaceEthnicity, setHouseHoldRaceEthnicity] = useState([]);
  const [activeFilterCategory, setActiveFilterCategory] = useState(null);
  const [segmentsList, setSegmentsList] = useState([]);
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [brands, setBrands] = useState([]);
  const [formsList, setFormsList] = useState([]);
  const [occasionsList, setOccasionsList] = useState({});
  const [maxHeight, setMaxHeight] = useState("90vh");
  const [filterPulse, setFilterPulse] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const genderlist = ["MALE", "FEMALE", "NON-BINARY"];
  
  const [dayPartList, setDayPartList] = useState([]);
  const weekPartList = ["WEEKDAY", "WEEKEND"];
  // const timeOfDayList = [
  //   "12 AM",
  //   "2 AM",
  //   "4 AM",
  //   "6 AM",
  //   "8 AM",
  //   "10 AM",
  //   "12 PM",
  //   "2 PM",
  //   "4 PM",
  //   "6 PM",
  //   "8 PM",
  //   "10 PM",
  // ];

  const onFilterChange = (category, item) => {
    props?.setSelectedFilters((prevFilters) => {
      let updatedCategory = prevFilters[category] || [];
      const correctCategoryKey = Object.keys(houseHoldData).find((key) =>
        key.includes(category),
      );
      if (!correctCategoryKey) return prevFilters;
      let categoryData = houseHoldData[correctCategoryKey]?.uniques || [];
      if (item.phu_id === 0) {
        const allSelected = updatedCategory.length === categoryData.length + 1;
        return {
          ...prevFilters,
          [category]: allSelected
            ? []
            : [{ phu_id: 0, uniques: "All" }, ...categoryData],
        };
      } else {
        const exists = updatedCategory.some(
          (selected) => selected.phu_id === item.phu_id,
        );
        updatedCategory = exists
          ? updatedCategory.filter(
              (selected) => selected.phu_id !== item.phu_id,
            )
          : [...updatedCategory, item];
        categoryData = categoryData.filter(
          (data) => data.phu_id !== item.phu_id,
        );
        return {
          ...prevFilters,
          [category]: updatedCategory,
        };
      }
    });
  };

  const fetchProductType = () => {
    setFilterPulse(true);
    const requestBody = {
      product_id: parseInt(props?.product_id),
      project_id: parseInt(PROJECT_ID),
    };
    getProductType(requestBody, token)
      .then(async (response) => {
        if (response.status === 200) {
          props?.setProductType(response.data.type);
          const request_body = {
            product_id: parseInt(props.product_id),
            product_type: response.data.type,
            project_id: parseInt(PROJECT_ID),
          };
          const [
            segmentsListResponse,
            brandsFormsAndSizeResponse,
            occasionsResponse,
          ] = await Promise.all([
            getSegmentsList(request_body, token),
            getBrandsFormsAndSize(request_body, token),
            getOccasions(request_body, token),
          ]);
          setSegmentsList(segmentsListResponse.data.segments);
          setFormsList(brandsFormsAndSizeResponse.data.barcode_details.forms);
          setBrands(brandsFormsAndSizeResponse.data.barcode_details.brands);
          setOccasionsList(occasionsResponse.data.occasions.options);
          props?.setQuestionId(occasionsResponse.data.occasions.question_id);
        } else {
          setError("Failed to fetch product types. Unexpected status code.");
        }
      })
      .catch((error) => {
        handleError(error, navigate, dispatch);
      })
      .finally(() => {
        setFilterPulse(false);
      });
  };

  const fetchFilterOptionsForCoaster = (type) => {
    setFilterPulse(true);
    getFilterOptionsForCoaster(PROJECT_ID, props?.category, type, token)
      .then((response) => {
        if (response.status === 200) {
          if (type === "form") {
            setFormsList(response.data.data);
          } else if (type === "brand") {
            setBrands(response.data.data);
          }
        }
      })
      .catch((error) => {
        handleError(error, navigate, dispatch);
      })
      .finally(() => {
        setFilterPulse(false);
      });
  };

  // const fetchFilterOptions = () => {
  //   setFilterPulse(true);
  //   getFiltersOptionsBasedOnFilterName(PROJECT_ID, "brand", token).then(
  //     (response) => {
  //       if (response.status === 200) {
  //         debugger;
  //       }
  //     },
  //   );
  // };

  const fetchHouseHoldData = () => {
    setFilterPulse(true);
    getHouseHoldData(parseInt(PROJECT_ID), token)
      .then((response) => {
        if (response.status === 200) {
          const data = response?.data?.data;
          setHouseHoldData(data);
          // Extracting data for respective state variables
          setHouseHoldAge(data["1, HEAD OF HOUSEHOLD AGE"]?.uniques || []);
          setHouseHoldIncome(data["2, HH INCOME"]?.uniques || []);
          setHouseHoldSize(data["3, HOUSEHOLD SIZE"]?.uniques || []);
          setHouseHoldChildren(data["4, PRESENCE OF CHILDREN"]?.uniques || []);
          setHouseHoldRaceEthnicity(
            data["5, HEAD OF HOUSEHOLD ETHNICITY"]?.uniques || [],
          );
        }
      })
      .catch((error) => {
        handleError(error, navigate, dispatch);
      })
      .finally(() => {
        setFilterPulse(false);
      });
  };

  const isMaxSelectionReached = (category, selectedOption) => {
    return (
      activeFilterCategory === category &&
      selectedOption.length === MAX_SELECTION
    );
  };
  const isFilterDisabled = (category, option, selectedOptions) => {
    // Disable if we are in COMPAREBY mode and not in the activeFilterCategory
    if (activeFilterCategory && activeFilterCategory !== category) {
      return true; // Disable entire category if it's not the active one
    }

    // Check if max selection is reached and the option is not in the selected list
    return (
      isMaxSelectionReached(category, selectedOptions) &&
      !selectedOptions.some((selected) => selected.phu_id === option.phu_id) // Corrected check
    );
  };

  const isFilterDisabledWithoutPhuId = (category, option, selectedOptions) => {
    // Disable if we are in COMPAREBY mode and not in the activeFilterCategory
    if (activeFilterCategory && activeFilterCategory !== category) {
      return true; // Disable entire category if it's not the active one
    }

    // Check if max selection is reached and the option is not in the selected list
    return (
      isMaxSelectionReached(category, selectedOptions) &&
      !selectedOptions.some((selected) => selected === option) // Corrected check
    );
  };

  const isFilterDisabledWithIndex = (category, option, selectedOptions) => {
    const optionKey = Object.keys(occasionsList).find(
      (key) => occasionsList[key] === option,
    ); // Get the key instead of index

    if (activeFilterCategory && activeFilterCategory !== category) {
      return true; // Disable entire category if it's not the active one
    }

    // Check if max selection is reached and the option's key is not in the selected list
    return (
      isMaxSelectionReached(category, selectedOptions) &&
      !selectedOptions.includes(optionKey) // Check key instead of index
    );
  };
  const handleOccasionChange = (e) => {
   console.log("dsfsdf", e)
    const { value } = e.target;
    let updatedSelectedOccasions;

    // Special case: Handle "ALL" separately
    if (value === "ALL") {
      updatedSelectedOccasions = handleSelectAll(
        props?.selectedOccasions,
        Object.keys(occasionsList), // Use keys instead of array indices
      );
    } else {
      // Ensure key lookup is type-safe
      const key = Object.keys(occasionsList).find(
        (k) => String(k) === String(value),
      );
      if (!key) {
        return; // Prevent state update if key is invalid
      }
      updatedSelectedOccasions = handleSelectOption(
        props?.selectedOccasions,
        key, // Use the corrected key
      );
    }
    props?.setSelectedOccasions(updatedSelectedOccasions);
    if (updatedSelectedOccasions.length === 0) {
      setActiveFilterCategory(null);
    }
  };
  const onAgeChange = (e) => {
    const { value, checked } = e.target;
    let updatedSelectedAgelist;
    if (value.uniques === "ALL") {
      updatedSelectedAgelist = handleSelectAll(
        props?.selectedHouseHoldAge,
        houseHoldAge,
      );
    } else {
      updatedSelectedAgelist = handleSelectOption(
        props?.selectedHouseHoldAge,
        value,
      );
    }
    props?.setSelectedHouseHoldAge(updatedSelectedAgelist);
    // Reset or update the active filter category based on selection
    if (updatedSelectedAgelist.length === 0) {
      setActiveFilterCategory(null);
    }
  };

  const onIncomeChange = (e) => {
    const { value, checked } = e.target;
    let updatedSelectedIncomeList;

    if (value.uniques === "ALL") {
      updatedSelectedIncomeList = handleSelectAll(
        props?.selectedHouseHoldIncome,
        houseHoldIncome,
      );
    } else {
      updatedSelectedIncomeList = handleSelectOption(
        props?.selectedHouseHoldIncome,
        value,
      );
    }

    props?.setSelectedHouseHoldIncome(updatedSelectedIncomeList);

    // Reset or update the active filter category based on selection
    if (updatedSelectedIncomeList.length === 0) {
      setActiveFilterCategory(null);
    }
  };

  const onSizeChange = (e) => {
    const { value, checked } = e.target;
    let updatedSelectedSizeList;

    if (value.uniques === "ALL") {
      updatedSelectedSizeList = handleSelectAll(
        props?.selectedHouseHoldSize,
        houseHoldSize,
      );
    } else {
      updatedSelectedSizeList = handleSelectOption(
        props?.selectedHouseHoldSize,
        value,
      );
    }

    props?.setSelectedHouseHoldSize(updatedSelectedSizeList);

    // Reset or update the active filter category based on selection
    if (updatedSelectedSizeList.length === 0) {
      setActiveFilterCategory(null);
    }
  };

  const onChildrenChange = (e) => {
    const { value, checked } = e.target;
    let updatedSelectedChildrenList;

    if (value.uniques === "ALL") {
      updatedSelectedChildrenList = handleSelectAll(
        props?.selectedHouseHoldChildren,
        houseHoldChildren,
      );
    } else {
      updatedSelectedChildrenList = handleSelectOption(
        props?.selectedHouseHoldChildren,
        value,
      );
    }

    props?.setSelectedHouseHoldChildren(updatedSelectedChildrenList);

    // Reset or update the active filter category based on selection
    if (updatedSelectedChildrenList.length === 0) {
      setActiveFilterCategory(null);
    }
  };

  const onRaceEthnicityChange = (e) => {
    const { value, checked } = e.target;
    let updatedSelectedRaceEthnicityList;

    if (value.uniques === "ALL") {
      updatedSelectedRaceEthnicityList = handleSelectAll(
        props?.selectedHouseHoldRaceEthnicity,
        houseHoldRaceEthnicity,
      );
    } else {
      updatedSelectedRaceEthnicityList = handleSelectOption(
        props?.selectedHouseHoldRaceEthnicity,
        value,
      );
    }

    props?.setSelectedHouseHoldRaceEthnicity(updatedSelectedRaceEthnicityList);

    // Reset or update the active filter category based on selection
    if (updatedSelectedRaceEthnicityList.length === 0) {
      setActiveFilterCategory(null);
    }
  };

  const handleDayPartChange = (e, dayPartList, index) => {
    const { value, checked } = e.target;
    // Ensure selectedDayPart is always an array
    let updatedSelections = Array.isArray(props?.selectedDayPart)
      ? [...props.selectedDayPart]
      : [];

    if (value === "ALL") {
      const getDayIndex = dayPartList.map((_, i) => i);
      updatedSelections = checked ? [...getDayIndex] : []; // Select all or clear all
    } else {
      if (checked) {
        updatedSelections.push(index); // Add selection
      } else {
        updatedSelections = updatedSelections.filter((item) => item !== dayPartList.indexOf(value)); // Remove selection
      }
    }

    // Ensure "ALL" is checked when all items are selected
    if (isAllSelected(updatedSelections, dayPartList)) {
      const getDayIndex = dayPartList.map((_, i) => i);
      updatedSelections = [...getDayIndex]; // Reflect "ALL" correctly
    }

    props?.setSelectedDayPart(updatedSelections);

    // If no options remain, reset active category
    if (updatedSelections.length === 0) {
      setActiveFilterCategory(null);
    }
  };

  const handleWeekPartChange = (e, weekPartList, index) => {
    const { value, checked } = e.target;

    // Ensure selectedWeekPart is always an array
    let updatedSelections = Array.isArray(props?.selectedWeekPart)
      ? [...props.selectedWeekPart]
      : [];

    if (value === "ALL") {
      const getWeekIndexes = weekPartList.map((_, i) => i);
      updatedSelections = checked ? [...getWeekIndexes] : []; // Select all or clear all
    } else {
      if (checked) {
        updatedSelections.push(index); // Add selection
      } else {
        updatedSelections = updatedSelections.filter((item) => item !== weekPartList.indexOf(value)); // Remove selection
      }
    }

    // Ensure "ALL" is checked when all items are selected
    if (isAllSelected(updatedSelections, weekPartList)) {
      const getWeekIndexes = weekPartList.map((_, i) => i);
      updatedSelections = [...getWeekIndexes]; // Reflect "ALL" correctly
    }

    props?.setSelectedWeekPart(updatedSelections);

    // If no options remain, reset active category
    if (updatedSelections.length === 0) {
      setActiveFilterCategory(null);
    }
  };

  const handleTimeOfDayChange = (e, timeOfDayList) => {
    const { value, checked } = e.target;

    // Ensure selectedTimeOfDay is always an array
    let updatedSelections = Array.isArray(props?.selectedTimeOfDay)
      ? [...props.selectedTimeOfDay]
      : [];

    if (value === "ALL") {
      updatedSelections = checked ? [...timeOfDayList] : []; // Select all or clear all
    } else {
      if (checked) {
        updatedSelections.push(value); // Add selection
      } else {
        updatedSelections = updatedSelections.filter((item) => item !== value); // Remove selection
      }
    }

    // Ensure "ALL" is checked when all items are selected
    if (isAllSelected(updatedSelections, timeOfDayList)) {
      updatedSelections = [...timeOfDayList]; // Reflect "ALL" correctly
    }

    props?.setSelectedTimeOfDay(updatedSelections);

    // If no options remain, reset active category
    if (updatedSelections.length === 0) {
      setActiveFilterCategory(null);
    }
  };

  const handleSegmentChange = (e) => {
    const { value, checked } = e.target;
    let updatedSelectedSegments;

    if (value === "ALL") {
      updatedSelectedSegments = handleSelectAll(selectedSegments, segmentsList);
    } else {
      updatedSelectedSegments = handleSelectOption(selectedSegments, value);
    }

    setSelectedSegments(updatedSelectedSegments);

    // Reset or update the active filter category based on selection
    if (updatedSelectedSegments.length === 0) {
      setActiveFilterCategory(null);
    }
  };

  const handleBrandChange = (e) => {
    const { value, checked } = e.target;
    let updatedSelectedBrands;

    if (value === "ALL") {
      updatedSelectedBrands = handleSelectAll(props?.selectedBrands, brands);
    } else {
      updatedSelectedBrands = handleSelectOption(props?.selectedBrands, value);
    }

    props?.setSelectedBrands(updatedSelectedBrands);

    // Reset or update the active filter category based on selection
    if (updatedSelectedBrands.length === 0) {
      setActiveFilterCategory(null);
    }
  };

  const handleFormChange = (e) => {
    const { value, checked } = e.target;
    let updatedSelectedForms;

    if (value === "ALL") {
      updatedSelectedForms = handleSelectAll(props?.selectedForms, formsList);
    } else {
      updatedSelectedForms = handleSelectOption(props?.selectedForms, value);
    }

    props?.setSelectedForms(updatedSelectedForms);

    // Reset or update the active filter category based on selection
    if (updatedSelectedForms.length === 0) {
      setActiveFilterCategory(null);
    }
  };

  const handleApply = () => {
    props?.setApply(!props?.apply);
    props?.setIsCompareByClicked(false);
    // window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleReset = () => {
    props?.handleReset();
  }

  useEffect(() => {
    if (props?.deviceCode === 1) {
      fetchFilterOptionsForCoaster("brand");
      fetchFilterOptionsForCoaster("form");
    } else {
      fetchProductType();
    }
  }, [props?.device, props?.deviceCode, props?.category, props?.product_id]);

  useEffect(() => {
    if(props?.deviceCode === 1) {
      setDayPartList(coasterDayPartListData);
    } else {
      setDayPartList(dayPartListData);
    }
  }, [props?.deviceCode])

  useEffect(() => {
    fetchHouseHoldData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const sidebar = document.querySelector(".sticky-sidebar");
      if (sidebar) {
        const parent = sidebar.parentElement; // Get the `.row` automatically
        const parentBottom = parent.getBoundingClientRect().bottom;
        const windowHeight = window.innerHeight;

        // Prevent height from shrinking when reaching the bottom
        const newHeight =
          parentBottom > windowHeight
            ? "calc(100vh - 130px)"
            : `${Math.max(parentBottom - 130, 300)}px`; // Ensure minimum height

        setMaxHeight(newHeight);
      }
    };

    window.addEventListener("scroll", handleResize);
    window.addEventListener("resize", handleResize);
    handleResize(); // Initial call

    return () => {
      window.removeEventListener("scroll", handleResize);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleGenderChange = () => {};

  return (
    <>
      {/* <div className="col-3 filter-wrapper"> 
        <div className="filter-hide-toggle">
          <i className="pi pi-angle-left"></i>
        </div> */}
        <div
          className="col-3 sticky-sidebar"
          style={{
            borderRight: "1px solid #dee2e6",
            position: "sticky",
            top: "115px",
            height: maxHeight,
            overflowY: "auto",
            background: "white",
            zIndex: 10,
          }}
        >
          <div className="filter-header-row">
            <span
              className="d-contents fs-5 mb-0 fw-600"
              style={{ color: "#000" }}
            >
              FILTER BY
            </span>
          </div>
          <Panel
            className="mt-2 text-start"
            header={filterPulse ? <LabelSkeleton /> : "BY BRANDS"}
            toggleable
            expandIcon="pi pi-angle-up"
            collapseIcon="pi pi-angle-down"
          >
            <div className="flex flex-column gap-3 panel-height">
              {!filterPulse ? (
                (brands.length > 0
                  ? ["ALL", ...brands.filter((brand) => brand !== "ALL")]
                  : []
                )?.map((brand, index) => {
                  return (
                    <div
                      key={`brand-${index}`}
                      className="d-flex align-items-center pt-2 pb-2"
                      style={{ justifyContent: "flex-start" }}
                    >
                      <Checkbox
                        inputId={`brand-${index}`}
                        value={brand}
                        checked={
                          brand === "ALL"
                            ? isAllSelected(props?.selectedBrands, brands) // Skip 'ALL'
                            : props?.selectedBrands?.includes(brand)
                        }
                        onChange={handleBrandChange}
                        disabled={isFilterDisabledWithoutPhuId(
                          "brands",
                          brand,
                          props?.selectedBrands,
                        )}
                      />
                      <label
                        htmlFor={`brand-${index}`}
                        className="ml-2 ps-2"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        title={brand}
                      >
                        {brand}
                      </label>
                    </div>
                  );
                })
              ) : (
                <FilterSkeleton />
              )}
            </div>
          </Panel>
          <Divider align="center">
            <span
              className="filter-label"
              style={{ fontSize: "12px", fontWeight: "600" }}
            >
              AND
            </span>
          </Divider>
          {!props.occasion && props?.deviceCode !== 1 && (
            <>
              <Panel
                className="mt-2 text-start"
                header={filterPulse ? <LabelSkeleton /> : "BY OCCASION"}
                toggleable
                expandIcon="pi pi-angle-up"
                collapseIcon="pi pi-angle-down"
                collapsed={true}
              >
                <div className="flex flex-column gap-3">
                  {!filterPulse ? (
                    (occasionsList &&
                    typeof occasionsList === "object" &&
                    Object.keys(occasionsList)?.length > 0
                      ? [
                          { key: "ALL", value: "ALL" },
                          ...Object.entries(occasionsList)
                            .filter(([key, _]) => key !== "ALL")
                            .map(([key, value]) => ({ key, value })),
                        ]
                      : []
                    )?.map((occasion, index) => {
                      return (
                        <div
                          key={`occassion-${index}`}
                          className="d-flex align-items-center pt-2 pb-2"
                          style={{ justifyContent: "flex-start" }}
                        >
                          <Checkbox
                            inputId={`occassion-${occasion.key}`}
                            value={occasion.key}
                            onChange={handleOccasionChange}
                            checked={
                              occasion.key === "ALL"
                                ? isAllSelected(
                                    props?.selectedOccasions,
                                    Object.keys(occasionsList),
                                  )
                                : props?.selectedOccasions?.includes(occasion.key)
                            }
                            disabled={isFilterDisabledWithIndex(
                              "occasions",
                              occasion.key,
                              props?.selectedOccasions,
                            )}
                          />
                          <label
                            htmlFor={`occassion-${occasion.key}`}
                            title={occasion.value}
                            className="ml-2 ps-2"
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {occasion.value}
                          </label>
                        </div>
                      );
                    })
                  ) : (
                    <FilterSkeleton />
                  )}
                </div>
              </Panel>
              <Divider align="center">
                <span
                  className="filter-label"
                  style={{ fontSize: "12px", fontWeight: "600" }}
                >
                  AND
                </span>
              </Divider>
            </>
          )}
          <Panel
            className="mt-2 text-start"
            header={filterPulse ? <LabelSkeleton /> : "BY HOUSEHOLD DEMOS"}
            toggleable
            expandIcon="pi pi-angle-up"
            collapseIcon="pi pi-angle-down"
            collapsed={true}
          >
            {Object.entries(houseHoldData).map(([category, details]) => {
              const categoryKey = category; // Extracting category name
              return (
                <div key={categoryKey}>
                  <label className="householdSubCategory mt-3">
                    {categoryKey.split(", ")[1]}
                  </label>
                  <div className="flex flex-column gap-3">
                    {!filterPulse ? (
                      [
                        // Add "All" option before the category options
                        {
                          phu_id: 0,
                          uniques: "All",
                        },
                        ...details.uniques,
                      ].map((item) => (
                        <div
                          key={item.phu_id}
                          className="flex align-items-center pt-2 pb-2"
                        >
                          <Checkbox
                            inputId={`household-${item.phu_id}`}
                            value={item}
                            onChange={() => onFilterChange(categoryKey, item)}
                            checked={
                              item.phu_id === 0
                                ? isAllSelectedHold(
                                    props?.selectedFilters?.[categoryKey] || [],
                                    details.uniques,
                                  )
                                : (
                                    props?.selectedFilters?.[categoryKey] || []
                                  ).some(
                                    (selected) => selected.phu_id === item.phu_id,
                                  )
                            }
                            disabled={isFilterDisabled(
                              categoryKey,
                              item,
                              props?.selectedFilters?.[categoryKey] || [],
                            )}
                          />
                          <label htmlFor={`household-${item.phu_id}`} className="ml-2 ps-2">
                            {item.uniques}
                          </label>
                        </div>
                      ))
                    ) : (
                      <FilterSkeleton />
                    )}
                  </div>
                </div>
              );
            })}
          </Panel>
          <Divider align="center">
            <span
              className="filter-label"
              style={{ fontSize: "12px", fontWeight: "600" }}
            >
              AND
            </span>
          </Divider>
          {/* <Panel
            className="mt-2 text-start"
            header={props?.loading ? <LabelSkeleton /> : "BY GENDER"}
            toggleable
            expandIcon="pi pi-angle-up"
            collapseIcon="pi pi-angle-down"
            collapsed={true}
          >
            <div className="flex flex-column gap-3 panel-height">
              {(props.activeOption === "COMPAREBY"
                ? genderlist.length > 0 && genderlist.length < MAX_SELECTION
                  ? ["ALL", ...genderlist.filter((gender) => gender !== "ALL")]
                  : genderlist.filter((gender) => gender !== "ALL")
                : genderlist.length > 0
                  ? ["ALL", ...genderlist]
                  : []
              )?.map((gender, index) => {
                return (
                  <div
                    key={index} 
                    className="flex align-items-center pt-2 pb-2"
                  >
                    <Checkbox
                      inputId={index}
                      value={gender}
                      checked={
                        gender === "ALL"
                          ? isAllSelected(props?.selectedGenders, genderlist) // Skip 'ALL'
                          : props?.selectedGenders?.includes(gender)
                      }
                      onChange={handleGenderChange}
                      disabled={isFilterDisabledWithoutPhuId(
                        "genders",
                        gender,
                        props?.selectedGenders,
                      )}
                    />
                    <label htmlFor={gender} className="ml-2 ps-2">
                      {gender} 
                    </label>
                  </div>
                );
              })}
            </div>
          </Panel> */}
          {/* <Divider align="center">
            <span
              className="filter-label"
              style={{ fontSize: "12px", fontWeight: "600" }}
            >
              {props.activeOption === "FILTERBY" ? "AND" : "OR"}
            </span>
          </Divider> */}
          {/* <Panel
            className="mt-2 text-start"
            header={props?.loading ? <LabelSkeleton /> : "BY CONSUMER SEGMENTS"}
            toggleable
            expandIcon="pi pi-angle-up"
            collapseIcon="pi pi-angle-down"
            collapsed={true}
          >
            <label className="householdSubCategory">HML</label>
            <div className="flex flex-column gap-3 panel-height">
              {!props?.loading ? (
                (props.activeOption === "COMPAREBY"
                  ? segmentsList?.length > 0 &&
                    segmentsList?.length < MAX_SELECTION
                    ? [
                        "ALL",
                        ...segmentsList?.filter((segment) => segment !== "ALL"),
                      ]
                    : segmentsList?.filter((segment) => segment !== "ALL")
                  : segmentsList?.length > 0
                    ? ["ALL", ...segmentsList]
                    : []
                )?.map((segment, index) => {
                  return (
                    <div
                      key={index}
                      className="flex align-items-center pt-2 pb-2"
                    >
                      <Checkbox
                        inputId={index}
                        value={segment}
                        onChange={handleSegmentChange}
                        checked={
                          segment === "ALL"
                            ? isAllSelected(selectedSegments, segmentsList) // Skip 'ALL'
                            : selectedSegments?.includes(segment)
                        }
                      />
                      <label htmlFor={index} className="ml-2 ps-2">
                        {segment}
                      </label>
                    </div>
                  );
                })
              ) : (
                <FilterSkeleton />
              )}
            </div>
          </Panel>
          <Divider align="center">
            <span
              className="filter-label"
              style={{ fontSize: "12px", fontWeight: "600" }}
            >
              {props?.activeOption === "FILTERBY" ? "AND" : "OR"}
            </span>
          </Divider> */}
          <Panel
            className="mt-2 text-start"
            header={filterPulse ? <LabelSkeleton /> : "BY PRODUCT FORM"}
            toggleable
            expandIcon="pi pi-angle-up"
            collapseIcon="pi pi-angle-down"
            collapsed={true}
          >
            <div className="flex flex-column gap-3">
              {(formsList?.length > 0
                ? ["ALL", ...formsList.filter((form) => form !== "ALL")]
                : []
              ).map((form) => {
                return (
                  <div
                    key={form.key}
                    className="flex align-items-center pt-2 pb-2"
                  >
                    <Checkbox
                      inputId={`productform-${form.key}`}
                      value={form}
                      onChange={handleFormChange}
                      checked={
                        form === "ALL"
                          ? isAllSelected(props?.selectedForms, formsList) // Skip 'ALL'
                          : props?.selectedForms?.includes(form)
                      }
                    />
                    <label htmlFor={`productform-${form.key}`} className="ml-2 ps-2">
                      {form}
                    </label>
                  </div>
                );
              })}
            </div>
          </Panel>
          { isDayWeekPartShow && process.env?.REACT_APP_SHOW_DAY_WEEK_PART === "true" ?
            <>
              <Divider align="center">
                <span
                  className="filter-label"
                  style={{ fontSize: "12px", fontWeight: "600" }}
                >
                  AND
                </span>
              </Divider>
              <Panel
                className="mt-2 text-start"
                header={props?.loading ? <LabelSkeleton /> : "BY DAY PART"}
                toggleable
                expandIcon="pi pi-angle-up"
                collapseIcon="pi pi-angle-down"
                collapsed={true}
              >
                <div className="flex flex-column gap-3 panel-height">
                  {(props.activeOption === "COMPAREBY"
                    ? dayPartList.length > 0 && dayPartList.length < MAX_SELECTION
                      ? ["ALL", ...dayPartList.filter((daypart) => daypart !== "ALL")]
                      : dayPartList.filter((daypart) => daypart !== "ALL")
                    : dayPartList.length > 0
                      ? ["ALL", ...dayPartList]
                      : []
                  )?.map((daypart, index) => {
                    return (
                      <div key={index} className="flex align-items-center pt-2 pb-2">
                        <Checkbox
                          inputId={`daypart-${index}`}
                          value={daypart}
                          checked={
                            daypart === "ALL"
                              ? isAllSelected(props?.selectedDayPart, dayPartList)
                              : props?.selectedDayPart?.includes(index - 1)
                          }
                          onChange={(e) => handleDayPartChange(e, dayPartList, index - 1)}
                          disabled={isFilterDisabledWithoutPhuId(
                            "dayPart",
                            index - 1,
                            props?.selectedDayPart,
                          )}
                        />
                        <label htmlFor={`daypart-${index}`} className="ml-2 ps-2">
                          {daypart}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </Panel>
              <Divider align="center">
                <span
                  className="filter-label"
                  style={{ fontSize: "12px", fontWeight: "600" }}
                >
                  AND
                </span>
              </Divider>
              <Panel
                className="mt-2 text-start"
                header={props?.loading ? <LabelSkeleton /> : "BY WEEK PART"}
                toggleable
                expandIcon="pi pi-angle-up"
                collapseIcon="pi pi-angle-down"
                collapsed={true}
              >
                <div className="flex flex-column gap-3 panel-height">
                {(props.activeOption === "COMPAREBY"
                  ? weekPartList.length > 0 && weekPartList.length < MAX_SELECTION
                    ? ["ALL", ...weekPartList.filter((weekpart) => weekpart !== "ALL")]
                    : weekPartList.filter((weekpart) => weekpart !== "ALL")
                  : weekPartList.length > 0
                    ? ["ALL", ...weekPartList]
                    : []
                )?.map((weekpart, index) => {
                  return (
                    <div key={index} className="flex align-items-center pt-2 pb-2">
                      <Checkbox
                        inputId={`weekpart_${index}`} 
                        value={weekpart} // Now passing index
                        checked={
                          weekpart === "ALL"
                            ? isAllSelected(props?.selectedWeekPart, weekPartList.map((_, i) => i)) 
                            : props?.selectedWeekPart?.includes(index - 1) // Now checking index
                        }
                        onChange={(e) => handleWeekPartChange(e, weekPartList, index-1)}
                        disabled={isFilterDisabledWithoutPhuId(
                          "weekPart",
                          index - 1,
                          props?.selectedWeekPart
                        )}
                      />
                      <label htmlFor={`weekpart_${index}`} className="ml-2 ps-2">
                        {weekpart}
                      </label>
                    </div>
                  );
                })}
                </div>
              </Panel>
            </> : ''
          }

          {/* <Divider align="center">
            <span
              className="filter-label"
              style={{ fontSize: "12px", fontWeight: "600" }}
            >
              AND
            </span>
          </Divider>
          <Panel
            className="mt-2 text-start"
            header={props?.loading ? <LabelSkeleton /> : "BY TIME OF DAY"}
            toggleable
            expandIcon="pi pi-angle-up"
            collapseIcon="pi pi-angle-down"
            collapsed={true}
          >
            <div className="flex flex-column gap-3 panel-height">
              {(
                props.activeOption === "COMPAREBY"
                  ? timeOfDayList.length > 0 && timeOfDayList.length < MAX_SELECTION
                    ? ["ALL", ...timeOfDayList.filter((time) => time !== "ALL")]
                    : timeOfDayList.filter((time) => time !== "ALL")
                  : timeOfDayList.length > 0
                    ? ["ALL", ...timeOfDayList]
                    : []
              )?.map((time, index) => {
                return (
                  <div key={index} className="flex align-items-center pt-2 pb-2">
                    <Checkbox
                      inputId={`timeOfDay-${index}`}
                      value={time}
                      checked={
                        time === "ALL"
                          ? isAllSelected(props?.selectedTimeOfDay, timeOfDayList)
                          : props?.selectedTimeOfDay?.includes(time)
                      }
                      onChange={(e) => handleTimeOfDayChange(e, timeOfDayList)}
                      disabled={isFilterDisabledWithoutPhuId(
                        "timeOfDay",
                        time,
                        props?.selectedTimeOfDay
                      )}
                    />
                    <label htmlFor={`timeOfDay-${index}`} className="ml-2 ps-2">
                      {time}
                    </label>
                  </div>
                );
              })}
            </div>
          </Panel> */}

          <Divider align="center"></Divider>
          <div className="flex align-items-center gap-2 managed-row">
            <Button
              label="RESET"
              className="p-button-text me-2 resetButton br-c fw-600"
              style={{ width: "120px", color: "gray" }}
              onClick={handleReset}
            ></Button>
            <Button
              label="APPLY"
              className="p-button-text me-2  active-button br-c fw-600"
              style={{ width: "120px" }}
              onClick={handleApply}
            ></Button>
          </div>
        </div>
      {/* </div>     */}
    </>
  );
});

export default FilterByForDashboard;
