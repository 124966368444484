import React, { useState } from "react";
import "../../assets/css/login.css";
import logo from "../../assets/images/tb_logo.png";
import { validateEmail } from "../../globalUtilities/validation";
import { generateCaptcha } from "../../globalUtilities/captcha";
import { login } from "../../utils/auth/authActions";
import { useDispatch } from "react-redux";
import StepOne from "../../components/auth/StepOne";
import StepTwo from "../../components/auth/StepTwo";
import StepThree from "../../components/auth/StepThree";
import StepFour from "../../components/auth/StepFour";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [enteredCaptcha, setEnteredCaptcha] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // State to track loading
  const [passwordError, setPasswordError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);

  const handleNext = () => {
    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
    } else if (email.includes("@pg.com")) {
      setError("");
      setStep(3); // Display P&G SSO Login Screen
    } else {
      setError("");
      setStep(2); // Show Password and captcha fields
    }
  };

  const handleLogin = () => {
    if (password === "") {
      setPasswordError("Oh Oh ! Please Enter Password.");
      setError("");
    } else {
      if (enteredCaptcha === "") {
        setError("Oh Oh! Please enter captcha.");
      } else if (enteredCaptcha !== captcha) {
        setError("Invalid captcha. Please try again.");
        setCaptcha(generateCaptcha()); // Regenerate captcha if invalid
        setEnteredCaptcha(""); // Clear the entered captcha field
      } else {
        setError("");
        dispatch(login({ email, password }, navigate, setLoading));
      }
    }
  };

  const handleForgotPassword = () => {
    setError("");
    setEnteredCaptcha("");
    setStep(4);
  };

  const handleBackToLogin = () => {
    setStep(1);
    setEmail("");
    setPassword("");
    setEnteredCaptcha("");
  };

  const handlePasswordChange = (password) => {
    setPasswordError("");
    setPassword(password);
  };

  return (
    <div className="container-fluid gradient-form">
      {loading && (
        <div className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center bg-dark bg-opacity-50">
          <ProgressSpinner />
        </div>
      )}{" "}
      {/* Show spinner when loading is true */}
      <div className="row no-gutters" style={{ height: "100vh" }}>
        <div className="col-md-5 left-side d-none d-md-block">
          <div className="d-flex flex-column gradient-custom-2 h-100">
            <div className="text-white text-start px-3 py-4 p-md-5 mx-md-4">
              <h4 className="mb-4 fw-600">
                THE MOST ADVANCED <br />
                TECHNOLOGY <br />
                SUITE IN THE INDUSTRY
              </h4>
              <p className="small mb-0 secondary_text">
                This powerful technology suite enables insights and growth teams
                to passively track and effortlessly observe behavior, uncover
                the conscience and subconscious motivators behind decisions,
                analyze trends, and share narrative-driven insights across any
                research methodology for agile decision-making.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-7">
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div
              className={
                step === 4 ? "logo_padding_forgot_password" : "logo_padding"
              }
            >
              <img
                src={logo}
                style={{ width: "185px" }}
                alt="logo"
                className="image-fluid"
              />
            </div>
            {step === 1 && (
              <StepOne
                email={email}
                setEmail={setEmail}
                error={error}
                handleNext={handleNext}
              />
            )}
            {step === 2 && (
              <StepTwo
                password={password}
                handlePasswordChange={handlePasswordChange}
                error={error}
                passwordError={passwordError}
                enteredCaptcha={enteredCaptcha}
                setEnteredCaptcha={setEnteredCaptcha}
                captcha={captcha}
                handleLogin={handleLogin}
                setStep={setStep}
                handleForgotPassword={handleForgotPassword}
                loading={loading}
              />
            )}
            {step === 3 && <StepThree />}
            {step === 4 && (
              <StepFour
                email={email}
                setEmail={setEmail}
                error={error}
                enteredCaptcha={enteredCaptcha}
                setEnteredCaptcha={setEnteredCaptcha}
                captcha={captcha}
                setStep={setStep}
                handleBackToLogin={handleBackToLogin}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
