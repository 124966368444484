import React from "react";
import { Chart } from "primereact/chart";

const ConsumptionSlope = () => {
  const data = {
    labels: ["BEGINNING OF CONTAINER", "DAY OF USE", "END OF CONTAINER"],
    datasets: [
      {
        label: "Category",
        data: [4, 2, 3],
        borderColor: "orange",
        backgroundColor: "orange",
      },
      {
        label: "Tide",
        data: [1, 2.5, 1],
        borderColor: "blue",
        backgroundColor: "blue",
      },
      {
        label: "Gain",
        data: [2, 3, 0],
        borderColor: "#EE534F",
        backgroundColor: "#EE534F",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      datalabels: false,
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "AVG AMOUNT PER USE (OUNCES)*",
        },
      },
    },
  };
  return (
    <>
      <div className="fs-14 text-start mt-4 mb-4">
        <strong>CONSUMPTION SLOPE</strong>
      </div>
      <div className="col-12">
        <Chart type="line" data={data} options={options} />
      </div>
    </>
  );
};

export default ConsumptionSlope;
