import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { Panel } from "primereact/panel";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import {
  getProductType,
  getBrandsFormsAndSize,
  getParticipantsList,
  getSegmentsList,
} from "../../services/reports/reports";
import { PROJECT_ID } from "../../utils/constants";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import handleError from "../../utils/handleError";
import {
  handleSelectAll,
  handleSelectOption,
  isAllSelected,
} from "../../utils/filterBy/filterBy";
import FilterSkeleton from "../Skeletons/FilterSkeleton";
import LabelSkeleton from "../Skeletons/LabelSkeleton";
const MAX_SELECTION = 5;

const FilterBy = React.memo(function FilterBy(props) {
  const [activeFilterCategory, setActiveFilterCategory] = useState(null);
  const [brandsList, setBrandsList] = useState([]);
  const [formsList, setFormsList] = useState([]);
  const [sizesList, setSizesList] = useState([]);
  const [participantsList, setParticipantsList] = useState([]);
  const [segmentsList, setSegmentsList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchProductType = () => {
    setLoading(true);
    setError(null);
    const requestBody = {
      product_id: parseInt(props.product_id),
      project_id: parseInt(PROJECT_ID),
    };
    getProductType(requestBody, token)
      .then(async (response) => {
        if (response.status === 200) {
          // setProductType(response.data.type);
          props.setProductType(response.data.type);
          const request_body = {
            product_id: parseInt(props.product_id),
            product_type: response.data.type,
            project_id: parseInt(PROJECT_ID),
          };
          const [
            brandsFormsAndSizeResponse,
            participantsListResponse,
            segmentsListResponse,
          ] = await Promise.all([
            getBrandsFormsAndSize(request_body, token),
            getParticipantsList(request_body, token),
            getSegmentsList(request_body, token),
          ]);
          setBrandsList(brandsFormsAndSizeResponse.data.barcode_details.brands);
          setSizesList(brandsFormsAndSizeResponse.data.barcode_details.sizes);
          setFormsList(brandsFormsAndSizeResponse.data.barcode_details.forms);
          setParticipantsList(participantsListResponse.data.participants_data);
          setSegmentsList(segmentsListResponse.data.segments);
        } else {
          setError("Failed to fetch product types. Unexpected status code.");
        }
      })
      .catch((error) => {
        handleError(error, navigate, dispatch);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // Fetch product types when the component mounts
    fetchProductType();
  }, [props.device, props.deviceCode, props.category, props.product_id]);

  const genderlist = ["ALL", "MALE", "FEMALE", "NON-BINARY"];
  const handleBrandChange = (e) => {
    const { value } = e.target; // No longer using checked, since it can be inferred
    let updatedSelectedBrands;
    if (value === "ALL") {
      // Updated to check value directly
      updatedSelectedBrands = handleSelectAll(props.selectedBrands, brandsList);
    } else {
      updatedSelectedBrands = handleSelectOption(props.selectedBrands, value); // value is now just the string
    }
    props.setSelectedBrands(updatedSelectedBrands);
    // If everything is unchecked, reset the active filter category
    if (updatedSelectedBrands.length === 0) {
      setActiveFilterCategory(null);
    } else if (props.activeOption === "COMPAREBY") {
      setActiveFilterCategory("brand");
    }
  };
  const handleFormChange = (e) => {
    const { value } = e.target; // No longer using checked, since it can be inferred
    let updatedSelectedForms;
    if (value === "ALL") {
      // Updated to check value directly
      updatedSelectedForms = handleSelectAll(props.selectedForms, formsList);
    } else {
      updatedSelectedForms = handleSelectOption(props.selectedForms, value); // value is now just the string
    }
    props.setSelectedForms(updatedSelectedForms);
    // If everything is unchecked, reset the active filter category
    if (updatedSelectedForms.length === 0) {
      setActiveFilterCategory(null);
    } else if (props.activeOption === "COMPAREBY") {
      setActiveFilterCategory("form");
    }
  };
  const handleGenderChange = (e) => {
    const { value } = e.target; // No longer using checked, since it can be inferred
    let updatedSelectedGenders;
    if (value === "ALL") {
      // Updated to check value directly
      updatedSelectedGenders = handleSelectAll(
        props.selectedGenders,
        genderlist.slice(1),
      );
    } else {
      updatedSelectedGenders = handleSelectOption(props.selectedGenders, value); // value is now just the string
    }
    props.setSelectedGenders(updatedSelectedGenders);
    // If everything is unchecked, reset the active filter category
    if (updatedSelectedGenders.length === 0) {
      setActiveFilterCategory(null);
    } else if (props.activeOption === "COMPAREBY") {
      setActiveFilterCategory("gender");
    }
  };
  const handleParticipantChange = (e) => {
    const { value } = e.target;
    let updatedSelectedParticipants;

    if (value === "ALL") {
      // If "ALL" is selected, select all participants
      if (props.selectedParticipants.length === participantsList.length) {
        updatedSelectedParticipants = []; // Deselect all if already selected
      } else {
        updatedSelectedParticipants = participantsList.map(
          (p) => p.participant_id,
        ); // Select all
      }
    } else {
      // Handle individual participant selection
      updatedSelectedParticipants = handleSelectOption(
        props.selectedParticipants,
        value,
      );
    }

    props.setSelectedParticipants(updatedSelectedParticipants);

    // Adjust the active filter category
    if (updatedSelectedParticipants.length === 0) {
      setActiveFilterCategory(null);
    } else if (props.activeOption === "COMPAREBY") {
      setActiveFilterCategory("participant");
    }
  };
  const handleSizeChange = (e) => {
    const { value } = e.target; // No longer using checked, since it can be inferred
    let updatedSelectedSizes;
    if (value === "ALL") {
      // Updated to check value directly
      updatedSelectedSizes = handleSelectAll(props.selectedSizes, sizesList);
    } else {
      updatedSelectedSizes = handleSelectOption(props.selectedSizes, value); // value is now just the string
    }
    props.setSelectedSizes(updatedSelectedSizes);
    // If everything is unchecked, reset the active filter category
    if (updatedSelectedSizes.length === 0) {
      setActiveFilterCategory(null);
    } else if (props.activeOption === "COMPAREBY") {
      setActiveFilterCategory("size");
    }
  };
  const handleSegmentChange = (e) => {
    const { value } = e.target; // No longer using checked, since it can be inferred
    let updatedSelectedSegments;
    if (value === "ALL") {
      // Updated to check value directly
      updatedSelectedSegments = handleSelectAll(
        props.selectedSegment,
        segmentsList,
      );
    } else {
      updatedSelectedSegments = handleSelectOption(
        props.selectedSegment,
        value,
      ); // value is now just the string
    }
    props.setSelectedSegments(updatedSelectedSegments);
    // If everything is unchecked, reset the active filter category
    if (updatedSelectedSegments.length === 0) {
      setActiveFilterCategory(null);
    } else if (props.activeOption === "COMPAREBY") {
      setActiveFilterCategory("segment");
    }
  };
  const isMaxSelectionReached = (category, selectedOption) => {
    return (
      activeFilterCategory === category &&
      selectedOption.length === MAX_SELECTION
    );
  };
  const handleFilterAndCompare = (option) => {
    props.setActiveOption(option);
  };
  const isFilterDisabled = (category, optionKey, selectedOption) => {
    // Disable if we are in COMPAREBY mode and not in the activeFilterCategory
    if (activeFilterCategory && activeFilterCategory !== category) {
      return true; // Disable entire category if it's not the active one
    }

    // Disable unchecked options if max selection reached for the current active category
    return (
      isMaxSelectionReached(category, selectedOption) &&
      !selectedOption.includes(optionKey)
    );
  };

  return (
    <>
      <div className="col-3" style={{ borderRight: "1px solid #dee2e6" }}>
        <div className="filter-header-row">
          <div className="col-6">
            <Button
              className={`p-button-text me-2  ${props.activeOption === "FILTERBY" ? "active-button" : "inactive-button"} br-c`}
              outlined
              onClick={() => handleFilterAndCompare("FILTERBY")}
              label="FILTER BY"
              style={{ width: "130px" }}
            />
          </div>
          <div className="col-6">
            <Button
              className={`p-button-text me-2 ${props.activeOption === "COMPAREBY" ? "active-button" : "inactive-button"} br-c`}
              outlined
              onClick={() => handleFilterAndCompare("COMPAREBY")}
              label="COMPARE BY"
              style={{ width: "130px" }}
            />
          </div>
        </div>
        <Panel className="mt-2 text-start">
          <div className="flex flex-column gap-3 text-start mt-3 panel-height">
            <div
              key={props.productType}
              className="flex align-items-center pt-2 pb-2"
            >
              {!loading ? (
                <>
                  <RadioButton
                    inputId={props.productType}
                    name="category"
                    value={props.productType}
                    checked
                  />
                  <label htmlFor={props.productType} className="ps-2">
                    {props.productType?.toUpperCase()}
                  </label>
                </>
              ) : (
                <LabelSkeleton />
              )}
            </div>
          </div>
        </Panel>
        <Divider align="center">
          <span
            className="filter-label"
            style={{ fontSize: "12px", fontWeight: "600" }}
          >
            {props.activeOption === "FILTERBY" ? "AND" : "OR"}
          </span>
        </Divider>
        <Panel
          className="mt-2 text-start"
          header={loading ? <LabelSkeleton /> : "BY BRAND"}
          toggleable
          expandIcon="pi pi-angle-up"
          collapseIcon="pi pi-angle-down"
          collapsed={true}
        >
          <div className="flex flex-column gap-3 panel-height">
            {!loading ? (
              (props.activeOption === "COMPAREBY"
                ? brandsList.length > 0 && brandsList.length < 5
                  ? ["ALL", ...brandsList.filter((brand) => brand !== "ALL")]
                  : brandsList.filter((brand) => brand !== "ALL")
                : brandsList.length > 0
                  ? ["ALL", ...brandsList]
                  : []
              )?.map((brand, index) => {
                return (
                  <div
                    key={index}
                    className="d-flex align-items-center pt-2 pb-2"
                    style={{ justifyContent: "flex-start" }}
                  >
                    <Checkbox
                      inputId={index}
                      name="category"
                      value={brand}
                      checked={
                        brand === "ALL"
                          ? isAllSelected(props.selectedBrands, brandsList) // Skip 'ALL'
                          : props.selectedBrands.includes(brand)
                      }
                      onChange={handleBrandChange}
                      disabled={isFilterDisabled(
                        "brand",
                        brand,
                        props.selectedBrands,
                      )}
                    />
                    <label
                      htmlFor={index}
                      className="ml-2 ps-2"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      title={brand}
                    >
                      {brand}
                    </label>
                  </div>
                );
              })
            ) : (
              <FilterSkeleton />
            )}
          </div>
        </Panel>
        <Divider align="center">
          <span
            className="filter-label"
            style={{ fontSize: "12px", fontWeight: "600" }}
          >
            {props.activeOption === "FILTERBY" ? "AND" : "OR"}
          </span>
        </Divider>
        <Panel
          className="mt-2 text-start"
          header={loading ? <LabelSkeleton /> : "BY FORM"}
          toggleable
          expandIcon="pi pi-angle-up"
          collapseIcon="pi pi-angle-down"
          collapsed={true}
        >
          <div className="flex flex-column gap-3 panel-height">
            {!loading ? (
              (props.activeOption === "COMPAREBY"
                ? formsList.length > 0 && formsList.length < 5
                  ? ["ALL", ...formsList.filter((form) => form !== "ALL")]
                  : formsList.filter((form) => form !== "ALL")
                : formsList.length > 0
                  ? ["ALL", ...formsList]
                  : []
              )?.map((form, index) => {
                return (
                  <div
                    key={index}
                    className="flex align-items-center pt-2 pb-2"
                  >
                    <Checkbox
                      inputId={index}
                      name="category"
                      value={form}
                      checked={
                        form === "ALL"
                          ? isAllSelected(props.selectedForms, formsList) // Skip 'ALL'
                          : props.selectedForms.includes(form)
                      }
                      onChange={handleFormChange}
                      disabled={isFilterDisabled(
                        "form",
                        form,
                        props.selectedForms,
                      )}
                    />
                    <label htmlFor={index} className="ml-2 ps-2">
                      {form}
                    </label>
                  </div>
                );
              })
            ) : (
              <FilterSkeleton />
            )}
          </div>
        </Panel>
        <Divider align="center">
          <span
            className="filter-label"
            style={{ fontSize: "12px", fontWeight: "600" }}
          >
            {props.activeOption === "FILTERBY" ? "AND" : "OR"}
          </span>
        </Divider>
        <Panel
          className="mt-2 text-start"
          header={loading ? <LabelSkeleton /> : "BY PARTICIPANTS"}
          toggleable
          expandIcon="pi pi-angle-up"
          collapseIcon="pi pi-angle-down"
          collapsed={true}
        >
          <div className="flex flex-column gap-3 panel-height">
            {!loading ? (
              (props.activeOption === "COMPAREBY"
                ? participantsList.length > 0 && participantsList.length < 5
                  ? [
                      { participant_id: "ALL", participant_name: "ALL" },
                      ...participantsList,
                    ]
                  : participantsList
                : participantsList.length > 0
                  ? [
                      { participant_id: "ALL", participant_name: "ALL" },
                      ...participantsList,
                    ]
                  : []
              )?.map((participant) => {
                return (
                  <div
                    key={participant.participant_id}
                    className="flex align-items-center pt-2 pb-2"
                  >
                    <Checkbox
                      inputId={participant.participant_id}
                      name="category"
                      value={participant.participant_id}
                      checked={
                        participant.participant_id === "ALL"
                          ? isAllSelected(
                              props.selectedParticipants,
                              participantsList,
                            ) // Skip 'ALL'
                          : props.selectedParticipants.includes(
                              participant.participant_id,
                            )
                      }
                      onChange={handleParticipantChange}
                      disabled={isFilterDisabled(
                        "participant",
                        participant.participant_id,
                        props.selectedParticipants,
                      )} // Pass participant ID to isFilterDisabled
                    />
                    <label
                      htmlFor={participant.participant_id}
                      className="ml-2 ps-2"
                    >
                      {participant.participant_name}
                    </label>
                  </div>
                );
              })
            ) : (
              <FilterSkeleton />
            )}
          </div>
        </Panel>
        <Divider align="center">
          <span
            className="filter-label"
            style={{ fontSize: "12px", fontWeight: "600" }}
          >
            {props.activeOption === "FILTERBY" ? "AND" : "OR"}
          </span>
        </Divider>
        <Panel
          className="mt-2 text-start"
          header={loading ? <LabelSkeleton /> : "BY GENDER"}
          toggleable
          expandIcon="pi pi-angle-up"
          collapseIcon="pi pi-angle-down"
          collapsed={true}
        >
          <div className="flex flex-column gap-3 panel-height">
            {genderlist
              .filter(
                (gender) =>
                  !(
                    props.activeOption === "COMPAREBY" &&
                    genderlist.length > 5 &&
                    gender === "ALL"
                  ),
              )
              .map((gender, index) => {
                return (
                  <div
                    key={index} // Using index as the key
                    className="flex align-items-center pt-2 pb-2"
                  >
                    <Checkbox
                      inputId={gender} // Use the gender string as the input ID
                      name="category"
                      value={gender}
                      checked={
                        gender === "ALL"
                          ? isAllSelected(
                              props.selectedGenders,
                              genderlist.slice(1),
                            ) // Skip 'ALL'
                          : props.selectedGenders.includes(gender)
                      }
                      onChange={handleGenderChange}
                      disabled={isFilterDisabled(
                        "gender",
                        gender,
                        props.selectedGenders,
                      )}
                    />
                    <label htmlFor={gender} className="ml-2 ps-2">
                      {gender} {/* Display the gender string */}
                    </label>
                  </div>
                );
              })}
          </div>
        </Panel>
        <Divider align="center">
          <span
            className="filter-label"
            style={{ fontSize: "12px", fontWeight: "600" }}
          >
            {props.activeOption === "FILTERBY" ? "AND" : "OR"}
          </span>
        </Divider>
        <Panel
          className="mt-2 text-start"
          header={loading ? <LabelSkeleton /> : "BY SIZE"}
          toggleable
          expandIcon="pi pi-angle-up"
          collapseIcon="pi pi-angle-down"
          collapsed={true}
        >
          <div className="flex flex-column gap-3 panel-height">
            {!loading ? (
              (props.activeOption === "COMPAREBY"
                ? sizesList.length > 0 && sizesList.length < 5
                  ? ["ALL", ...sizesList.filter((size) => size !== "ALL")]
                  : sizesList.filter((size) => size !== "ALL")
                : sizesList.length > 0
                  ? ["ALL", ...sizesList]
                  : []
              )?.map((size, index) => {
                return (
                  <div
                    key={index}
                    className="flex align-items-center pt-2 pb-2"
                  >
                    <Checkbox
                      inputId={index}
                      name="category"
                      value={size}
                      checked={
                        size === "ALL"
                          ? isAllSelected(props.selectedSizes, sizesList) // Skip 'ALL'
                          : props.selectedSizes.includes(size)
                      }
                      onChange={handleSizeChange}
                      disabled={isFilterDisabled(
                        "size",
                        size,
                        props.selectedSizes,
                      )}
                    />
                    <label htmlFor={index} className="ml-2 ps-2">
                      {size}
                    </label>
                  </div>
                );
              })
            ) : (
              <FilterSkeleton />
            )}
          </div>
        </Panel>
        <Divider align="center">
          <span
            className="filter-label"
            style={{ fontSize: "12px", fontWeight: "600" }}
          >
            {props.activeOption === "FILTERBY" ? "AND" : "OR"}
          </span>
        </Divider>
        <Panel
          className="mt-2 text-start"
          header={loading ? <LabelSkeleton /> : "BY SEGMENT"}
          toggleable
          expandIcon="pi pi-angle-up"
          collapseIcon="pi pi-angle-down"
          collapsed={true}
        >
          <div className="flex flex-column gap-3 panel-height">
            {!loading ? (
              (props.activeOption === "COMPAREBY"
                ? segmentsList.length > 0 && segmentsList.length < 5
                  ? [
                      "ALL",
                      ...segmentsList.filter((segment) => segment !== "ALL"),
                    ]
                  : segmentsList.filter((segment) => segment !== "ALL")
                : segmentsList.length > 0
                  ? ["ALL", ...segmentsList]
                  : []
              )?.map((segment, index) => {
                return (
                  <div
                    key={index}
                    className="flex align-items-center pt-2 pb-2"
                  >
                    <Checkbox
                      inputId={index}
                      name="category"
                      value={segment}
                      checked={
                        segment === "ALL"
                          ? isAllSelected(props.selectedSegment, segmentsList) // Skip 'ALL'
                          : props.selectedSegment.includes(segment)
                      }
                      onChange={handleSegmentChange}
                      disabled={isFilterDisabled(
                        "segment",
                        segment,
                        props.selectedSegment,
                      )}
                    />
                    <label htmlFor={index} className="ml-2 ps-2">
                      {segment}
                    </label>
                  </div>
                );
              })
            ) : (
              <FilterSkeleton />
            )}
          </div>
        </Panel>
        <Divider align="center"></Divider>
        <div className="flex align-items-center gap-2 managed-row">
          <Button
            label="RESET"
            className="p-button-text me-2 resetButton br-c fw-600"
            style={{ width: "120px", color: "gray" }}
          ></Button>
          <Button
            label="APPLY"
            className="p-button-text me-2  active-button br-c fw-600"
            style={{ width: "120px" }}
            onClick={props.setApplyFilter(true)}
          ></Button>
        </div>
      </div>
    </>
  );
});

export default FilterBy;
