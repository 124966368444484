import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";

const BrandStickiness = () => {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    // Static color values
    const textColor = "#495057"; // Dark text color
    const textColorSecondary = "#6c757d"; // Secondary text color
    const surfaceBorder = "#ebedef"; // Light gray color for borders

    // Define chart data
    const data = {
      labels: ["TIDE", "ALL", "GAIN"],
      datasets: [
        {
          type: "bar",
          label: "1",
          backgroundColor: "#AB46BC", // Blue color
          data: [23, 8, 19],
        },
        {
          type: "bar",
          label: "2",
          backgroundColor: "#EE534F", // Green color
          data: [28, 14, 21],
        },
        {
          type: "bar",
          label: "3",
          backgroundColor: "#2196F3", // Yellow color
          data: [10, 52, 43],
        },
        {
          type: "bar",
          label: "4+",
          backgroundColor: "#F9971C", // Yellow color
          data: [39, 26, 17],
        },
      ],
    };

    // Define chart options
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        tooltip: {
          mode: "index",
          intersect: false,
        },
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
        y: {
          stacked: true,
        },
      },
    };

    // Update chart data and options
    setChartData(data);
    setChartOptions(options);
  }, []);

  return (
    <>
      <div className="fs-14 text-start mt-4 mb-4">
        <strong>BRAND STICKINESS - AVERAGE NUMBER OF BRAND USED</strong>
        <div
          className="secondary-text small fw-600 mt-4"
          style={{ color: "#BDBDBD" }}
        >
          % USERS
        </div>
      </div>
      <div className="col-12">
        <Chart type="bar" data={chartData} options={chartOptions} />
      </div>
    </>
  );
};
export default BrandStickiness;
