import React from "react";
import { Chart } from "primereact/chart";

const LoyalUsersToOneBrand = () => {
  const data = {
    labels: ["CATEGORY", "TIDE", "GAIN"],
    datasets: [
      {
        label: "CATEGORY",
        data: [30, 0, 0], // Data only for CATEGORY
        backgroundColor: "orange",
      },
      {
        label: "TIDE",
        data: [0, 45, 0], // Data only for TIDE
        backgroundColor: "blue",
      },
      {
        label: "GAIN",
        data: [0, 0, 20], // Data only for GAIN
        backgroundColor: "#EE534F",
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true, // Show the legend with dataset labels
        position: "top", // Position of the legend
      },
      datalabels: {
        color: "black",
        anchor: "end",
        align: "top",
        font: {
          weight: "bold",
          size: 12,
        },
        formatter: (value) => (value !== 0 ? value : ""),
      },
    },
    scales: {
      x: {
        stacked: true, // Stack the bars horizontally
      },
      y: {
        beginAtZero: true,
        suggestedMax: 55, // Adjust the maximum value as needed
      },
    },
  };

  return (
    <>
      <div className="fs-14 text-start mt-4 mb-4">
        <strong>% USERS LOYAL TO ONE BRAND</strong>
      </div>
      <div className="col-12">
        <Chart type="bar" data={data} options={options} />
      </div>
    </>
  );
};

export default LoyalUsersToOneBrand;
