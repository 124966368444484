export const handleSelectAll = (currentSelections, options) => {
  debugger;
  if (currentSelections.length === options.length) {
    // Deselect all
    return [];
  } else {
    // Select all; no longer using option.key since we're just dealing with strings
    return [...options]; // Just return the options directly
  }
};

export const handleSelectOption = (currentSelections, optionKey) => {
  if (currentSelections.includes(optionKey)) {
    // Remove selected option
    return currentSelections.filter((key) => key !== optionKey);
  } else {
    // Add selected option
    return [...currentSelections, optionKey];
  }
};

export const isAllSelected = (currentSelections, options) => {
  return currentSelections.length === options.length;
};
