import React, { useState, useEffect } from "react";
import { Menubar } from "primereact/menubar";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGlobalContext } from "../../context/GlobalContext";
import Cookies from "js-cookie";
import "../../assets/css/dashboard.css";
import "../../assets/css/primecustomdashboard.css";
import Header from "../../components/header/Header";
import tb_logo from "../../assets/images/tb_logo.png";
import Reports from "../reports/Reports";

const Layout = ({ renderReports }) => {
  const {
    setDevice,
    setDeviceCode,
    setCategory,
    setProductId,
    device,
    deviceCode,
    category,
    product_id,
  } = useGlobalContext();

  const categoryData = useSelector((state) => state.auth.category_data || []);

  const [selectedPath, setSelectedPath] = useState(() => {
    const cookiePath = Cookies.get("selectedMenuPath");

    // Check if categoryData exists and has more than 0 elements
    if (categoryData.length > 0) {
      const newPath = `/category/${categoryData[0].id}`;

      // Save newPath to cookies with a 7-day expiration
      Cookies.set("selectedMenuPath", newPath, { expires: 7 });
      setDevice(categoryData[0].device);
      setDeviceCode(categoryData[0].device_code);
      setCategory(categoryData[0].category);
      setProductId(categoryData[0].tag_or_coaster_category_id);
      // Return the newly set path
      return newPath;
    } else {
      // If no categoryData, fall back to dashboard or existing cookiePath
      return cookiePath || "/dashboard";
    }
  });

  const handleMenuItemClick = (
    newPath,
    device,
    deviceCode,
    category,
    product_id,
  ) => {
    setSelectedPath(newPath);
    setDevice(device);
    setDeviceCode(deviceCode);
    setCategory(category);
    setProductId(product_id);
    Cookies.set("selectedMenuPath", newPath, { expires: 7 });
  };

  const menuItems = [
    ...categoryData.map((category) => ({
      label: category.category,
      command: () =>
        handleMenuItemClick(
          `/category/${category.id}`,
          category.device,
          category.device_code,
          category.category,
          category.tag_or_coaster_category_id,
        ),
      className:
        selectedPath === `/category/${category.id}` ? "p-highlighter" : "",
    })),
  ];

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const highlightedItem = document.querySelector(
        ".p-menubar-root-list li.p-highlighter",
      );
      if (highlightedItem) {
        highlightedItem.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    });

    const menuContainer = document.querySelector(".p-menubar-root-list");

    if (menuContainer) {
      observer.observe(menuContainer, {
        childList: true,
        subtree: true,
      });
    }

    return () => observer.disconnect(); // Cleanup observer on unmount
  }, [selectedPath, categoryData]);

  return (
    <div className="d-flex vh-100">
      <div className="position-fixed left-sidebar">
        <img src={tb_logo} className="image-fluid logo_dash" alt="Logo" />
        <Menubar model={menuItems} className="custom-menubar" />
      </div>
      <div className="flex-grow-1 d-flex flex-column main-content">
        <Header renderReports={renderReports} />
        {renderReports ? (
          <Reports
            device={device}
            deviceCode={deviceCode}
            category={category}
            product_id={product_id}
          />
        ) : (
          <Outlet />
        )}
      </div>
    </div>
  );
};

export default Layout;
