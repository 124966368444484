import React, { useState, useEffect } from "react";
import FilterByForDashboard from "../FilterByForDashboard";
import DataAndQuantityType from "./experienceComponents/DataAndQuantityType";
import OverallSatisfaction from "./experienceComponents/OverallSatisfaction";
import RepeatPurchaseIntent from "./experienceComponents/RepeatPurchaseIntent";
import PerformanceVsExpectations from "./experienceComponents/PerformanceVsExpectations";
import ValueForMoney from "./experienceComponents/ValueForMoney";
import Uniqueness from "./experienceComponents/Uniqueness";
import Likes from "./experienceComponents/Likes";
import Dislikes from "./experienceComponents/Dislikes";
import { useGlobalContext } from "../../../context/GlobalContext";
import { useSelector } from "react-redux";
import { PROJECT_ID } from "../../../utils/constants";
import { getOverAllSatisfactionChartData } from "../../../services/dashboard/experience";

const Experience = (props) => {
  const { device, deviceCode, category, product_id } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [productType, setProductType] = useState("");
  const [activeOption, setActiveOption] = useState("FILTERBY");
  const [question_id, setQuestionId] = useState("");
  const { token } = useSelector((state) => state.auth);
  const [overAllSatisfactionChartData, setOverAllSatisfactionChartData] =
    useState([]);
  const [chartQuestion, setChartQuestion] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedOccasions, setSelectedOccasions] = useState([]);
  const [selectedHouseHoldAge, setSelectedHouseHoldAge] = useState([]);
  const [selectedHouseHoldIncome, setSelectedHouseHoldIncome] = useState([]);
  const [selectedHouseHoldSize, setSelectedHouseHoldSize] = useState([]);
  const [selectedHouseHoldChildren, setSelectedHouseHoldChildren] = useState(
    [],
  );
  const [selectedHouseHoldRaceEthnicity, setSelectedHouseHoldRaceEthnicity] =
    useState([]);
  const [activeDataTypeButton, setActiveDataTypeButton] = useState("Full");
  const [selectedForms, setSelectedForms] = useState([]);
  const [selectedDayPart, setSelectedDayPart] = useState([]);
  const [selectedWeekPart, setSelectedWeekPart] = useState([]);
  const [selectedTimeOfDay, setSelectedTimeOfDay] = useState([]);
  const [apply, setApply] = useState(false);

  const fetchExperienceChartData = () => {
    setLoading(true);
    const requestBody = {
      compare_with: props?.selectedComparitionBy,
      compare_by: props?.compareBy,
      box_type: activeDataTypeButton,
      filter_form: "",
      filter_househol: "",
      filter_household_type: "",
      filter_occasion: "",
      occasion_question_id: parseInt(question_id),
      product_id: parseInt(product_id),
      product_type: productType,
      project_id: parseInt(PROJECT_ID),
      question_id: 0,
      section_type: props?.isCompareByClicked ? "compare" : "filter",
    };

    Promise.all([getOverAllSatisfactionChartData(requestBody, token)])
      .then(([overallSatisfactionResponse]) => {
        setOverAllSatisfactionChartData(
          overallSatisfactionResponse.data.graphs,
        );
        setChartQuestion(overallSatisfactionResponse.data.question);
      })
      .catch((error) => {
        console.error("Error fetching chart data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (productType !== "") {
      fetchExperienceChartData();
    }
  }, [productType, props?.apply, activeDataTypeButton]);

  const handleReset = () => {
    setSelectedDayPart([]);
    setSelectedWeekPart([]);
    setSelectedBrands([]);
    setSelectedOccasions([]);
    setSelectedFilters([]);
    setSelectedForms([]);
    setApply(!apply);
  }

  return (
    <div className="row">
      {/* Filter By Component */}
      <FilterByForDashboard
        loading={loading}
        setLoading={setLoading}
        device={device}
        deviceCode={deviceCode}
        category={category}
        product_id={product_id}
        productType={productType}
        setProductType={setProductType}
        activeOption={activeOption}
        setActiveOption={setActiveOption}
        question_id={question_id}
        setQuestionId={setQuestionId}
        selectedForms={selectedForms}
        setSelectedForms={setSelectedForms}
        selectedBrands={selectedBrands}
        setSelectedBrands={setSelectedBrands}
        selectedGenders={selectedGenders}
        setSelectedGenders={setSelectedGenders}
        selectedOccasions={selectedOccasions}
        setSelectedOccasions={setSelectedOccasions}
        selectedHouseHoldAge={selectedHouseHoldAge}
        setSelectedHouseHoldAge={setSelectedHouseHoldAge}
        selectedHouseHoldIncome={selectedHouseHoldIncome}
        setSelectedHouseHoldIncome={setSelectedHouseHoldIncome}
        selectedHouseHoldSize={selectedHouseHoldSize}
        setSelectedHouseHoldSize={setSelectedHouseHoldSize}
        selectedHouseHoldChildren={selectedHouseHoldChildren}
        setSelectedHouseHoldChildren={setSelectedHouseHoldChildren}
        selectedHouseHoldRaceEthnicity={selectedHouseHoldRaceEthnicity}
        setSelectedHouseHoldRaceEthnicity={setSelectedHouseHoldRaceEthnicity}
        selectedDayPart={selectedDayPart}
        setSelectedDayPart={setSelectedDayPart}
        selectedWeekPart={selectedWeekPart}
        setSelectedWeekPart={setSelectedWeekPart}
        selectedTimeOfDay={selectedTimeOfDay}
        setSelectedTimeOfDay={setSelectedTimeOfDay}
        apply={apply}
        setApply={setApply}
        isCompareByClicked={props?.isCompareByClicked}
        setIsCompareByClicked={props?.setIsCompareByClicked}
        setSelectedFilters={setSelectedFilters}
        selectedFilters={selectedFilters}
        handleReset={handleReset}
      />
      <div className="col-9" style={{ padding: "0px 5%" }}>
        <DataAndQuantityType
          activeDataTypeButton={activeDataTypeButton}
          setActiveDataTypeButton={setActiveDataTypeButton}
        />
        <OverallSatisfaction
          overAllSatisfactionChartData={overAllSatisfactionChartData}
          loading={loading}
          activeDataTypeButton={activeDataTypeButton}
          chartQuestion={chartQuestion}
        />
        <RepeatPurchaseIntent />
        <PerformanceVsExpectations />
        <ValueForMoney />
        <Uniqueness />
        <Likes />
        <Dislikes />
      </div>
    </div>
  );
};
export default Experience;
