import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { Panel } from "primereact/panel";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import {
  getProductType,
  getBrandsFormsAndSize,
  getParticipantsList,
  getSegmentsList,
} from "../../services/reports/reports";
import { PROJECT_ID } from "../../utils/constants";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import handleError from "../../utils/handleError";
import {
  handleSelectAll,
  handleSelectOption,
  isAllSelected,
} from "../../utils/filterBy/filterBy";
import FilterSkeleton from "../Skeletons/FilterSkeleton";
import LabelSkeleton from "../Skeletons/LabelSkeleton";
const MAX_SELECTION = 5;
const FilterBy = React.memo(function FilterBy(props) {
  const [activeOption, setActiveOption] = useState("FILTERBY");
  const [activeFilterCategory, setActiveFilterCategory] = useState(null);
  const [productType, setProductType] = useState(null);
  const [brandsList, setBrandsList] = useState([]);
  const [formsList, setFormsList] = useState([]);
  const [sizesList, setSizesList] = useState([]);
  const [participantsList, setParticipantsList] = useState([]);
  const [segmentsList, setSegmentsList] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchProductType = () => {
    setLoading(true);
    setError(null);
    const requestBody = {
      product_id: parseInt(props.product_id),
      project_id: parseInt(PROJECT_ID),
    };
    getProductType(requestBody, token)
      .then(async (response) => {
        if (response.status === 200) {
          setProductType(response.data.type);
          const request_body = {
            product_id: parseInt(props.product_id),
            product_type: response.data.type,
            project_id: parseInt(PROJECT_ID),
          };
          const [
            brandsFormsAndSizeResponse,
            participantsListResponse,
            segmentsListResponse,
          ] = await Promise.all([
            getBrandsFormsAndSize(request_body, token),
            getParticipantsList(request_body, token),
            getSegmentsList(request_body, token),
          ]);
          setBrandsList(brandsFormsAndSizeResponse.data.barcode_details.brands);
          setSizesList(brandsFormsAndSizeResponse.data.barcode_details.sizes);
          setFormsList(brandsFormsAndSizeResponse.data.barcode_details.forms);
          setParticipantsList(participantsListResponse.data.participants_data);
          setSegmentsList(segmentsListResponse.data.segments);
        } else {
          setError("Failed to fetch product types. Unexpected status code.");
        }
      })
      .catch((error) => {
        handleError(error, navigate, dispatch);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // Fetch product types when the component mounts
    fetchProductType();
  }, [props]);

  useEffect(() => {
    console.log(selectedGenders);
  }, [selectedGenders]);

  const genderlist = ["ALL", "MALE", "FEMALE", "NON-BINARY"];
  const handleBrandChange = (e) => {
    const { value } = e.target; // No longer using checked, since it can be inferred
    let updatedSelectedBrands;
    if (value === "ALL") {
      // Updated to check value directly
      updatedSelectedBrands = handleSelectAll(selectedBrands, brandsList);
    } else {
      updatedSelectedBrands = handleSelectOption(selectedBrands, value); // value is now just the string
    }
    setSelectedBrands(updatedSelectedBrands);
    // If everything is unchecked, reset the active filter category
    if (updatedSelectedBrands.length === 0) {
      setActiveFilterCategory(null);
    } else if (activeOption === "COMPAREBY") {
      setActiveFilterCategory("brand");
    }
  };
  const handleGenderChange = (e) => {
    const { value } = e.target; // No longer using checked, since it can be inferred
    let updatedSelectedGenders;
    if (value === "ALL") {
      // Updated to check value directly
      updatedSelectedGenders = handleSelectAll(
        selectedGenders,
        genderlist.slice(1),
      );
    } else {
      updatedSelectedGenders = handleSelectOption(selectedGenders, value); // value is now just the string
    }
    setSelectedGenders(updatedSelectedGenders);
    // If everything is unchecked, reset the active filter category
    if (updatedSelectedGenders.length === 0) {
      setActiveFilterCategory(null);
    } else if (activeOption === "COMPAREBY") {
      setActiveFilterCategory("gender");
    }
  };

  const isMaxSelectionReached = (category, selectedOption) => {
    return (
      activeFilterCategory === category &&
      selectedOption.length === MAX_SELECTION
    );
  };
  const handleFilterAndCompare = (option) => {
    setActiveOption(option);
  };
  const isFilterDisabled = (category, optionKey, selectedOption) => {
    // Disable if we are in COMPAREBY mode and not in the activeFilterCategory
    if (activeFilterCategory && activeFilterCategory !== category) {
      return true; // Disable entire category if it's not the active one
    }

    // Disable unchecked options if max selection reached for the current active category
    return (
      isMaxSelectionReached(category, selectedOption) &&
      !selectedOption.includes(optionKey)
    );
  };

  return (
    <>
      <div className="col-3" style={{ borderRight: "1px solid #dee2e6" }}>
        <div className="filter-header-row">
          <div className="col-6">
            <Button
              className={`p-button-text me-2  ${activeOption === "FILTERBY" ? "active-button" : "inactive-button"} br-c`}
              outlined
              onClick={() => handleFilterAndCompare("FILTERBY")}
              label="FILTER BY"
              style={{ width: "130px" }}
            />
          </div>
          <div className="col-6">
            <Button
              className={`p-button-text me-2 ${activeOption === "COMPAREBY" ? "active-button" : "inactive-button"} br-c`}
              outlined
              onClick={() => handleFilterAndCompare("COMPAREBY")}
              label="COMPARE BY"
              style={{ width: "130px" }}
            />
          </div>
        </div>
        <Panel className="mt-2 text-start">
          <div className="flex flex-column gap-3 text-start mt-3 panel-height">
            <div
              key={productType}
              className="flex align-items-center pt-2 pb-2"
            >
              {!loading ? (
                <>
                  <RadioButton
                    inputId={productType}
                    name="category"
                    value={productType}
                    checked
                  />
                  <label htmlFor={productType} className="ps-2">
                    {productType?.toUpperCase()}
                  </label>
                </>
              ) : (
                <LabelSkeleton />
              )}
            </div>
          </div>
        </Panel>
        <Divider align="center">
          <span
            className="filter-label"
            style={{ fontSize: "12px", fontWeight: "600" }}
          >
            {activeOption === "FILTERBY" ? "AND" : "OR"}
          </span>
        </Divider>
        <Panel
          className="mt-2 text-start"
          header={loading ? <LabelSkeleton /> : "BY BRAND"}
          toggleable
          expandIcon="pi pi-angle-up"
          collapseIcon="pi pi-angle-down"
          collapsed={true}
        >
          <div className="flex flex-column gap-3 panel-height">
            {!loading ? (
              (activeOption === "COMPAREBY"
                ? brandsList.filter((brand) => brand !== "ALL")
                : ["ALL", ...brandsList]
              )?.map((brand, index) => {
                return (
                  <div
                    key={index}
                    className="d-flex align-items-center pt-2 pb-2"
                    style={{ justifyContent: "flex-start" }}
                  >
                    <Checkbox
                      inputId={index}
                      name="category"
                      value={brand}
                      checked={
                        brand === "ALL"
                          ? isAllSelected(selectedBrands, brandsList) // Skip 'ALL'
                          : selectedBrands.includes(brand)
                      }
                      onChange={handleBrandChange}
                      disabled={isFilterDisabled(
                        "brand",
                        brand,
                        selectedBrands,
                      )}
                    />
                    <label
                      htmlFor={index}
                      className="ml-2 ps-2"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      title={brand}
                    >
                      {brand}
                    </label>
                  </div>
                );
              })
            ) : (
              <FilterSkeleton />
            )}
          </div>
        </Panel>
        <Divider align="center">
          <span
            className="filter-label"
            style={{ fontSize: "12px", fontWeight: "600" }}
          >
            {activeOption === "FILTERBY" ? "AND" : "OR"}
          </span>
        </Divider>
        <Panel
          className="mt-2 text-start"
          header={loading ? <LabelSkeleton /> : "BY FORM"}
          toggleable
          expandIcon="pi pi-angle-up"
          collapseIcon="pi pi-angle-down"
          collapsed={true}
        >
          <div className="flex flex-column gap-3 panel-height">
            {!loading ? (
              (activeOption === "COMPAREBY"
                ? formsList.filter((brand) => brand !== "ALL")
                : ["ALL", ...formsList]
              )?.map((form, index) => {
                return (
                  <div
                    key={index}
                    className="flex align-items-center pt-2 pb-2"
                  >
                    <Checkbox
                      inputId={index}
                      name="category"
                      value={form}
                      disabled={isFilterDisabled("form")}
                    />
                    <label htmlFor={index} className="ml-2 ps-2">
                      {form}
                    </label>
                  </div>
                );
              })
            ) : (
              <FilterSkeleton />
            )}
          </div>
        </Panel>
        <Divider align="center">
          <span
            className="filter-label"
            style={{ fontSize: "12px", fontWeight: "600" }}
          >
            {activeOption === "FILTERBY" ? "AND" : "OR"}
          </span>
        </Divider>
        <Panel
          className="mt-2 text-start"
          header={loading ? <LabelSkeleton /> : "BY PARTICIPANTS"}
          toggleable
          expandIcon="pi pi-angle-up"
          collapseIcon="pi pi-angle-down"
          collapsed={true}
        >
          <div className="flex flex-column gap-3 panel-height">
            {!loading ? (
              (activeOption === "COMPAREBY"
                ? participantsList
                : [
                    { participant_id: "all", participant_name: "All" },
                    ...participantsList,
                  ]
              )?.map((participant) => {
                return (
                  <div
                    key={participant.participant_id}
                    className="flex align-items-center pt-2 pb-2"
                  >
                    <Checkbox
                      inputId={participant.participant_id}
                      name="category"
                      value={participant.participant_id}
                      disabled={isFilterDisabled(
                        "participant",
                        participant.participant_id,
                      )} // Pass participant ID to isFilterDisabled
                    />
                    <label
                      htmlFor={participant.participant_id}
                      className="ml-2 ps-2"
                    >
                      {participant.participant_name}
                    </label>
                  </div>
                );
              })
            ) : (
              <FilterSkeleton />
            )}
          </div>
        </Panel>
        <Divider align="center">
          <span
            className="filter-label"
            style={{ fontSize: "12px", fontWeight: "600" }}
          >
            {activeOption === "FILTERBY" ? "AND" : "OR"}
          </span>
        </Divider>
        <Panel
          className="mt-2 text-start"
          header={loading ? <LabelSkeleton /> : "BY GENDER"}
          toggleable
          expandIcon="pi pi-angle-up"
          collapseIcon="pi pi-angle-down"
          collapsed={true}
        >
          <div className="flex flex-column gap-3 panel-height">
            {genderlist
              .filter(
                (gender) => !(activeOption === "COMPAREBY" && gender === "ALL"),
              )
              .map((gender, index) => {
                return (
                  <div
                    key={index} // Using index as the key
                    className="flex align-items-center pt-2 pb-2"
                  >
                    <Checkbox
                      inputId={gender} // Use the gender string as the input ID
                      name="category"
                      value={gender}
                      checked={
                        gender === "ALL"
                          ? isAllSelected(selectedGenders, genderlist.slice(1)) // Skip 'ALL'
                          : selectedGenders.includes(gender)
                      }
                      onChange={handleGenderChange}
                      disabled={isFilterDisabled(
                        "gender",
                        gender,
                        selectedGenders,
                      )}
                    />
                    <label htmlFor={gender} className="ml-2 ps-2">
                      {gender} {/* Display the gender string */}
                    </label>
                  </div>
                );
              })}
          </div>
        </Panel>
        <Divider align="center">
          <span
            className="filter-label"
            style={{ fontSize: "12px", fontWeight: "600" }}
          >
            {activeOption === "FILTERBY" ? "AND" : "OR"}
          </span>
        </Divider>
        <Panel
          className="mt-2 text-start"
          header={loading ? <LabelSkeleton /> : "BY SIZE"}
          toggleable
          expandIcon="pi pi-angle-up"
          collapseIcon="pi pi-angle-down"
          collapsed={true}
        >
          <div className="flex flex-column gap-3 panel-height">
            {!loading ? (
              (activeOption === "COMPAREBY"
                ? sizesList.filter((brand) => brand !== "ALL")
                : ["ALL", ...sizesList]
              )?.map((size, index) => {
                return (
                  <div
                    key={index}
                    className="flex align-items-center pt-2 pb-2"
                  >
                    <Checkbox
                      inputId={index}
                      name="category"
                      value={size}
                      disabled={isFilterDisabled("category")}
                    />
                    <label htmlFor={index} className="ml-2 ps-2">
                      {size}
                    </label>
                  </div>
                );
              })
            ) : (
              <FilterSkeleton />
            )}
          </div>
        </Panel>
        <Divider align="center">
          <span
            className="filter-label"
            style={{ fontSize: "12px", fontWeight: "600" }}
          >
            {activeOption === "FILTERBY" ? "AND" : "OR"}
          </span>
        </Divider>
        <Panel
          className="mt-2 text-start"
          header={loading ? <LabelSkeleton /> : "BY SEGMENT"}
          toggleable
          expandIcon="pi pi-angle-up"
          collapseIcon="pi pi-angle-down"
          collapsed={true}
        >
          <div className="flex flex-column gap-3 panel-height">
            {!loading ? (
              (activeOption === "COMPAREBY"
                ? segmentsList.filter((brand) => brand !== "ALL")
                : ["ALL", ...segmentsList]
              )?.map((segment, index) => {
                return (
                  <div
                    key={index}
                    className="flex align-items-center pt-2 pb-2"
                  >
                    <Checkbox
                      inputId={index}
                      name="category"
                      value={segment}
                      disabled={isFilterDisabled("segment")}
                    />
                    <label htmlFor={index} className="ml-2 ps-2">
                      {segment}
                    </label>
                  </div>
                );
              })
            ) : (
              <FilterSkeleton />
            )}
          </div>
        </Panel>
        <Divider align="center"></Divider>
        <div className="flex align-items-center gap-2 managed-row">
          <Button
            label="RESET"
            className="p-button-text me-2 resetButton br-c fw-600"
            style={{ width: "120px", color: "gray" }}
          ></Button>
          <Button
            label="APPLY"
            className="p-button-text me-2  active-button br-c fw-600"
            style={{ width: "120px" }}
          ></Button>
        </div>
      </div>
    </>
  );
});

export default FilterBy;
