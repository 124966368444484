const BASE_URLS = {
  auth: "https://authmodule-dev.panel.qualsights.com",
  reports: "https://reportsmodule-dev.panel.qualsights.com",
};

// Utility function to get the correct base URL based on the module type
export const getApiBaseUrl = (module) => {
  switch (module) {
    case "auth":
      return BASE_URLS.auth;
    case "reports":
      return BASE_URLS.reports;
    default:
      throw new Error(`Unknown module: ${module}`);
  }
};
