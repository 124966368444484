import { LOGOUT } from "./constants";
const handleError = (error, navigate, dispatch) => {
  debugger;
  if (
    error.response &&
    error.response.status === 400 &&
    error.response.data?.msg === "Invalid Token"
  ) {
    // Redirect to the login page
    dispatch({
      type: LOGOUT,
      payload: {},
    });
    navigate("/login");
  } else {
    // Log or handle other errors
    console.error("Error occurred:", error);
  }
};

export default handleError;
