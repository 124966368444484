import React, { useState } from "react";
import { Chart } from "primereact/chart";
import { Button } from "primereact/button";
import ChartDivider from "../../ChartDivider";
import ChartSkeleton from "../../../Skeletons/ChartSkeleton";
import { getDayOfWeekChartOptions } from "../../../../utils/chartOptions/dashboard/amount";
const DayOfWeek = React.memo(function DayOfWeek(props) {
  const [activeButton, setActiveButton] = useState("# OF USES");
  const options = getDayOfWeekChartOptions(
    props?.chartData,
    props?.activeButtonDayOfWeek,
  );

  const handleClick = (label) => props?.setActiveButtonDayOfWeek(label);

  return (
    <div className="mt-4" style={{ maxWidth: "100%" }}>
      <div className="mb-4">
        <h5 className="text-uppercase fw-bold mb-1 text-start">
          <span>Day of Week</span>
          <div
            className="d-flex align-items-center gap-2"
            style={{ float: "right" }}
          >
            <Button
              label="% OF TOTAL"
              className={`px-3 py-2 fw-bold fs-6 ${
                props?.activeButtonDayOfWeek === "% OF TOTAL"
                  ? "btn-primary text-white"
                  : "btn-light text-dark"
              }`}
              onClick={() => handleClick("% OF TOTAL")}
              style={{
                borderRadius: "10px",
                border: "none",
                height: "25px",
                backgroundColor:
                  props?.activeButtonDayOfWeek === "% OF TOTAL"
                    ? "#002D42"
                    : "#E0E0E0",
              }}
            />
            <Button
              label="# OF USES"
              className={`px-3 py-2 fw-bold fs-6 ${
                props?.activeButtonDayOfWeek === "# OF USES"
                  ? "btn-primary text-white"
                  : "btn-light text-dark"
              }`}
              onClick={() => handleClick("# OF USES")}
              style={{
                borderRadius: "10px",
                border: "none",
                height: "25px",
                backgroundColor:
                  props?.activeButtonDayOfWeek === "# OF USES"
                    ? "#002D42"
                    : "#E0E0E0",
              }}
            />
          </div>
        </h5>
        <p
          className="text-uppercase mb-0 text-start fw-bold fs-12 py-3"
          style={{ color: "#6c757d" }}
        >
          GRAMS PER USE (Average)
        </p>
        <div className="col-12">
          {!props?.dayOfWeekLoader ? (
            <Chart type="bar" data={props?.chartData} options={options} />
          ) : (
            <ChartSkeleton />
          )}
        </div>
        <ChartDivider />
      </div>
    </div>
  );
});

export default DayOfWeek;
