import React, { useRef, useEffect } from "react";
import { Chart } from "primereact/chart";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Import the plugin
import { Chart as ChartJS, registerables } from "chart.js";

ChartJS.register(...registerables); // Register Chart.js components
ChartJS.register(ChartDataLabels); // Register the datalabels plugin

const AverageAmountPerUse = () => {
  const data = {
    labels: ["CATEGORY", "TIDE", "GAIN"],
    datasets: [
      {
        label: "CATEGORY (57)",
        data: [240, 120, 130], // Data only for CATEGORY
        backgroundColor: "orange",
      },
      {
        label: "TIDE (40)",
        data: [90, 325, 10], // Data only for TIDE
        backgroundColor: "blue",
      },
      {
        label: "GAIN (17)*",
        data: [90, 80, 502], // Data only for GAIN
        backgroundColor: "#EE534F",
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      datalabels: {
        color: "black",
        anchor: "end",
        align: "top",
        font: {
          weight: "bold",
          size: 12,
        },
        formatter: (value) => (value !== 0 ? value : ""),
      },
    },
    scales: {
      x: {
        stacked: false,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      <div className="fs-14 text-start mt-3 mb-3">
        <strong>HOW MUCH - AVERAGE AMOUNT PER USE (OZ)</strong>
      </div>
      <div className="col-12">
        <Chart type="bar" data={data} options={options} />
      </div>
    </>
  );
};

export default AverageAmountPerUse;
