import React from "react";
import { Chart } from "primereact/chart";
import ChartDivider from "../../ChartDivider";
import ChartSkeleton from "../../../Skeletons/ChartSkeleton";
import { getWeeklyChartOptions } from "../../../../utils/chartOptions/dashboard/frequency";

const UsesPerWeek = React.memo(function UsesPerWeek(props) {
  const options = getWeeklyChartOptions(props?.usePerWeekGraph);
  return (
    <>
      <div className="mt-4" style={{ maxWidth: "100%" }}>
        <div className="mb-4">
          <h5 className="text-uppercase fw-bold mb-1 text-start">
            Uses per week
          </h5>
          <p
            className="text-uppercase mb-0 text-start fw-bold fs-12 py-3"
            style={{ color: "#6c757d" }}
          >
            Average
          </p>
          <div className="col-12">
            {!props?.loading ? (
              <Chart
                type="bar"
                data={props?.usePerWeekGraph}
                options={options}
              />
            ) : (
              <ChartSkeleton />
            )}
          </div>
          <ChartDivider />
        </div>
      </div>
    </>
  );
});

export default UsesPerWeek;
