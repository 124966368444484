import React from "react";
import { Chart } from "primereact/chart";
import ChartDivider from "../../ChartDivider";
import ChartSkeleton from "../../../Skeletons/ChartSkeleton";
import { getUserSummaryChartOptions } from "../../../../utils/chartOptions/dashboard/usersummary";
const Household = React.memo(function Household({
  title,
  data,
  loading,
  length,
  index,
}) {
  const options = getUserSummaryChartOptions(data);
  return (
    <div className="mt-4" style={{ maxWidth: "100%" }}>
      <div className="mb-4">
        <h5 className="text-uppercase fw-bold mb-1 text-start">{title}</h5>
        <p
          className="text-uppercase mb-0 text-start fw-bold fs-12 py-3"
          style={{ color: "#6c757d" }}
        >
          % of uses
        </p>
        <div className="col-12 pb-4">
          {!loading ? (
            <Chart type="bar" data={data} options={options} unstyled={true} />
          ) : (
            <ChartSkeleton />
          )}
        </div>
        {index !== length - 1 && <ChartDivider />}
      </div>
    </div>
  );
});

export default Household;
