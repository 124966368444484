const BASE_URLS = {
  auth: "https://authmodule-dev.panel.qualsights.com",
  reports: "https://reportsmodule-dev.panel.qualsights.com",
  coaster: "https://coasterchart-dev.panel.qualsights.com",
};

export const getApiBaseUrl = (module) => {
  switch (module) {
    case "auth":
      return BASE_URLS.auth;
    case "reports":
      return BASE_URLS.reports;
    case "coaster":
      return BASE_URLS.coaster;
    default:
      throw new Error(`Unknown module: ${module}`);
  }
};
