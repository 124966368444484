import React, { useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const TopReasonForSwitchBrand = () => {
  const data = [
    { categoryName: "SCENT", category: 34, tide: 23, gain: 44 },
    { categoryName: "PRICE", category: 30, tide: 42, gain: 30 },
    { categoryName: "BIGGER SIZE", category: 15, tide: 3, gain: 10 },
    { categoryName: "LESS MESSY", category: 10, tide: 18, gain: 9 },
    { categoryName: "SMALLER SIZE", category: 10, tide: 18, gain: 9 },
    { categoryName: "EFFICASY", category: 10, tide: 18, gain: 9 },
  ];

  // Function to determine cell styles based on value and field
  const cellStyle = (value, field) => {
    if (field === "category" || field === "tide" || field === "gain") {
      return {
        backgroundColor: value <= 10 ? "#28a745" : "#BDBDBD", // Green for values > 20, Gray for others
        color: "black", // Ensure text color is black for readability
      };
    }
    return {};
  };

  // Function to apply styles to <td> and <div> after rendering
  const applyStyles = () => {
    document.querySelectorAll("td").forEach((td) => {
      const div = td.querySelector("div.custom-cell");
      if (div) {
        const computedStyle = getComputedStyle(div);
        td.style.backgroundColor = computedStyle.backgroundColor;
      }
    });
  };

  useEffect(() => {
    // Apply styles after component mounts
    applyStyles();
  }, []);

  // Function to render the cell content with dynamic styles and class name
  const columnBodyTemplate = (rowData, { field }) => {
    const value = rowData[field];
    const style = cellStyle(value, field);

    return (
      <div
        className={`custom-cell ${field}`}
        style={{
          ...style,
          padding: "0.5rem",
          textAlign: field !== "categoryName" ? "center" : "left",
          fontWeight: field === "categoryName" ? "bold" : "normal",
          textTransform: field === "categoryName" ? "uppercase" : "none",
        }}
      >
        {field !== "categoryName" ? value + "%" : value}
      </div>
    );
  };

  const columnStyle = { width: "25%" }; // Equal width for all columns
  const gradientStyle = {
    background: "linear-gradient(to right, #28a745, #BDBDBD)", // bg-success to secondary colors
    height: "30px", // Height of the bar
    color: "#fff",
    textAlign: "center",
    lineHeight: "30px", // Vertically center text
    padding: "0 10px",
    fontSize: "14px",
  };
  return (
    <>
      <div className="fs-14 text-start mt-4 mb-4">
        <strong>TOP NEEDS</strong>
        <div
          className="secondary-text small fw-600 mt-4"
          style={{ color: "#BDBDBD" }}
        >
          % USERS INDEX TO CATEGORY
        </div>
      </div>
      <div className="table-container">
        <style>
          {`
            .table-bordered {
                border-collapse: collapse;
                width: 100%;
            }

            .table-bordered th, .table-bordered td {
                border: 1px solid #dee2e6;
                padding: 0.5rem;
                text-align: center;
            }

            .table-bordered th {
                background-color: transparent;
                color: #BDBDBD;
                text-transform: uppercase;
                text-align: center; /* Center-align header text */
            }

            .custom-cell {
                color: black;
            }
            `}
        </style>
        <DataTable value={data} className="table table-bordered">
          <Column
            field="categoryName"
            body={columnBodyTemplate}
            style={columnStyle}
          />
          <Column
            field="category"
            header="Category"
            body={columnBodyTemplate}
            style={columnStyle}
          />
          <Column
            field="tide"
            header="Tide"
            body={columnBodyTemplate}
            style={columnStyle}
          />
          <Column
            field="gain"
            header="Gain"
            body={columnBodyTemplate}
            style={columnStyle}
          />
        </DataTable>
      </div>
      <div className="container mt-4">
        <div className="row" style={gradientStyle}></div>
      </div>
      <div
        className="d-flex justify-content-between mt-2 small fw-600"
        style={{ color: "#BDBDBD" }}
      >
        <span>ABOVE AVERAGE</span>
        <span>BELOW AVERAGE</span>
      </div>
    </>
  );
};

export default TopReasonForSwitchBrand;
