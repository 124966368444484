import React from "react";
import { Skeleton } from "primereact/skeleton";
const CompareByColumnsSkeleton = () => {
  return (
    <>
      <Skeleton width="13rem" className="mb-2" borderRadius="16px"></Skeleton>
      <Skeleton width="13rem" className="mb-2" borderRadius="16px"></Skeleton>
      <Skeleton width="13rem" className="mb-2" borderRadius="16px"></Skeleton>
      <Skeleton width="13rem" className="mb-2" borderRadius="16px"></Skeleton>
    </>
  );
};
export default CompareByColumnsSkeleton;
