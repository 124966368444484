import React, { useState, useEffect } from "react";
import FilterByForDashboard from "../FilterByForDashboard";
import OccasionsChart from "./occasionsComponents/OccasionsChart";
import OccasionsTable from "./occasionsComponents/OccasionsTable";
import IndexIndecator from "../../IndexIndicator/IndexIndecator";
import { useGlobalContext } from "../../../context/GlobalContext";
import { PROJECT_ID } from "../../../utils/constants";
import { getOccasionsChartData } from "../../../services/dashboard/occasions";
import { useSelector } from "react-redux";

const Occasions = (props) => {
  const { device, deviceCode, category, product_id } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [productType, setProductType] = useState("");
  const [activeOption, setActiveOption] = useState("FILTERBY");
  const [question_id, setQuestionId] = useState("");
  const { token } = useSelector((state) => state.auth);
  const [occasionsChartData, setOccasionsChartData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [originalChartData, setOriginalChartData] = useState([]);
  const [sortOption, setSortOption] = useState("default");
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedHouseHoldAge, setSelectedHouseHoldAge] = useState([]);
  const [selectedHouseHoldIncome, setSelectedHouseHoldIncome] = useState([]);
  const [selectedHouseHoldSize, setSelectedHouseHoldSize] = useState([]);
  const [selectedHouseHoldChildren, setSelectedHouseHoldChildren] = useState(
    [],
  );
  const [selectedHouseHoldRaceEthnicity, setSelectedHouseHoldRaceEthnicity] =
    useState([]);
  const [selectedForms, setSelectedForms] = useState([]);
  const [selectedDayPart, setSelectedDayPart] = useState([]);
  const [selectedWeekPart, setSelectedWeekPart] = useState([]);
  const [selectedTimeOfDay, setSelectedTimeOfDay] = useState([]);
  const [apply, setApply] = useState(false);

  const getSelectedCategoriesString = () => {
    const categories = [];
    if (selectedHouseHoldAge.length > 0) categories.push("age");
    if (selectedHouseHoldIncome.length > 0) categories.push("income");
    if (selectedHouseHoldSize.length > 0) categories.push("size");
    if (selectedHouseHoldChildren.length > 0) categories.push("children");
    if (selectedHouseHoldRaceEthnicity.length > 0)
      categories.push("raceEthnicity");
    return categories.join("|");
  };

  const fetchChartsData = () => {
    setLoading(true);
    const requestBody = {
      compare_with: props?.selectedComparitionBy,
      compare_by: props?.compareBy,
      filter_form: activeOption === "FILTERBY" ? selectedForms.join("|") : "",
      filter_brand: activeOption === "FILTERBY" ? selectedBrands.join("|") : "",
      filter_household:
        activeOption === "FILTERBY"
          ? [
              ...selectedHouseHoldAge,
              ...selectedHouseHoldIncome,
              ...selectedHouseHoldSize,
              ...selectedHouseHoldChildren,
              ...selectedHouseHoldRaceEthnicity,
            ]
              .map((item) => item.uniques)
              .join("|")
          : "",
      filter_household_type:
        activeOption === "FILTERBY" ? getSelectedCategoriesString() : "",
      filter_gender:
        activeOption === "FILTERBY" ? selectedGenders.join("|") : "",
      product_id: parseInt(product_id),
      product_type: productType,
      project_id: parseInt(PROJECT_ID),
      question_id: parseInt(question_id),
      section_type: props?.isCompareByClicked ? "compare" : "filter",
    };

    Promise.all([getOccasionsChartData(requestBody, token)])
      .then(([occasionsChartDataResponse]) => {
        setOccasionsChartData(occasionsChartDataResponse.data.graphs);
        setOriginalChartData(occasionsChartDataResponse.data.graphs); // Save original data for reset
      })
      .catch((error) => {
        console.error("Error fetching chart data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (productType !== "" && question_id !== "") {
      fetchChartsData();
    }
  }, [productType, question_id, apply, props?.apply]);

  // Sorting Function
  const handleSortChange = (option) => {
    setSortOption(option);

    if (option === "default") {
      setOccasionsChartData(originalChartData);
      return;
    }
    const sortedIndices = occasionsChartData.datasets[0].data
      .map((value, index) => ({ index, value }))
      .sort((a, b) =>
        option === "highest" ? b.value - a.value : a.value - b.value,
      )
      .map((item) => item.index);
    const sortedLabels = sortedIndices.map((i) => occasionsChartData.labels[i]);
    const sortedDatasets = occasionsChartData.datasets.map((dataset) => ({
      ...dataset,
      data: sortedIndices.map((i) => dataset.data[i]),
    }));
    setOccasionsChartData((prev) => ({
      ...prev,
      labels: sortedLabels,
      datasets: sortedDatasets,
    }));
  };

  const handleReset = () => {
    setSelectedDayPart([]);
    setSelectedWeekPart([]);
    setSelectedBrands([]);
    setSelectedFilters([]);
    setSelectedForms([]);
    setApply(!apply);
  }

  return (
    <div className="row">
      <FilterByForDashboard
        loading={loading}
        setLoading={setLoading}
        device={device}
        deviceCode={deviceCode}
        category={category}
        product_id={product_id}
        productType={productType}
        setProductType={setProductType}
        activeOption={activeOption}
        setActiveOption={setActiveOption}
        occasion={true}
        question_id={question_id}
        setQuestionId={setQuestionId}
        selectedForms={selectedForms}
        setSelectedForms={setSelectedForms}
        selectedBrands={selectedBrands}
        setSelectedBrands={setSelectedBrands}
        selectedGenders={selectedGenders}
        setSelectedGenders={setSelectedGenders}
        selectedHouseHoldAge={selectedHouseHoldAge}
        setSelectedHouseHoldAge={setSelectedHouseHoldAge}
        selectedHouseHoldIncome={selectedHouseHoldIncome}
        setSelectedHouseHoldIncome={setSelectedHouseHoldIncome}
        selectedHouseHoldSize={selectedHouseHoldSize}
        setSelectedHouseHoldSize={setSelectedHouseHoldSize}
        selectedHouseHoldChildren={selectedHouseHoldChildren}
        setSelectedHouseHoldChildren={setSelectedHouseHoldChildren}
        selectedHouseHoldRaceEthnicity={selectedHouseHoldRaceEthnicity}
        setSelectedHouseHoldRaceEthnicity={setSelectedHouseHoldRaceEthnicity}
        selectedDayPart={selectedDayPart}
        setSelectedDayPart={setSelectedDayPart}
        selectedWeekPart={selectedWeekPart}
        setSelectedWeekPart={setSelectedWeekPart}
        selectedTimeOfDay={selectedTimeOfDay}
        setSelectedTimeOfDay={setSelectedTimeOfDay}
        apply={apply}
        setApply={setApply}
        isCompareByClicked={props?.isCompareByClicked}
        setIsCompareByClicked={props?.setIsCompareByClicked}
        setSelectedFilters={setSelectedFilters}
        selectedFilters={selectedFilters}
        handleReset={handleReset}
      />

      <div className="col-9" style={{ padding: "0px 3%" }}>
        <OccasionsChart
          occasionsChartData={occasionsChartData}
          loading={loading}
          sortOption={sortOption}
          handleSortChange={handleSortChange}
        />
        <OccasionsTable tableData={occasionsChartData} />
        <IndexIndecator />
      </div>
    </div>
  );
};

export default Occasions;
