import React, { useState, useEffect } from "react";
import FilterByForDashboard from "../FilterByForDashboard";
import QuantityIndecator from "../brandScorecard/brandScorecardComponents/QuantityIndecator";
import OuncesPerUse from "./amountComponents/OuncesPerUse";
import TimeOfDay from "./amountComponents/TimeOfDay";
import DayOfWeek from "./amountComponents/DayOfWeek";
import WeekPart from "./amountComponents/WeekPart";
import { useSelector } from "react-redux";
import { useGlobalContext } from "../../../context/GlobalContext";
import { PROJECT_ID } from "../../../utils/constants";
import {
  getOuncesAndGramsPerUseChartData,
  getTimeOfDayChartData,
  getDayOfWeekAndWeekPartChartData,
} from "../../../services/dashboard/amount";
import { validateCompareObjectValues } from "../../../utils/filterBy/filterBy";

const Amount = (props) => {
  const [activeOption, setActiveOption] = useState("FILTERBY");
  const [loading, setLoading] = useState(false);
  const { device, deviceCode, category, product_id } = useGlobalContext();
  const [timeOfDayChartData, setTimeOfDayChartData] = useState("");
  const [dayOfWeekChartData, setDayOfWeekChartData] = useState("");
  const [weekPartChartData, setWeekPartChartData] = useState("");
  const [ounceAndGramsPerUseChartData, setOunceAndGramsPerUseChartData] =
    useState("");
  const [productType, setProductType] = useState("");
  const { token } = useSelector((state) => state.auth);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedOccasions, setSelectedOccasions] = useState([]);
  const [selectedHouseHoldAge, setSelectedHouseHoldAge] = useState([]);
  const [selectedHouseHoldIncome, setSelectedHouseHoldIncome] = useState([]);
  const [selectedHouseHoldSize, setSelectedHouseHoldSize] = useState([]);
  const [selectedHouseHoldChildren, setSelectedHouseHoldChildren] = useState(
    [],
  );
  const [selectedHouseHoldRaceEthnicity, setSelectedHouseHoldRaceEthnicity] =
    useState([]);
  const [selectedForms, setSelectedForms] = useState([]);
  const [selectedDayPart, setSelectedDayPart] = useState([]);
  const [selectedWeekPart, setSelectedWeekPart] = useState([]);
  const [selectedTimeOfDay, setSelectedTimeOfDay] = useState([]);
  const [apply, setApply] = useState(false);
  const [activeButtonDayOfWeek, setActiveButtonDayOfWeek] =
    useState("% OF TOTAL");
  const [dayOfWeekLoader, setDayOfWeekLoader] = useState(false);
  const [activeButtonWeekPart, setActiveButtonWeekPart] =
    useState("% OF TOTAL");
  const [weekPartLoader, setWeekPartLoader] = useState(false);
  const getSelectedCategory = () => {
    if (selectedHouseHoldAge.length > 0) {
      return "age";
    }
    if (selectedHouseHoldIncome.length > 0) {
      return "income";
    }
    if (selectedHouseHoldSize.length > 0) {
      return "size";
    }
    if (selectedHouseHoldChildren.length > 0) {
      return "children";
    }
    if (selectedHouseHoldRaceEthnicity.length > 0) {
      return "raceEthnicity";
    }
    return "";
  };

  const getSelectedCategoriesString = () => {
    const categories = [];

    if (selectedHouseHoldAge.length > 0) {
      categories.push("age");
    }
    if (selectedHouseHoldIncome.length > 0) {
      categories.push("income");
    }
    if (selectedHouseHoldSize.length > 0) {
      categories.push("size");
    }
    if (selectedHouseHoldChildren.length > 0) {
      categories.push("children");
    }
    if (selectedHouseHoldRaceEthnicity.length > 0) {
      categories.push("raceEthnicity");
    }

    return categories.join("|");
  };

  //these are for quantity indicator
  const [activeTag, setActiveTag] = useState("GM");
  const handleTagClick = (tag) => {
    setActiveTag(tag);
  };

  const fetchChartsData = () => {
    setLoading(true);
    const requestBody = {
      cat_id: parseInt(product_id),
      cat_name: category,
      chart_type: "",
      compare_with: props?.selectedComparitionBy,
      compare_by: props?.compareBy,
      project_id: parseInt(PROJECT_ID),

      filter_occasion: selectedOccasions.join("|"),
      filter_household: [
        ...selectedHouseHoldAge,
        ...selectedHouseHoldIncome,
        ...selectedHouseHoldSize,
        ...selectedHouseHoldChildren,
        ...selectedHouseHoldRaceEthnicity,
      ]
        .map((item) => item.uniques)
        .join("|"),
      filter_household_type: getSelectedCategoriesString(),
      filter_consumer_segment: "",
      filter_product_category: parseInt(product_id),
      filter_product_type: productType,
      filter_brand: selectedBrands.join("|"),
      filter_form: selectedForms.join("|"),
      filter_day_part: selectedDayPart.join("|"),
      filter_week_part: selectedWeekPart.join("|"),
      graph_type: "",
      how_long_use_per: "",
      section_type:
        props?.chooseComparison && validateCompareObjectValues(props?.compareBy)
          ? "compare"
          : "filter",
      how_often_use_per: "",
    };
    Promise.all([
      getOuncesAndGramsPerUseChartData({ ...requestBody }, token),
      getTimeOfDayChartData({ ...requestBody }, token),
    ])
      .then(([ouncesAndGramsPerUseResponse, timeOfDayResponse]) => {
        setOunceAndGramsPerUseChartData(
          ouncesAndGramsPerUseResponse.data.graphs,
        );
        setTimeOfDayChartData(timeOfDayResponse.data.graphs);
        set;
      })
      .catch((error) => {
        console.error("Error fetching chart data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // const fetchDayOfWeekAndWeekPartData = () => {
  //   setLoading(true);
  //   const requestBody = {
  //     cat_id: parseInt(product_id),
  //     cat_name: category,
  //     chart_type: "",
  //     compare_with: props?.selectedComparitionBy,
  //     compare_by: props?.compareBy,
  //     project_id: parseInt(PROJECT_ID),

  //     filter_occasion: selectedOccasions.join("|"),
  //     filter_household: [
  //       ...selectedHouseHoldAge,
  //       ...selectedHouseHoldIncome,
  //       ...selectedHouseHoldSize,
  //       ...selectedHouseHoldChildren,
  //       ...selectedHouseHoldRaceEthnicity,
  //     ]
  //       .map((item) => item.uniques)
  //       .join("|"),
  //     filter_household_type: getSelectedCategoriesString(),
  //     filter_consumer_segment: "",
  //     filter_product_category: parseInt(product_id),
  //     filter_product_type: productType,
  //     filter_brand: selectedBrands.join("|"),
  //     filter_form: selectedForms.join("|"),
  //     graph_type: "",
  //     how_long_use_per: "",
  //     section_type:
  //       props?.chooseComparison && validateCompareObjectValues(props?.compareBy)
  //         ? "compare"
  //         : "filter",
  //     how_often_use_per: "",
  //   };
  //   Promise.all([
  //     getDayOfWeekAndWeekPartChartData(
  //       { ...requestBody, day_type: "day", sub_graph_type:
  //         activeButtonDayOfWeek === "% OF TOTAL"
  //           ? "percentage_of_total"
  //           : "number_of_usage", },
  //       token,
  //     ),
  //     getDayOfWeekAndWeekPartChartData(
  //       { ...requestBody, day_type: "week" },
  //       token,
  //     ),
  //   ])
  //     .then(([dayOfWeekResponse, weekPartResponse]) => {
  //       setDayOfWeekChartData(dayOfWeekResponse.data.graphs);
  //       setWeekPartChartData(weekPartResponse.data.graphs);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching chart data:", error);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  const getRequestBody = () => ({
    cat_id: parseInt(product_id),
    cat_name: category,
    chart_type: "",
    compare_with: props?.selectedComparitionBy,
    compare_by: props?.compareBy,
    project_id: parseInt(PROJECT_ID),

    filter_occasion: selectedOccasions.join("|"),
    filter_household: [
      ...selectedHouseHoldAge,
      ...selectedHouseHoldIncome,
      ...selectedHouseHoldSize,
      ...selectedHouseHoldChildren,
      ...selectedHouseHoldRaceEthnicity,
    ]
      .map((item) => item.uniques)
      .join("|"),
    filter_household_type: getSelectedCategoriesString(),
    filter_consumer_segment: "",
    filter_product_category: parseInt(product_id),
    filter_product_type: productType,
    filter_brand: selectedBrands.join("|"),
    filter_form: selectedForms.join("|"),
    graph_type: "",
    how_long_use_per: "",
    section_type:
      props?.chooseComparison && validateCompareObjectValues(props?.compareBy)
        ? "compare"
        : "filter",
    how_often_use_per: "",
  });

  const fetchDayOfWeekData = () => {
    setDayOfWeekLoader(true);
    const requestBody = getRequestBody();

    getDayOfWeekAndWeekPartChartData(
      {
        ...requestBody,
        day_type: "day",
        sub_graph_type:
          activeButtonDayOfWeek === "% OF TOTAL"
            ? "percentage_of_total"
            : "number_of_usage",
      },
      token,
    )
      .then((response) => {
        setDayOfWeekChartData(response.data.graphs);
      })
      .catch((error) => {
        console.error("Error fetching Day of the Week chart data:", error);
      })
      .finally(() => {
        setDayOfWeekLoader(false);
      });
  };

  const fetchWeekPartData = () => {
    setWeekPartLoader(true);
    const requestBody = getRequestBody();

    getDayOfWeekAndWeekPartChartData(
      {
        ...requestBody,
        day_type: "week",
        sub_graph_type:
          activeButtonWeekPart === "% OF TOTAL"
            ? "percentage_of_total"
            : "number_of_usage",
      },
      token,
    )
      .then((response) => {
        setWeekPartChartData(response.data.graphs);
      })
      .catch((error) => {
        console.error("Error fetching Week Part chart data:", error);
      })
      .finally(() => {
        setWeekPartLoader(false);
      });
  };

  useEffect(() => {
    fetchChartsData();
  }, [apply, props?.apply]);

  useEffect(() => {
    fetchDayOfWeekData();
  }, [apply, props?.apply, activeButtonDayOfWeek]);

  useEffect(() => {
    fetchWeekPartData();
  }, [apply, props?.apply, activeButtonWeekPart]);

  const handleReset = () => {
    setSelectedDayPart([]);
    setSelectedWeekPart([]);
    setSelectedBrands([]);
    setSelectedOccasions([]);
    setSelectedHouseHoldAge([]);
    setSelectedForms([]);
    setSelectedHouseHoldIncome([]);
    setSelectedHouseHoldSize([]);
    setSelectedHouseHoldChildren([]);
    setSelectedHouseHoldRaceEthnicity([]);
    setApply(!apply);
  }

  return (
    <div className="row">
      <FilterByForDashboard
        loading={loading}
        setLoading={setLoading}
        device={device}
        deviceCode={deviceCode}
        category={category}
        product_id={product_id}
        productType={productType}
        setProductType={setProductType}
        activeOption={activeOption}
        setActiveOption={setActiveOption}
        amount={true}
        selectedForms={selectedForms}
        setSelectedForms={setSelectedForms}
        selectedBrands={selectedBrands}
        setSelectedBrands={setSelectedBrands}
        selectedGenders={selectedGenders}
        setSelectedGenders={setSelectedGenders}
        selectedOccasions={selectedOccasions}
        setSelectedOccasions={setSelectedOccasions}
        selectedHouseHoldAge={selectedHouseHoldAge}
        setSelectedHouseHoldAge={setSelectedHouseHoldAge}
        selectedHouseHoldIncome={selectedHouseHoldIncome}
        setSelectedHouseHoldIncome={setSelectedHouseHoldIncome}
        selectedHouseHoldSize={selectedHouseHoldSize}
        setSelectedHouseHoldSize={setSelectedHouseHoldSize}
        selectedHouseHoldChildren={selectedHouseHoldChildren}
        setSelectedHouseHoldChildren={setSelectedHouseHoldChildren}
        selectedHouseHoldRaceEthnicity={selectedHouseHoldRaceEthnicity}
        setSelectedHouseHoldRaceEthnicity={setSelectedHouseHoldRaceEthnicity}
        selectedDayPart={selectedDayPart}
        setSelectedDayPart={setSelectedDayPart}
        selectedWeekPart={selectedWeekPart}
        setSelectedWeekPart={setSelectedWeekPart}
        selectedTimeOfDay={selectedTimeOfDay}
        setSelectedTimeOfDay={setSelectedTimeOfDay}
        apply={apply}
        setApply={setApply}
        isCompareByClicked={props?.isCompareByClicked}
        setIsCompareByClicked={props?.setIsCompareByClicked}
        isDayWeekPartShow={true}
        handleReset={handleReset}
      />
      <div className="col-9" style={{ padding: "0px 3%" }}>
        {/* <QuantityIndecator
          activeTag={activeTag}
          setActiveTag={setActiveTag}
          handleTagClick={handleTagClick}
        /> */}
        <OuncesPerUse
          chartData={ounceAndGramsPerUseChartData}
          loading={loading}
        />
        <TimeOfDay chartData={timeOfDayChartData} loading={loading} />
        <DayOfWeek
          activeButtonDayOfWeek={activeButtonDayOfWeek}
          setActiveButtonDayOfWeek={setActiveButtonDayOfWeek}
          chartData={dayOfWeekChartData}
          dayOfWeekLoader={dayOfWeekLoader}
        />
        <WeekPart
          activeButtonWeekPart={activeButtonWeekPart}
          setActiveButtonWeekPart={setActiveButtonWeekPart}
          chartData={weekPartChartData}
          weekPartLoader={weekPartLoader}
        />
      </div>
    </div>
  );
};

export default Amount;
