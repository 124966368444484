import React from "react";
import FilterByForDashboard from "../FilterByForDashboard";
import ChartDivider from "../ChartDivider";
import OverallSatisfaction from "./experienceComponents/OverallSatisfaction";
import RepeatPurchaseIntent from "./experienceComponents/RepeatPurchaseIntent";
import Uniqueness from "./experienceComponents/Uniqueness";
import TopLikesUsers from "./experienceComponents/TopLikesUsers";
import TopDislikesUsers from "./experienceComponents/TopDislikesUsers";
import ValueForMoney from "./experienceComponents/ValueForMoney";

const Experience = (props) => {
  return (
    <div className="row">
      {/* Filter By Component */}
      <FilterByForDashboard />
      <div className="col-9" style={{ padding: "0px 5%" }}>
        <OverallSatisfaction />
        <ChartDivider />
        <RepeatPurchaseIntent />
        <ChartDivider />
        <Uniqueness />
        <ChartDivider />
        <TopLikesUsers />
        <ChartDivider />
        <TopDislikesUsers />
        <ChartDivider />
        <ValueForMoney />
        <hr style={{ margin: "2rem 0" }} />
      </div>
    </div>
  );
};
export default Experience;
