import React from "react";
import FilterByForDashboard from "../FilterByForDashboard";
import ChartDivider from "../ChartDivider";
import TopNeedsUsers from "./needsComponents/TopNeedsUsers";
import TopNeedsCategory from "./needsComponents/TopNeedsCategory";
const Needs = () => {
  return (
    <div className="row">
      {/* Filter By Component */}
      <FilterByForDashboard />
      <div className="col-9" style={{ padding: "0px 5%" }}>
        <TopNeedsUsers />
        <ChartDivider />
        <TopNeedsCategory />
        <hr style={{ margin: "2rem 0" }} />
      </div>
    </div>
  );
};
export default Needs;
