import React from "react";
import { Chart } from "primereact/chart";

const Users = () => {
  const data = {
    labels: ["HEAVY FREQUENCY", "MEDIUM FREQUENCY", "LIGHT FREQUENCY"],
    datasets: [
      {
        data: [33, 33, 33],
        backgroundColor: ["#FF9800", "#2196F3", "#F44336"], // Static colors
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
        },
      },
      datalabels: {
        formatter: (value, context) => {
          const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
          const percentage = ((value / total) * 100).toFixed(0); // Convert to percentage
          return `${percentage}%`; // Show percentage
        },
        color: "#000", // Text color for labels
        font: {
          weight: "bold",
        },
      },
    },
  };

  return (
    <>
      <div className="fs-14 text-start mt-4 mb-4">
        <strong>% USERS</strong>
      </div>
      {/* Use Bootstrap classes to center the chart */}
      <div className="d-flex justify-content-center align-items-center">
        <Chart type="pie" data={data} options={options} />
      </div>
    </>
  );
};

export default Users;
