import React, { useState, useEffect } from "react";
import FilterByForDashboard from "../FilterByForDashboard";
import IndexIndecator from "../../IndexIndicator/IndexIndecator";
import QuantityIndecator from "./brandScorecardComponents/QuantityIndecator";
import GrowYourBrand from "./brandScorecardComponents/GrowYourBrand";
import IdentifyWhitespace from "./brandScorecardComponents/IdentifyWhitespace";
import StrengthenCompetitiveEdge from "./brandScorecardComponents/StrengthenCompetitiveEdge";
import RefineYourTarget from "./brandScorecardComponents/RefineYourTarget";
import { useGlobalContext } from "../../../context/GlobalContext";

const BrandScorecard = (props) => {
  const { device, deviceCode, category, product_id } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [activeOption, setActiveOption] = useState("FILTERBY");
  const [productType, setProductType] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedOccasions, setSelectedOccasions] = useState([]);
  const [selectedHouseHoldAge, setSelectedHouseHoldAge] = useState([]);
  const [selectedHouseHoldIncome, setSelectedHouseHoldIncome] = useState([]);
  const [selectedHouseHoldSize, setSelectedHouseHoldSize] = useState([]);
  const [selectedHouseHoldChildren, setSelectedHouseHoldChildren] = useState(
    [],
  );
  const [selectedHouseHoldRaceEthnicity, setSelectedHouseHoldRaceEthnicity] =
    useState([]);
  const [selectedForms, setSelectedForms] = useState([]);
  const [selectedDayPart, setSelectedDayPart] = useState([]);
  const [selectedWeekPart, setSelectedWeekPart] = useState([]);
  const [selectedTimeOfDay, setSelectedTimeOfDay] = useState([]);
  const [apply, setApply] = useState(false);
  const [activeTag, setActiveTag] = useState("GM");
  const handleTagClick = (tag) => {
    setActiveTag(tag);
  };

  const handleReset = () => {
    setSelectedDayPart([]);
    setSelectedWeekPart([]);
    setSelectedBrands([]);
    setSelectedOccasions([]);
    setSelectedFilters([]);
    setSelectedForms([]);
    setApply(!apply);
  }

  return (
    <div className="row">
      <FilterByForDashboard
        loading={loading}
        setLoading={setLoading}
        device={device}
        deviceCode={deviceCode}
        category={category}
        product_id={product_id}
        productType={productType}
        setProductType={setProductType}
        activeOption={activeOption}
        setActiveOption={setActiveOption}
        selectedForms={selectedForms}
        setSelectedForms={setSelectedForms}
        selectedBrands={selectedBrands}
        setSelectedBrands={setSelectedBrands}
        selectedGenders={selectedGenders}
        setSelectedGenders={setSelectedGenders}
        selectedOccasions={selectedOccasions}
        setSelectedOccasions={setSelectedOccasions}
        selectedHouseHoldAge={selectedHouseHoldAge}
        setSelectedHouseHoldAge={setSelectedHouseHoldAge}
        selectedHouseHoldIncome={selectedHouseHoldIncome}
        setSelectedHouseHoldIncome={setSelectedHouseHoldIncome}
        selectedHouseHoldSize={selectedHouseHoldSize}
        setSelectedHouseHoldSize={setSelectedHouseHoldSize}
        selectedHouseHoldChildren={selectedHouseHoldChildren}
        setSelectedHouseHoldChildren={setSelectedHouseHoldChildren}
        selectedHouseHoldRaceEthnicity={selectedHouseHoldRaceEthnicity}
        setSelectedHouseHoldRaceEthnicity={setSelectedHouseHoldRaceEthnicity}
        selectedDayPart={selectedDayPart}
        setSelectedDayPart={setSelectedDayPart}
        selectedWeekPart={selectedWeekPart}
        setSelectedWeekPart={setSelectedWeekPart}
        selectedTimeOfDay={selectedTimeOfDay}
        setSelectedTimeOfDay={setSelectedTimeOfDay}
        apply={apply}
        setApply={setApply}
        isCompareByClicked={props?.isCompareByClicked}
        setIsCompareByClicked={props?.setIsCompareByClicked}
        setSelectedFilters={setSelectedFilters}
        selectedFilters={selectedFilters}
        handleReset={handleReset}
      />
      <div className="col-9" style={{ padding: "0px 3%" }}>
        <IndexIndecator />
        <QuantityIndecator
          activeTag={activeTag}
          setActiveTag={setActiveTag}
          handleTagClick={handleTagClick}
        />
        <GrowYourBrand />
        <IdentifyWhitespace />
        <StrengthenCompetitiveEdge />
        <RefineYourTarget />
      </div>
    </div>
  );
};

export default BrandScorecard;
