import React, { useState } from "react";
import { Chart } from "primereact/chart";
import ChartDivider from "../../ChartDivider";
import { Button } from "primereact/button";
import ChartSkeleton from "../../../Skeletons/ChartSkeleton";
import { timeOfDayChartOptions } from "../../../../utils/chartOptions/dashboard/amount";

const TimeOfDay = React.memo(function TimeOfDay(props) {
  const options = timeOfDayChartOptions();
  const ToggleButtons = () => {
    const [activeButton, setActiveButton] = useState("# OF USES");

    const handleClick = (label) => {
      setActiveButton(label);
    };

    return (
      <div
        className="d-flex align-items-center gap-2"
        style={{ float: "right" }}
      >
        {/* <Button
          label="% OF TOTAL"
          className={`px-3 py-2 fw-bold fs-6 ${
            activeButton === "% OF TOTAL"
              ? "btn-primary text-white"
              : "btn-light text-dark"
          }`}
          onClick={() => handleClick("% OF TOTAL")}
          style={{
            borderRadius: "10px",
            border: "none",
            height: "25px",
            backgroundColor:
              activeButton === "% OF TOTAL" ? "#002D42" : "#E0E0E0",
          }}
          disabled
        />
        <Button
          label="# OF USES"
          className={`px-3 py-2 fw-bold fs-6 ${
            activeButton === "# OF USES"
              ? "btn-primary text-white"
              : "btn-light text-dark"
          }`}
          onClick={() => handleClick("# OF USES")}
          style={{
            borderRadius: "10px",
            border: "none",
            height: "25px",
            backgroundColor:
              activeButton === "# OF USES" ? "#002D42" : "#E0E0E0",
          }}
        /> */}
      </div>
    );
  };

  return (
    <>
      <div className="mt-4" style={{ maxWidth: "100%" }}>
        <div className="mb-4">
          <h5 className="text-uppercase fw-bold mb-1 text-start">
            <span>Time of day</span>
            <ToggleButtons />
          </h5>
          <p
            className="text-uppercase mb-0 text-start fw-bold fs-12 py-3"
            style={{ color: "#6c757d" }}
          >
            Grams Per Use (Average)
          </p>
          <div className="col-12">
            {!props?.loading ? (
              <Chart type="line" data={props?.chartData} options={options} />
            ) : (
              <ChartSkeleton />
            )}
          </div>
          <ChartDivider />
        </div>
      </div>
    </>
  );
});

export default TimeOfDay;
