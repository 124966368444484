import React from "react";
import { Chart } from "primereact/chart";

const Uniqueness = () => {
  const data = {
    labels: ["BRAND TOTAL", "TIDE", "GAIN"],
    datasets: [
      {
        label: "BRAND TOTAL",
        backgroundColor: "#FF9800",
        data: [30, 0, 0],
      },
      {
        label: "TIDE",
        backgroundColor: "#2196F3",
        data: [0, 45, 0],
      },
      {
        label: "GAIN",
        backgroundColor: "#F44336",
        data: [0, 0, 25],
      },
    ],
  };

  // Calculate the maximum value from all datasets
  const allValues = data.datasets.flatMap((dataset) => dataset.data);
  const maxValue = Math.max(...allValues);
  const maxWithBuffer = Math.ceil(maxValue * 1.1); // Adding 10% buffer and rounding up

  const options = {
    indexAxis: "y",
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      datalabels: {
        color: "black",
        anchor: "end",
        align: "right",
        font: {
          weight: "bold",
          size: 12,
        },
        formatter: (value) => (value !== 0 ? value : ""),
      },
    },
    scales: {
      x: {
        stacked: true,
        beginAtZero: true,
        max: maxWithBuffer, // Dynamically set max value with buffer
      },
      y: {
        stacked: true,
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      <div className="fs-14 text-start mt-4 mb-4">
        <strong>UNIQUENESS</strong>
        <div
          className="secondary-text small fw-600 mt-4"
          style={{ color: "#BDBDBD" }}
        >
          % USERS
        </div>
      </div>
      <div className="col-12">
        <Chart type="bar" data={data} options={options} />
      </div>
    </>
  );
};

export default Uniqueness;
