import axios from "axios";
import { getApiBaseUrl } from "../../config";

const apiPrefixReports = getApiBaseUrl("reports");

export const getNeedsChartData = async (req_body, authtoken) => {
  return await axios.post(
    `${apiPrefixReports}/api/v1/svc-smart-tag/charts-needs-average`,
    req_body,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};
