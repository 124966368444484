import axios from "axios";
import { getApiBaseUrl } from "../../config";

export const getProductType = async (product_type_req_body, authtoken) => {
  const apiPrefixReports = getApiBaseUrl("reports");
  return await axios.post(
    `${apiPrefixReports}/api/v1/svc-smart-tag/product-type`,
    product_type_req_body,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};

export const getBrandsFormsAndSize = async (req_body, authtoken) => {
  const apiPrefixReports = getApiBaseUrl("reports");
  return await axios.post(
    `${apiPrefixReports}/api/v1/svc-smart-tag/charts-barcode-details`,
    req_body,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};

export const getParticipantsList = async (req_body, authtoken) => {
  const apiPrefixReports = getApiBaseUrl("reports");
  return await axios.post(
    `${apiPrefixReports}/api/v1/svc-smart-tag/charts-participants`,
    req_body,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};

export const getSegmentsList = async (req_body, authtoken) => {
  const apiPrefixReports = getApiBaseUrl("reports");
  return await axios.post(
    `${apiPrefixReports}/api/v1/svc-smart-tag/participants-segments`,
    req_body,
    {
      headers: {
        token: authtoken,
      },
    },
  );
};
