import React from "react";
import { Chart } from "primereact/chart";

const TimeOfDayAverage = () => {
  const timeOfDayData = {
    labels: [
      "12am-4am",
      "4am-8am",
      "8am-12pm",
      "12pm-4pm",
      "4pm-8pm",
      "8pm-12am",
    ],
    datasets: [
      {
        label: "",
        data: [3.46, 7.88, 29.42, 18.08, 25, 16.15],
        backgroundColor: "#FF9800",
        participant: [9, 14, 28, 28, 29, 23],
      },
    ],
  };
  const timeOfDayOptions = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 10, // Ensures a difference of 10 units between y-axis ticks
        },
      },
    },
  };
  return (
    <div className="col-12">
      <strong className="fs-14 float-start">
        # OF USES PER TIME OF WEEK - AVERAGE
      </strong>
      <Chart type="bar" data={timeOfDayData} options={timeOfDayOptions} />
      <div className="my-table data-table">
        <div className="table-responsive">
          <table className="table text-start">
            <thead>
              <tr className="table-first-row-border">
                <th></th>
                {timeOfDayData.labels.map((label) => (
                  <th key={label} style={{ color: "#B1B1B1" }}>
                    {label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong style={{ color: "#949494" }}>AVERAGE</strong>
                </td>
                {timeOfDayData.datasets[0].data.map((data, index) => (
                  <td key={index} style={{ color: "#949494" }}>
                    {data}
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <strong style={{ color: "#949494" }}>
                    # of Participants
                  </strong>
                </td>
                {timeOfDayData.labels.map((_, index) => (
                  <td key={index} style={{ color: "#949494" }}>
                    57
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default TimeOfDayAverage;
