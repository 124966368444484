import React, { useState, useEffect } from "react";
import FilterByForDashboard from "../FilterByForDashboard";
import Household from "./userSummaryComponents/Household";
import { useGlobalContext } from "../../../context/GlobalContext";
import { useSelector } from "react-redux";
import {
  getHouseHoldMasterList,
  getUserSummaryChartData,
} from "../../../services/dashboard/usersummary";
import { PROJECT_ID } from "../../../utils/constants";
import { validateCompareObjectValues } from "../../../utils/filterBy/filterBy";
import ChartSkeleton from "../../Skeletons/ChartSkeleton";
const UserSummary = (props) => {
  const { device, deviceCode, category, product_id } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [productType, setProductType] = useState("");
  const [activeOption, setActiveOption] = useState("FILTERBY");
  const [question_id, setQuestionId] = useState("");
  const { token } = useSelector((state) => state.auth);
  const [occasionsChartData, setOccasionsChartData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedOccasions, setSelectedOccasions] = useState([]);
  const [selectedHouseHoldAge, setSelectedHouseHoldAge] = useState([]);
  const [selectedHouseHoldIncome, setSelectedHouseHoldIncome] = useState([]);
  const [selectedHouseHoldSize, setSelectedHouseHoldSize] = useState([]);
  const [selectedHouseHoldChildren, setSelectedHouseHoldChildren] = useState(
    [],
  );
  const [selectedHouseHoldRaceEthnicity, setSelectedHouseHoldRaceEthnicity] =
    useState([]);
  const [selectedForms, setSelectedForms] = useState([]);
  const [selectedDayPart, setSelectedDayPart] = useState([]);
  const [selectedWeekPart, setSelectedWeekPart] = useState([]);
  const [selectedTimeOfDay, setSelectedTimeOfDay] = useState([]);
  const [apply, setApply] = useState(false);
  const [houseHoldMasterList, setHouseHoldMasterList] = useState([]);
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    getHouseHoldMasterList({ project_id: parseInt(PROJECT_ID) }, token)
      .then((response) => {
        if (response?.status === 200) {
          setHouseHoldMasterList(response?.data?.household);
          fetchChartsData(response?.data?.household); // Fetch chart data for all IDs
        }
      })
      .catch((err) => console.error("Error fetching household list:", err));
  }, [ apply, props?.apply]);

  const getSelectedCategoriesString = () => {
    const categories = [];

    if (selectedHouseHoldAge.length > 0) {
      categories.push("age");
    }
    if (selectedHouseHoldIncome.length > 0) {
      categories.push("income");
    }
    if (selectedHouseHoldSize.length > 0) {
      categories.push("size");
    }
    if (selectedHouseHoldChildren.length > 0) {
      categories.push("children");
    }
    if (selectedHouseHoldRaceEthnicity.length > 0) {
      categories.push("raceEthnicity");
    }

    return categories.join("|");
  };

  const fetchChartsData = (households) => {
    setLoading(true);
    const requests = households.map(async (household) => {
      const requestBody = {
        project_id: parseInt(PROJECT_ID),
        household_id: parseInt(household.id),
        product_id: parseInt(product_id),
        product_type: productType,
        occasion_question_id: parseInt(question_id),
        question_id: parseInt(question_id),
        section_type:
          props?.chooseComparison &&
          validateCompareObjectValues(props?.compareBy)
            ? "compare"
            : "filter",
        filter_household: Object.values(selectedFilters)
          .flat()
          .map((item) => item.uniques.trim())
          .filter((value) => value !== "All") // Remove "All" if present
          .join("|"),
        filter_household_type: getSelectedCategoriesString(),
        filter_form: selectedForms.join("|"),
        filter_occasion: selectedOccasions.join("|"),
        filter_brand: selectedBrands.join("|"),
        compare_by: props?.compareBy,
        compare_with: props?.selectedComparitionBy,
      };

      return await getUserSummaryChartData(requestBody, token)
        .then((response) => ({
          id: household.id,
          name: household.name,
          data: response?.data?.graphs || null, // Store API response data
        }))
        .catch((err) => {
          console.error(
            `Error fetching chart data for ${household.name}:`,
            err,
          );
          return { id: household.id, name: household.name, data: null };
        })
        .finally(() => {
          setLoading(false);
        });
    });
    Promise.all(requests).then((results) => {
      setChartData(results);
    });
  };

  const handleReset = () => {
    setSelectedDayPart([]);
    setSelectedWeekPart([]);
    setSelectedBrands([]);
    setSelectedFilters([]);
    setSelectedForms([]);
    setApply(!apply);
  }

  return (
    <div className="row">
      {/* Filter By Component */}
      <FilterByForDashboard
        loading={loading}
        setLoading={setLoading}
        device={device}
        deviceCode={deviceCode}
        category={category}
        product_id={product_id}
        productType={productType}
        setProductType={setProductType}
        activeOption={activeOption}
        setActiveOption={setActiveOption}
        occasion={true}
        question_id={question_id}
        setQuestionId={setQuestionId}
        selectedForms={selectedForms}
        setSelectedForms={setSelectedForms}
        selectedBrands={selectedBrands}
        setSelectedBrands={setSelectedBrands}
        selectedGenders={selectedGenders}
        setSelectedGenders={setSelectedGenders}
        selectedOccasions={selectedOccasions}
        setSelectedOccasions={setSelectedOccasions}
        selectedHouseHoldAge={selectedHouseHoldAge}
        setSelectedHouseHoldAge={setSelectedHouseHoldAge}
        selectedHouseHoldIncome={selectedHouseHoldIncome}
        setSelectedHouseHoldIncome={setSelectedHouseHoldIncome}
        selectedHouseHoldSize={selectedHouseHoldSize}
        setSelectedHouseHoldSize={setSelectedHouseHoldSize}
        selectedHouseHoldChildren={selectedHouseHoldChildren}
        setSelectedHouseHoldChildren={setSelectedHouseHoldChildren}
        selectedHouseHoldRaceEthnicity={selectedHouseHoldRaceEthnicity}
        setSelectedHouseHoldRaceEthnicity={setSelectedHouseHoldRaceEthnicity}
        selectedDayPart={selectedDayPart}
        setSelectedDayPart={setSelectedDayPart}
        selectedWeekPart={selectedWeekPart}
        setSelectedWeekPart={setSelectedWeekPart}
        selectedTimeOfDay={selectedTimeOfDay}
        setSelectedTimeOfDay={setSelectedTimeOfDay}
        apply={apply}
        setApply={setApply}
        isCompareByClicked={props?.isCompareByClicked}
        setIsCompareByClicked={props?.setIsCompareByClicked}
        setSelectedFilters={setSelectedFilters}
        selectedFilters={selectedFilters}
        handleReset={handleReset}
      />
      <div className="col-9" style={{ padding: "0px 5%" }}>
        {loading || chartData.length === 0
          ? Array(3)
              .fill(null)
              .map((_, index) => (
                <div style={{ marginTop: "50px" }} key={index}>
                  <ChartSkeleton />
                  <br />
                </div>
              ))
          : chartData.map((chart, index) =>
              chart.data ? (
                <Household
                  key={chart.id}
                  title={chart.name}
                  data={chart.data}
                  loading={loading}
                  length={chartData.length}
                  index={index}
                />
              ) : (
                <ChartSkeleton key={chart.id} />
              ),
            )}
        <hr style={{ margin: "2rem 0" }} />
      </div>
    </div>
  );
};
export default UserSummary;
