import React from "react";
import { Chart } from "primereact/chart";

const TopNeedsUsers = () => {
  const data = {
    labels: [
      "IT MAKES WHATEVER I WASH LOOK NEW",
      "I FEEL CONFIDENT USING IT",
      "IT SAVES MY TIME",
      "IT IS ECO FRIENDLY",
      "MY FAMILY LIKES THE SCENT",
      "IT IS BIODEGRADABLE",
    ],
    datasets: [
      {
        label: "CATEGORY",
        backgroundColor: "#FF9800", // Corrected color definition
        data: [65, 59, 75, 55, 33, 22],
      },
      {
        label: "TIDE",
        backgroundColor: "#2196F3", // Corrected color definition
        data: [28, 48, 40, 30, 20, 10],
      },
      {
        label: "GAIN",
        backgroundColor: "#F44336", // Corrected color definition
        data: [20, 30, 35, 25, 67, 47],
      },
    ],
  };

  const options = {
    indexAxis: "y", // Horizontal bar chart
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      datalabels: {
        color: "black",
        anchor: "end",
        align: "right",
        font: {
          weight: "bold",
          size: 12,
        },
        formatter: (value) => (value !== 0 ? value : ""),
      },
    },
  };

  return (
    <>
      <div className="fs-14 text-start mt-4 mb-4">
        <strong>TOP NEEDS</strong>
        <div
          className="secondary-text small fw-600 mt-4"
          style={{ color: "#BDBDBD" }}
        >
          % USERS
        </div>
      </div>
      <div className="col-12">
        <Chart type="bar" data={data} options={options} />
      </div>
    </>
  );
};

export default TopNeedsUsers;
