import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Chart } from "primereact/chart";
const TotalAverage = () => {
  const options = [
    { label: "USE", value: "use" },
    { label: "DAY", value: "day" },
  ];
  const [selectedOption, setSelectedOption] = useState("day");
  const data = {
    labels: ["# of Uses Per Day - Total Average"],
    datasets: [
      {
        label: "",
        data: [0.63],
        backgroundColor: "#FF9800",
        participant: [32],
      },
    ],
  };
  const option = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  return (
    <>
      <div className="fs-14 text-start mt-4 mb-4">
        <strong>
          # OF USES PER{" "}
          <Dropdown
            value={selectedOption}
            options={options}
            onChange={(e) => setSelectedOption(e.value)}
            style={{
              width: "auto",
              display: "inline-flex",
              color: "#002133",
              padding: "0",
            }}
            className="form-control fw-600"
            id="hmSelect"
          />{" "}
          - TOTAL AVERAGE
        </strong>
      </div>
      <div className="col-12">
        <Chart type="bar" data={data} options={option} />
        <div className="my-table data-table">
          <div className="table-responsive">
            <table className="table text-start">
              <thead>
                <tr className="my-table table-first-row-border">
                  <th></th>
                  {data.labels.map((label) => (
                    <th
                      className="text-center"
                      key={label}
                      style={{ color: "#B1B1B1" }}
                    >
                      {label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong style={{ color: "#949494" }}>AVERAGE</strong>
                  </td>
                  {data.datasets[0].data.map((data, index) => (
                    <td
                      className="text-center"
                      key={index}
                      style={{ color: "#949494" }}
                    >
                      {data}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td>
                    <strong style={{ color: "#949494" }}>
                      # of Participants
                    </strong>
                  </td>
                  {data.labels.map((_, index) => (
                    <td
                      className="text-center"
                      key={index}
                      style={{ color: "#949494" }}
                    >
                      57
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default TotalAverage;
